import { Helmet } from 'react-helmet-async'
import { favicons } from '../assets/images/images';
import React, { useEffect, useState, useMemo } from "react";

function FaviconHandler({brand}){
    const [files, setFiles] = useState({});

    useEffect(() => {
        if(!brand) return;
        if(!['mdu','cng','igc','gpg'].includes(brand)) return;

        const importedImages = {};
        const r = favicons(brand);
        r.keys().forEach(item => {
          const importedImg = r(item); // import image
          importedImages[item.replace("./", "")] = importedImg; // name of file will be a key, path to file will be a value
        });
        setFiles(importedImages); 
      }, []);

      const colorMap = {
        cng: {
            maskIcon: "#008c99",
            msapplicationTileColor: "#ccfbff",
            themeColor: "#ffffff",
        },
        gpg: {
            maskIcon: "#0067a6",
            msapplicationTileColor: "#d9f1ff",
            themeColor: "#ffffff",
        },
        igc: {
            maskIcon: "#0067b1",
            msapplicationTileColor: "#e6f5ff",
            themeColor: "#e6f5ff",
        },
        mdu: {
            maskIcon: "#cd1041",
            msapplicationTileColor: "#f0ebdf",
            themeColor: "#ffffff",
        },
      }

    return (
        <Helmet>
            <link rel="apple-touch-icon" sizes="180x180" href={files["apple-touch-icon.png"]} />
            <link rel="icon" type="image/png" sizes="32x32" href={files["favicon-32x32.png"]} />
            <link rel="icon" type="image/png" sizes="16x16" href={files["favicon-16x16.png"]} />
            <link rel="mask-icon" href={files["safari-pinned-tab.svg"]} color={colorMap[brand].maskIcon} />
            <link rel="shortcut icon" href={files["favicon.ico"]} />
            <meta name="msapplication-TileColor" content={colorMap[brand].msapplicationTileColor} />
            <meta name="theme-color" content={colorMap[brand].themeColor} />

            {/* Todo: implement browserconfig if necessary */}
            {/* <meta name="msapplication-config" content={files["browserconfig.xml"]} /> */}
            {/* todo: implement manifest if necessary */}
            {/* <link rel="manifest" href={siteManifest} /> */}
        </Helmet>
    )
    
}

export default function CacheableFaviconHandler({brand}){
    return useMemo(() => <FaviconHandler brand={brand} />, [brand]);
}