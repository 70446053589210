import React, { useEffect, useState, useMemo, useRef } from "react";

import { Button, Row, Col, Accordion, Modal } from "react-bootstrap";
import $ from "jquery";

import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import { sortItemsIgnoreCase} from "../dashboard/TableComponents/SharedConstants";
import SmartTextBox from "../lib/SmartTextBox";
import { toast } from 'react-toastify';

import UserTables from "./UserTables";
import CustomAlert from "../lib/Alert";

export default function SubuserManagement({ builderData }) {
  const [activeUserList, setActiveUserList] = useState([]);
  const [disabledUserList, setDisabledUserList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isUserCreateLoading, setIsUserCreateLoading] = useState(false);
  const [showAddUser, setShowAddUser] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');

  const setNewEmailFromEvent = (e) => {setNewEmail(e.target.value)}
  const setConfirmEmailFromEvent = (e) => {setConfirmEmail(e.target.value)}
  const handleModalClose = () => { setShowAddUser(false); }
  const handleShowAddUserModal = () => { setShowAddUser(true); }
  
  useEffect(() => {
    fetchDataAndLoad();
  }, []);

  const fetchDataAndLoad = () => {
    setLoading(true);
    $.ajax({
      url: "/api/primary-user/get-users-by-builder",
      dataType: "json",
      method: "GET",
      success: (result) => {
        setActiveUserList(result.filter(u => u.isActive));
        setDisabledUserList(result.filter(u => !u.isActive));
      },
      fail: (result) => {
        toast.error("Unable to fetch user list at this time");
      },
      complete: (res) => {
        setLoading(false);
      },
    });
  }

  const getNewEmptyUser = (email) => {
    return {
      userId: '',
      name: '',
      phone: '',
      email: email,
      isPrimaryUser: false,
    }
  }

  const createNewUser = () => {
    setIsUserCreateLoading(true);

    let data = {
      email: confirmEmail,
    }

    $.ajax({
      url: "/api/primary-user/create-child-user",
      method: "POST",
      data: data,
      success: (result) => {
        toast.success("User Added Successfully");
        fetchDataAndLoad();
        handleModalClose()
      },
      error: (result) => {
        toast.error("The following error occurred: " + result.responseText);
      },
      complete: (res) => {
        setIsUserCreateLoading(false);
      },
    });
  }

  const DeactivateUser = (id) => {
    let data = {
      userId: id,
    }

    $.ajax({
      url: "/api/primary-user/deactivate-child-user",
      method: "POST",
      data: data,
      success: (result) => {
        let user = activeUserList.find((u) => u.userId == id);
        setActiveUserList(oldList => oldList.filter(u => u.userId != id));
        setDisabledUserList(oldList => [...oldList, user]);
        toast.success("User deactivated");
      },
      error: (result) => {
        toast.error("Failed to deactivate user. Reason: " + result.responseText);
      },
      complete: (res) => {
        
      },
    });
  }

  const ActivateUser = (id) => {
    let data = {
      userId: id,
    }

    $.ajax({
      url: "/api/primary-user/activate-child-user",
      method: "POST",
      data: data,
      success: (result) => {
        let user = disabledUserList.find(u => u.userId == id);
        setDisabledUserList(oldList => oldList.filter(u => u.userId != id));
        setActiveUserList(oldList => [...oldList, user]);
        toast.success("User has been activated");
      },
      error: (result) => {
        toast.error("Failed to activate user. Reason: " + result.responseText);
      },
      complete: (res) => {
        
      },
    });
  }

  const ActiveUserColumns = [
    {
      Header: "Name",
      accessor: "name",
      sortType: (prev, curr, columnId) => { return sortItemsIgnoreCase(prev, curr, columnId); },
    },
    {
      Header: "Email",
      accessor: "email",
      accordionHeader: true,
      accordionHeaderHideLabel: true,
      accordionHeaderColClass: "col-xs-11 order-xs-2 order-sm-1",
      sortType: (prev, curr, columnId) => { return sortItemsIgnoreCase(prev, curr, columnId); },
    },
    {
      Header: "Phone",
      accessor: "phone",
      sortType: (prev, curr, columnId) => { return sortItemsIgnoreCase(prev, curr, columnId); },
    },
    {
      Header: "Primary User",
      accordionHeader: true,
      accordionHeaderColClass: "col-xs-1 order-xs-1 order-sm-2 text-end",
      accordionHeaderHideLabel: true,
      accordionBodyExclude: true,
      className: "text-center",
      accessor: (item) => (item.isPrimaryUser ? '⭐' : ''),
      sortType: (prev, curr, columnId) => { return sortItemsIgnoreCase(prev, curr, columnId); },
    },
    {
      Header: "Actions",
      accessor: (item) => { return item },
      disableSortBy: true,
      
      Cell: (item) => (
        <>
          {!item.value.isPrimaryUser && (
            <Button variant="outline-primary" size="sm" onClick={() => DeactivateUser(item.value.userId)} >
              Disable
            </Button>
          )}
        </>
      )
    },
  ];
 
  const DeactivatedUserColumns = [
    {
      Header: "Name",
      accessor: "name",
      sortType: (prev, curr, columnId) => { return sortItemsIgnoreCase(prev, curr, columnId); },
    },
    {
      Header: "Email",
      accessor: "email",
      accordionHeader: true,
      accordionHeaderHideLabel: true,
      accordionHeaderColClass: "col-xs-11 order-xs-2 order-sm-1",
      sortType: (prev, curr, columnId) => { return sortItemsIgnoreCase(prev, curr, columnId); },
    },
    {
      Header: "Phone",
      accessor: "phone",
      sortType: (prev, curr, columnId) => { return sortItemsIgnoreCase(prev, curr, columnId); },
    },
    {
      Header: "Primary User",
      accordionHeader: true,
      accordionHeaderColClass: "col-xs-1 order-xs-1 order-sm-2 text-end",
      accordionHeaderHideLabel: true,
      accordionBodyExclude: true,
      className: "text-center",
      accessor: (item) => (item.isPrimaryUser ? '⭐' : ''),
      sortType: (prev, curr, columnId) => { return sortItemsIgnoreCase(prev, curr, columnId); },
    },
    {
      Header: "Actions",
      accessor: (item) => { return item },
      disableSortBy: true,
      Cell: (item) => (
        <>
          {!item.value.isPrimaryUser && (
            <Button variant="outline-primary" size="sm" onClick={() => ActivateUser(item.value.userId)} >
              Activate
            </Button>
          )}
        </>
      )
    },
  ];

  return (
    <>
      {(isLoading && <SkeletonLoader count={7} height={50} />) || (
        <>
          <Row className='mb-2'>
            <p>Company Name: <strong>{builderData ? builderData.builderName : "no data"}</strong></p>
            <p>Activated Users: <strong>{activeUserList ? activeUserList.length : "no data"}</strong></p>
            
            <Col xs={{ order: 1, span: 12 }} >
              <Button style={{ width: '100px' }} className="float-end" variant="success" size="sm" onClick={handleShowAddUserModal}>Add User</Button>
            </Col>
            <Modal show={showAddUser} onHide={handleModalClose}>
              <Modal.Header closeButton>
                <Modal.Title>Add New User</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  Please enter an email address for the new user. Once you click "Invite User", the new user will receive an email with a randomly generated password.  <br />
                  The new user will be prompted to change their password and set their name and phone number on their first login.
                </p>
                <SmartTextBox 
                    label="New User Email"
                    className="col-xs-12 mb-3"
                    placeholder="user@email.com"
                    type="email"
                    tooltip="This email will be used by the new user to log into the Construction Self Service Portal. They will receive an email with a randomly generated password."
                    value={newEmail}
                    onChange={setNewEmailFromEvent}
                    invalidFeedback="Please provide a valid email."
                    autoFocus
                />
                <SmartTextBox 
                    label="Re-enter Email"
                    className="col-xs-12 mb-3"
                    placeholder="user@email.com"
                    type="email"
                    tooltip="Please re-enter the email to ensure that it was entered correctly."
                    value={confirmEmail}
                    onChange={setConfirmEmailFromEvent}
                    invalidFeedback="Please provide a valid email."
                />
              </Modal.Body>
              <Modal.Footer>
                {!!newEmail && !!confirmEmail && newEmail !== confirmEmail && 
                  <Col xs={12}>
                    <CustomAlert message ="New User Email and the Re-Entered Email do not match" variant="danger"/>
                  </Col>
                }
                <Button variant="secondary" size="sm"  onClick={handleModalClose}>
                  Close
                </Button>
                <Button variant="primary" size="sm"  onClick={createNewUser} disabled={isUserCreateLoading || !newEmail || !confirmEmail || newEmail !== confirmEmail}>
                  Invite User
                </Button>
              </Modal.Footer>
            </Modal>
          </Row>
          <Row>
            <Accordion defaultActiveKey={['0']} alwaysOpen flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Active Users</Accordion.Header>
                <Accordion.Body>
                <UserTables columns={ActiveUserColumns} data={activeUserList} isLoading={isLoading}/>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Disabled Users</Accordion.Header>
                <Accordion.Body>
                <UserTables columns={DeactivatedUserColumns} data={disabledUserList} isLoading={isLoading}/>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Row>
        </>
      )}
    </>
  )
}