import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserGear, faCircleUser, faUser, faBuildingUser, faHouseChimneyUser } from '@fortawesome/free-solid-svg-icons'

const defaultProps = ({height, ...props}) => {
    let obj = {};
   
    obj.style = props.style;
    if(!!height) {
        obj.style = {...obj.style, height: `${height}px`}
    }

    return obj;
}

export function UserGearIcon (props) {
    return <FontAwesomeIcon icon={faUserGear} {...defaultProps(props)}/>
}

export function CircleUserIcon (props) {
    return <FontAwesomeIcon icon={faCircleUser} {...defaultProps(props)}/>
}

export function UserIcon (props) {
    return <FontAwesomeIcon icon={faUser} {...defaultProps(props)}/>
}

export function CompanyProfileIcon (props) {
    return <FontAwesomeIcon icon={faBuildingUser} {...defaultProps(props)}/>
}

export function HomeownerProfileIcon (props) {
    return <FontAwesomeIcon icon={faHouseChimneyUser} {...defaultProps(props)}/>
}