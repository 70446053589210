import { Button, Popover, OverlayTrigger,  Dropdown, ButtonGroup } from "react-bootstrap";
import { statusTooltip, sortItemsIgnoreCase, formatStringAsDate, sortItemsByDate } from "./SharedConstants";

const SubmittedColumns = (editSR, viewSR, dupSR) =>
  [
    {
      Header: "Request ID",
      accordionHeader: true,
      accessor: (item) => { return item.maximoIncomingData ? item.maximoIncomingData.maximoId : 'awaiting processing' },
    },
    {
      Header: "Status",
      accessor: (v) => {return v.maximoIncomingData ? v.maximoIncomingData.status : "Submitted"},
      accordionHeader: true,
      sortType: (prev, curr, columnId) => { return sortItemsIgnoreCase(prev, curr, columnId); },
      Cell: (item) => {
        return (
          <>
            {item.value}
            {!!statusTooltip[item.value] && (
              <OverlayTrigger trigger={['hover', 'focus']} placement="auto-end"
                overlay={
                  <Popover title="Popover right">
                    {statusTooltip[item.value]}
                  </Popover>}>
                  <span className="questionIcon">?</span>
              </OverlayTrigger>
            )}
          </>
        )
      }
    },
    {
      Header: "Work Type",
      accessor: "workTypeDescription",
      sortType: (prev, curr, columnId) => { return sortItemsIgnoreCase(prev, curr, columnId); },
    },
    {
      Header: "Date Submitted",
      className: "ellipsisOverflow",
      accessor: (item) => { return formatStringAsDate(item.dateSubmitted, false, false) },
      sortType: (prev, curr, columnId) => { return sortItemsByDate(prev, curr, columnId); },
    },
    {
      Header: "Submitted By",
      className: "ellipsisOverflow",
      accessor: (item) => { return item.user.name },
      sortType: (prev, curr, columnId) => { return sortItemsIgnoreCase(prev, curr, columnId); },
    },
    {
      Header: "Street Address",
      className: "ellipsisOverflow",
      accessor: (item) => { return item && item.proInfo && item.proInfo.tkserviceaddress_streetAddress || '' },
      sortType: (prev, curr, columnId) => { return sortItemsIgnoreCase(prev, curr, columnId); },
    },
    {
      Header: "City",
      className: "ellipsisOverflow",
      accessor: (item) => { return item && item.proInfo && item.proInfo.tkserviceaddress_city || '' },
      sortType: (prev, curr, columnId) => { return sortItemsIgnoreCase(prev, curr, columnId); },
    },
    {
      Header: "State",
      className: "ellipsisOverflow",
      accessor: (item) => { return item && item.proInfo && item.proInfo.tkserviceaddress_stateProvince || '' },
      sortType: (prev, curr, columnId) => { return sortItemsIgnoreCase(prev, curr, columnId); },
    },
    // {
    //   Header: "Date Created",
    //   accessor: (item) => { return formatStringAsDate(item.createDate, false, false) },
    // },
    // {
    //   Header: "Last Edit",
    //   accessor: (item) => { return formatStringAsDate(item.updateDate, true, false) },
    // },
    // {
    //   Header: "Date Desired",
    //   accessor: (item) => { return formatStringAsDate(item.desiredDate, false, false) },
    // },
    // {
    //   Header: "Notes",
    //   accessor: "description",
    //   colWidth: '25%',
    //   sortType: (prev, curr, columnId) => { return sortItemsIgnoreCase(prev, curr, columnId); },
    //   Cell: (cell) => (
    //     <p className="div-overflow-fix">{cell.value}</p>
    //   ),
    // },
    {
      Header: "Status Date",
      className: "ellipsisOverflow",
      accessor: (item) => { return item.maximoIncomingData ? formatStringAsDate(item.maximoIncomingData.dateReceived, true, true) : '' },
      sortType: (prev, curr, columnId) => { return sortItemsByDate(prev, curr, columnId); },
    },
    {
      Header: "Actions",
      accessor: (item) => { return item },
      colWidth: '125px',
      disableSortBy: true,
      Cell: (item) => {
        const showEditButton = item.value.maximoIncomingData && item.value.maximoIncomingData.status && item.value.maximoIncomingData.status.trim().toLowerCase() == "customer input required";
        return (
          <Dropdown
            as={ButtonGroup}
            style={{ width: '100%' }}
            drop="down"
          >
            <ButtonGroup style={{ width: '100%' }}>
              {!showEditButton && (
                <Button variant="outline-primary" size="sm" onClick={() => viewSR(item.value.sruid)} >
                  View
                </Button>
              )}
              {showEditButton && (
                <Button variant="outline-warning" onClick={() => editSR(item.value.sruid)} size="sm" style={{ width: '100%' }}>
                  Edit
                </Button>
              )}
            </ButtonGroup>
            <Dropdown.Toggle split variant={showEditButton ? "outline-warning" : "outline-primary"} size="sm" />
            <Dropdown.Menu>
              {showEditButton && (
                <Dropdown.Item onClick={() => viewSR(item.value.sruid)}>
                  <Button variant="outline-primary" size="sm" style={{ width: '100%' }}>
                    View
                  </Button>
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={() => dupSR(item.value.sruid)}>
                <Button variant="outline-primary" size="sm" style={{ width: '100%' }}>
                  Copy
                </Button>
              </Dropdown.Item>
            </Dropdown.Menu >
          </Dropdown>
        )
      }
    },
  ];

export default SubmittedColumns;