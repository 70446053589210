import React, { useRef, useCallback, useState, memo, useMemo } from 'react'
import { GoogleMap, useJsApiLoader, Autocomplete, Marker } from '@react-google-maps/api';
import { Button, Form, InputGroup, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import $ from "jquery";
import { toast } from 'react-toastify';
import { debounce } from 'lodash'

function MapSelector(props) {
  const jsApiLoaderProps = useMemo(() => ({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API,
    libraries: ['places'],
    version: "weekly",
    language: "en",
    region: "US",
  }), [])

  const { isLoaded, loadError } = useJsApiLoader({
    ...jsApiLoaderProps
  })

  const refSearchInput = useRef(null);
  const refMap = useRef(null);
  const refMarker = useRef(null);
  const refPlacesService = useRef(null);

  const setCoords = (coords) => {
    props.updateCoords(coords);
  }

  const onMapUnmount = useCallback(function callback(map) {
    refMap.current=null;
    refSearchInput.current=null;
    refMarker.current=null;
    refPlacesService.current=null;
  }, []);

  const onMapLoad = useCallback(function callback(map) {
    console.log('map mounted')

    //creating the marker that will hover over the center of the map
    refMarker.current = new window.google.maps.Marker({
      position:  map.getCenter(),
      map: map,
      title: 'Marker at center of map',
      draggable: true
    });

    //user can drag Map OR the marker
    refMarker.current.addListener('drag', (event) => {
      let coords = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      }
      setCoords(coords);
    });

    refMarker.current.addListener('dragend', (event) => {
      let coords = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      }
      setCoords(coords);
    });

    //creating the service that will look up address info after user searches
    refPlacesService.current = new window.google.maps.places.PlacesService(map);

    refMap.current=map;

    if(props.mapReadyToLoad && (!props.initialLat || !props.initialLng)){
      //if no lat or lng value, we assume map is being loaded for first time and user has not chosen a location.
      //therefore, center map on current location.
      //Important: props.mapReadyToLoad is important here. This map component will initially load before the parent CreateProjectRequest
      //  finishes pulling initialLat and initialLng from database. We don't want to start centering on current location before we know
      //  that it is necessary. (Loading current location takes a second or two. Sometimes it will start getting curr loc., then database 
      //  returns user selected lat/lng, and then current loc finishes and moves map away from user's chosen location.)
      centerMapOnCurrentLocation();
    }
  }, [props.mapReadyToLoad]);

  const onAutocompleteLoad = useCallback(function callback(ac) {
    //work-around to hide pac-container because it doesn't move correctly when modal scrolls
    var modal = $('.modal-body');
    if (modal && modal.length > 0) {
      modal[0].addEventListener('scroll', () => {
        var input = document.getElementById("addressSearchInputId");
        if (input) {
          input.blur();
        }
      })
    }
  }, [])

  const centerMapOnCurrentLocation = (userInitiated) => {
    // check if the Geolocation API is supported
    if (!navigator.geolocation) {
      //browser doesn't support geolocation. return
      console.log("User browser doesn't support geolocation");
      toast.warn("Your browser doesn't support geolocation");
      return;
    }

    console.log('Attempting to center map on current location');

    navigator.geolocation.getCurrentPosition((position) => {
      const {
        latitude,
        longitude
      } = position.coords;

      refMap.current.setCenter({
        lat: Number(latitude),
        lng: Number(longitude)
      })

      refMap.current.setZoom(19);
    }, 
    (error)=>{
      console.log('Location error occurred: ', error);
      if(userInitiated && error.message){
        toast.warn(error.message);
      }
    });
  }

  const onMapCenterChanged = useCallback(() => {
    if (!refMap || !refMap.current) return;
    if (!refMarker || !refMarker.current) return;
    const center = refMap.current.getCenter();
    refMarker.current.setPosition(center); //this line might not be needed
    let coords = {
      lat: center.lat(),
      lng: center.lng(),
    }
    setCoords(coords);
  }, [refMap]);

  const debouncedInfoToast = useRef(debounce(msg=>toast.info(msg, {autoClose: 30000}), 300)).current; //used to prevent double-calling of toast method when clicking "Enter" after searching for bad address

  const locationSearch = () => {
    let searchText = refSearchInput.current.value;
    if(!searchText) {
      refSearchInput.current.focus();
      return;
    }

    const request = {
      query: searchText,
      fields: ["geometry","place_id"],
    };
    
    const getAddressComponentByType = (a_c_list, type) => {
      for(let component of a_c_list) {
        if (component.types.includes(type)) {
          return component;
        }
      }
      return null;
    }

    const getDetailsFromPlaces_Callback = (place, status) => {
      if(status !== window.google.maps.places.PlacesServiceStatus.OK){
        console.log('Error occurred while searching map. code: x222');
        debouncedInfoToast("Unable to find results for search")
        return;
      }

      //create a nice object to return back to parent object
      let formattedResultObject = {};

      const POSTAL_CODE_TYPE = 'postal_code';
      const STATE_TYPE = 'administrative_area_level_1';
      const COUNTY_TYPE ='administrative_area_level_2';
      const CITY_TYPE ='locality';
      const HOUSE_NUMBER_TYPE ='street_number';
      const STREET_NAME_TYPE ='route';

      let postalCodeComponent = getAddressComponentByType(place.address_components, POSTAL_CODE_TYPE);
      let stateComponent = getAddressComponentByType(place.address_components, STATE_TYPE);
      let countyComponent = getAddressComponentByType(place.address_components, COUNTY_TYPE);
      let cityComponent = getAddressComponentByType(place.address_components, CITY_TYPE);
      let streetAddressPart1Component = getAddressComponentByType(place.address_components, HOUSE_NUMBER_TYPE);
      let streetAddressPart2Component = getAddressComponentByType(place.address_components, STREET_NAME_TYPE);

      formattedResultObject.postalCode = postalCodeComponent?.long_name;
      formattedResultObject.state = stateComponent?.short_name.toUpperCase().trim();;
      formattedResultObject.county = countyComponent?.long_name;
      formattedResultObject.serviceCity= cityComponent?.long_name.toUpperCase().trim();
      formattedResultObject.streetAddress= (streetAddressPart1Component && streetAddressPart2Component) ? `${streetAddressPart1Component.long_name} ${streetAddressPart2Component.long_name}` : '';

      formattedResultObject.lat = place.geometry.location.lat();
      formattedResultObject.lng = place.geometry.location.lng();

      //as a failsafe, try to parse city and/or service address from place.formatted_address
      if (!place.formatted_address || formattedResultObject.serviceCity) {
        //do nothing
      } else if ( !formattedResultObject.streetAddress && place.formatted_address.split(",").length > 2 ) {
        //ex: StreetAddress, Bismarck, ND 58501
        formattedResultObject.streetAddress = place.formatted_address.split(",")[0].trim();
        formattedResultObject.serviceCity = place.formatted_address.split(",")[1].toUpperCase().trim();
      } else if ( place.formatted_address.split(",").length === 2 ) {
        //ex: Boise, ID
        formattedResultObject.serviceCity = place.formatted_address.split(",")[0].toUpperCase().trim();
      }

      if(formattedResultObject.streetAddress){
        refMap.current.setZoom(19);
      }else{
        refMap.current.setZoom(12);
      }

      props.onPlaceSearchedInMap(formattedResultObject);
    }

    const findPlaceFromQuery_Callback = (results, status) => {
      if(!(status===window.google.maps.places.PlacesServiceStatus.OK) || !results){
        console.log('Unable to find location. code: x111');
        debouncedInfoToast("Unable to find address. Use map controls to move pin to construction location and enter nearest cross street in field below.")
        return;
      }

      refMap.current.setCenter(results[0].geometry.location);

      refPlacesService.current.getDetails({ placeId: results[0].place_id}, getDetailsFromPlaces_Callback);
    }

    refPlacesService.current.findPlaceFromQuery(request, findPlaceFromQuery_Callback);
  }

  //"useMemo" will 'memoize' or 'cache' the result of this function. 
  //Every time this component re-renders, instead of recalculating this component, the cached result will be returned instead.
  //This prevents the map from re-rending every time the marker location changes, etc.
  const renderMap = useMemo(()=>{
    if (loadError) {
      return (<div>Failed to load Map</div>)
    }
  
    if (!isLoaded || !props.mapReadyToLoad) {
      return (<div>Map Loading...</div>)
    }

    const mapContainerStyle = {
      height: 500,
    };

    const mapOptions = {
      center: {
        lat: Number(props.initialLat) || 43.5935084,
        lng: Number(props.initialLng) || -116.2750878
      },
      disableDefaultUI: true,
      fullscreenControl: true,
      gestureHandling: 'cooperative', //user must use two fingers or hit "ctrl" to scroll page (makes accidental map moving less likely)
      mapTypeControl: true, //chose map or satellite at bottom left
      mapTypeControlOptions: {
        position: window?.google?.maps?.ControlPosition?.LEFT_BOTTOM || 6.0,//,
        mapTypeIds: [
          window?.google?.maps?.MapTypeId?.TERRAIN || "terrain",
          window?.google?.maps?.MapTypeId?.HYBRID || "hybrid",
        ],
      },
      mapTypeId: window?.google?.maps?.MapTypeId?.HYBRID || 'hybrid',
      tilt: 0,
      zoom: (!!props.initialLat ? 19 : 10),
      zoomControl: true,
    }

    const autocompleteProps = {
      restrictions: { country: "us" },
      fields: ["address_components", "geometry"],
      types: ["geocode"],
      onPlaceChanged: locationSearch,
      onLoad: onAutocompleteLoad,
    };

    return (
      <GoogleMap
        onLoad={onMapLoad}
        onUnmount={onMapUnmount}
        options={mapOptions}
        mapContainerStyle={mapContainerStyle}
        onCenterChanged={() => {
          if (refMap.current !== null) {
            onMapCenterChanged()
          }
        }}
      >
        <>
          <Col xl={5} md={7} sm={9} xs={9} id="mapTopLeftCtrl">
            <InputGroup className="m-2">
              <Autocomplete {...autocompleteProps} >
                <Form.Control
                  onKeyDown={(event) => {
                    //if user presses enter key, perform search, don't submit form
                    if (event.key === 'Enter') {
                      event.preventDefault();
                    }
                  }}
                  ref={refSearchInput}
                  id="addressSearchInputId"
                  placeholder="Search Address"
                  aria-label="Enter zip code or address here to search"
                  type="text"
                />
              </Autocomplete>
              <Button variant="primary" onClick={() => locationSearch()}><FontAwesomeIcon icon={faMagnifyingGlass} />&nbsp;Find</Button>
              {window.navigator && window.navigator.geolocation && (
                <Button onClick={() => centerMapOnCurrentLocation(true)} variant="primary" title="Go to Current Location"><FontAwesomeIcon icon={faLocationDot} /></Button>
              )}
            </InputGroup>
          </Col>
        </>
      </GoogleMap>
    )
  }, [isLoaded, loadError, props.mapReadyToLoad])

  return (
    <>
      {renderMap}
    </>
  )
}

export default React.memo(MapSelector)