import React from "react";
import {Dropdown, Navbar} from "react-bootstrap";
import { logout, getBrandForHeader, translatedURL } from "../../util/functions";
import {UserGearIcon} from '../profile/ProfileIcons';
import LoggedInUserName from "./LoggedInUserName";

class MainNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brandShortName: getBrandForHeader(),
      type: "navBar",
      redirect: false,
      authError: false,
      isLoading: true,
      location: {},
    };
  }

  render() {
    const logOut = () => {
      logout({success: 'logout'})
    };

    const Profile = () => {
      window.location.href =  translatedURL("/profile");
    };

    // The forwardRef is important!!
    // Dropdown needs access to the DOM node in order to position the Menu
    const ProfileImageToggle = React.forwardRef(({ children, onClick }, ref) => (
      <a
        href="#!"
        className="text-decoration-none text-white mx-2"
        style={{display: "block"}}
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}&#x25bc;
      </a>
    ));

    return (
      <div className={this.state.brandShortName}>
        <nav className="navbar navbar-expand-lg navbar-dark bg-brand">
          <Navbar.Brand href={translatedURL("/dashboard")} className="dashboardHeading">
            <span className="dashboardHeadingColor">
              <strong className="uppercase">{this.state.brandShortName}</strong> Construction Self Service
            </span>
          </Navbar.Brand>

          <span className="ms-auto" style={{display: "flex"}}>
            <LoggedInUserName />

            <Dropdown >
              <Dropdown.Toggle as={ProfileImageToggle} id="dropdown-basic">

                <UserGearIcon height={35} />

              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={Profile} >View Profile</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={logOut} className="text-danger">Log Out</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </span>

        </nav>
      </div>
    );
  }
}

export default MainNav;