import React from "react";
import SmartTextBox from "../lib/SmartTextBox";
import $ from "jquery";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import SmartLabel from "../lib/SmartLabel";
import { translatedURL } from "../../util/functions";
class EditProfile extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      type: "editProfilePage",
      profileType: "",
      name: "",
      companyName: "",
      companyPhone: "",
      companyEmail: "",
      // mailing address
      streetAddress: "",
      city: "",
      state: "",
      zipCode: "",
      // billing address
      billingStreetAddress: "",
      billingCity: "",
      billingState: "",
      billingZipCode: "",
      billingAddressSame: true,
      builder: null,
      redirect: false,
      authError: false,
      isLoading: true,
      location: {},
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    $.ajax({
      url: `/api/user/profile`,
      dataType: "json",
      success: (result) => {
        this.setState({
          profileType: result.profileType,
          companyPhone: result.companyPhone,
          companyEmail: result.companyEmail,
          companyName: result.companyName,
          // mailing
          streetAddress: result.streetAddress,
          city: result.city,
          state: result.state,
          zipCode: result.zipCode,
          // billing
          billingStreetAddress: result.billingStreetAddress,
          billingCity: result.billingCity,
          billingState: result.billingState,
          billingZipCode: result.billingZipCode,
          billingAddressSame: (result.streetAddress == result.billingStreetAddress && result.city == result.billingCity && result.state == result.billingState && result.zipCode == result.billingZipCode),

          builder: result.builder,
        });
      },
      complete: (res) => {
        this.setState({
          isLoading: false
        });
      },
    });
  }

  handleInputChange(event) {
    let newValue;
    if (event.target.name == "billingAddressSame") {
      newValue = !this.state.billingAddressSame;
    } else {
      newValue = event.target.value;
    }

    //if needed, set billingAddress values to be same as regular address
    if ((event.target.name == "billingAddressSame" && !this.state.billingAddressSame) || (event.target.name != "billingAddressSame" && this.state.billingAddressSame)) {
      let stateValues = {
        billingState: this.state.state,
        billingStreetAddress: this.state.streetAddress,
        billingZipCode: this.state.zipCode,
        billingCity: this.state.city,
      }

      stateValues[event.target.name] = newValue
      stateValues["billing" + event.target.name.charAt(0).toUpperCase() + event.target.name.slice(1)] = newValue

      this.setState({ ...stateValues });
    } else {
      this.setState({ [event.target.name]: newValue });
    }
  }

  ConditionalWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children;

  render() {
    const handleSubmit = (event) => {
      event.preventDefault();

      if (!this.formRef.current.checkValidity()) {
        this.formRef.current.classList.add('was-validated');
        return false
      }

      this.setState({ isLoading: !this.state.isLoading });

      let bodyFormData = {
        profileType: this.state.profileType,
        companyName: this.state.companyName,
        companyPhone: this.state.companyPhone,
        companyEmail: this.state.companyEmail,
        // mailing
        streetAddress: this.state.streetAddress,
        city: this.state.city,
        state: this.state.state,
        zipCode: this.state.zipCode,
        // billing
        billingStreetAddress: this.state.billingStreetAddress,
        billingCity: this.state.billingCity,
        billingState: this.state.billingState,
        billingZipCode: this.state.billingZipCode,

        profileId: this.state.profileId,
      };
      $.ajax({
        url: `/api/user/profile`,
        dataType: "html",
        method: "POST",
        data: JSON.stringify(bodyFormData),
        contentType: "application/json; charset=utf-8",
        success: (result) => {
          if (this.props.displayedInModal) {
            this.props.showModal(false);
          } else {
            window.location.href = translatedURL("/profile");
          }
        },
        error: (error) => {
          this.setState({ authError: true });
        },
      });
    };

    return (
      <React.Fragment>
        <this.ConditionalWrapper
          condition={!this.props.displayedInModal}
          wrapper={children =>
            // add additional formatting when displayed in own page (as opposed to inside a modal)
            <div className="bg-light">
              <div className="maincontainer">
                <div className="container-fluid">
                  <div className="row justify-content-center no-gutter">
                    <div className="col-12">
                      <div className="d-flex align-items-center">
                        <div className="container pt-4 mb-4">
                          <div className="row customBorder mb-4">
                            {children}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        >
          <div className="col-12">

            <h3 className="text-center text-primary">
              {this.props.displayedInModal ? "" : "Edit Profile"}
            </h3>
            {
              this.state.isLoading ? <SkeletonLoader count={7} height={50} />
                :
                <form ref={this.formRef} onSubmit={handleSubmit} className="row mt-4 needs-validation" noValidate>
                  {this.state.builder &&
                    <>
                      <SmartLabel
                        id="companyName"
                        label="Company Name"
                        placeholder="Company Name"
                        value={this.state.companyName}
                      />
                      <SmartTextBox
                        id="companyPhone"
                        label="Company Phone"
                        type="phone"
                        placeholder="Company Phone"
                        value={this.state.companyPhone}
                        onChange={this.handleInputChange}
                        invalidFeedback="Please provide a valid Company Phone."
                      />
                      <SmartTextBox
                        id="companyEmail"
                        label="Company Email"
                        maxLength="100"
                        type="email"
                        placeholder="Company Email"
                        value={this.state.companyEmail}
                        onChange={this.handleInputChange}
                        invalidFeedback="Please provide a valid Company Email."
                      />

                    </>
                  }
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3" ></div>

                  <h5>Mailing Address</h5>
                  <SmartTextBox
                    id="streetAddress"
                    label="Street Address"
                    placeholder="Street Address"
                    value={this.state.streetAddress}
                    onChange={this.handleInputChange}
                    invalidFeedback="Please provide a valid Street Address."
                    required
                  />
                  <SmartTextBox
                    id="city"
                    label="City"
                    placeholder="City"
                    value={this.state.city}
                    onChange={this.handleInputChange}
                    invalidFeedback="Please provide a valid City."
                    required
                  />
                  <SmartTextBox
                    id="state"
                    label="State"
                    placeholder="State"
                    value={this.state.state}
                    onChange={this.handleInputChange}
                    invalidFeedback="Please provide a valid State."
                    required
                  />
                  <SmartTextBox
                    id="zipCode"
                    label="Zip Code"
                    placeholder="Zip Code"
                    type="zip"
                    value={this.state.zipCode}
                    onChange={this.handleInputChange}
                    invalidFeedback="Please provide a valid Zip Code. (format 12345 or 12345-1234)"
                    required
                  />
                  <h5>Billing Address</h5>
                  <div className="form-check mb-3 ms-3">
                    <input
                      className={'form-check-input'}
                      type="checkbox"
                      name={"billingAddressSame"}
                      id={"billingAddressSame"}

                      checked={this.state.billingAddressSame}
                      onChange={this.handleInputChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={"billingAddressSame"}
                    >
                      Billing address is same as mailing address
                    </label>
                  </div>


                  <SmartTextBox
                    id="billingStreetAddress"
                    label="Street Address"
                    placeholder="Street Address"
                    value={this.state.billingStreetAddress}
                    onChange={this.handleInputChange}
                    invalidFeedback="Please provide a valid Street Address."
                    required
                    disabled={this.state.billingAddressSame || undefined}
                  />
                  <SmartTextBox
                    id="billingCity"
                    label="City"
                    placeholder="City"
                    value={this.state.billingCity}
                    onChange={this.handleInputChange}
                    invalidFeedback="Please provide a valid City."
                    required
                    disabled={this.state.billingAddressSame || undefined}
                  />
                  <SmartTextBox
                    id="billingState"
                    label="State"
                    placeholder="State"
                    value={this.state.billingState}
                    onChange={this.handleInputChange}
                    invalidFeedback="Please provide a valid State."
                    required
                    disabled={this.state.billingAddressSame || undefined}
                  />
                  <SmartTextBox
                    id="billingZipCode"
                    label="Zip Code"
                    placeholder="Zip Code"
                    type="zip"
                    value={this.state.billingZipCode}
                    onChange={this.handleInputChange}
                    invalidFeedback="Please provide a valid Zip Code. (format 12345 or 12345-1234)"
                    required
                    disabled={this.state.billingAddressSame || undefined}
                  />

                  <div className="col-12 text-center">
                    {!this.props.displayedInModal && <a href={translatedURL("/profile")} className="mx-3 btn btn-primary btn-block text-uppercase mb-2 shadow-sm btn-sm">Cancel</a>}
                    <button
                      type="submit"
                      className="btn btn-primary btn-block text-uppercase mb-2 shadow-sm btn-sm"
                    >
                      {this.props.displayedInModal ? "Create" : "Update"}
                    </button>
                  </div>
                </form>
            }
          </div>
        </this.ConditionalWrapper>
      </React.Fragment>
    );
  }
}

export default EditProfile;