import React from "react";
import SmartTextBox from "../lib/SmartTextBox";
import $ from "jquery";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import PasswordStrengthMessage from "../register/PasswordStrengthMessage";
import { getPasswordStrength } from "../../util/functions";
import { toast } from "react-toastify";
import { Fade } from "react-bootstrap";
import CustomAlert from "../lib/Alert";

class EditUserAndChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      type: "editUserPage",
      userData: null,
      redirect: false,
      authError: false,
      isLoading: true,

      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      passwordStrength: 0,

      passwordsDoNotMatch: false,
    };
  }

  componentDidMount() {
    $.ajax({
      url: "/api/user/profileAndUser",
      dataType: "json",
      method: "GET",
      success: (result) => {
        this.setState({
          userData: result.user,
          isLoading: false,
        });
      },
    });
  }

  handleInputChange = (event) => {
    let optional = {};

    if (event.target.name == 'newPassword') {
      optional = {
        passwordStrength: getPasswordStrength(event.target.value),
        passwordsDoNotMatch: (this.state.confirmNewPassword !== event.target.value)
      }
    }
    if (event.target.name == 'confirmNewPassword') {
      optional = { passwordsDoNotMatch: this.state.newPassword !== event.target.value }
    }

    this.setState(prevState => ({
      userData: { //user data
        ...prevState.userData,
        [event.target.name]: event.target.value
      },
      [event.target.name]: event.target.value, //password data
      ...optional, //passwordsDoNotMatch or other conditional things.
    }));
  }

  render() {
    const handleSubmit = (event) => {
      event.preventDefault();

      if (!this.formRef.current.checkValidity()) {
        this.formRef.current.classList.add('was-validated');
        return false
      }

      if (this.state.newPassword != this.state.confirmNewPassword) {
        return toast.error("Confirm new password does not match with new password")
      }

      if (this.state.passwordStrength < 4) {
        return toast.error("New Password not strong enough")
      }

      let dataObject = {
        userData: this.state.userData,
        passwordData: {
          newPassword: this.state.newPassword,
          confirmNewPassword: this.state.confirmNewPassword,
        }
      }

      $.ajax({
        url: `/api/user/initial-user-and-password`,
        method: "PUT",
        
        data: JSON.stringify(dataObject),
        contentType: "application/json",
        success: (result) => {
          toast.success("Information updated successfully")
          this.props.showModal(false);
        },
        error: (error) => {
          toast.error(error.responseText)
          this.setState({ authError: true });
        },
      });
    };

    return (
      <React.Fragment>
          <div className="col-12">
            <h3 className="text-center text-primary">
              Edit User
            </h3>

            {this.state.isLoading && <SkeletonLoader count={3} height={30} />}
            <Fade in={!this.state.isLoading}>
              <form ref={this.formRef} onSubmit={handleSubmit} className="row mt-4 needs-validation" noValidate>
                <SmartTextBox
                  id="name"
                  label="Name"
                  placeholder="Name"
                  value={this.state.userData? this.state.userData.name : undefined}
                  onChange={this.handleInputChange}
                  invalidFeedback="Please provide a valid Name."
                  required
                />
                <SmartTextBox
                  id="email"
                  label="Email"
                  maxLength="100"
                  type="email"
                  placeholder="Email"
                  value={this.state.userData? this.state.userData.email : undefined}
                  onChange={this.handleInputChange}
                  invalidFeedback="Please provide a valid Email."
                  disabled
                />
                <SmartTextBox
                  id="phone"
                  label="Phone"
                  type="phone"
                  placeholder="Phone Number"
                  tooltip="Start entering numbers starting with your area code. We will take care of the formatting for you"
                  value={this.state.userData? this.state.userData.phone : undefined}
                  onChange={this.handleInputChange}
                  invalidFeedback="Please provide a valid Phone Number."
                  required
                />

                <div className="col-12" ></div>

                <PasswordStrengthMessage passwordStrength={this.state.passwordStrength} />
                <SmartTextBox
                  id="newPassword"
                  label="New Password"
                  type="password"
                  tooltip="Please use uppercase letters, lower case letters, special characters and numbers to create a strong password"
                  placeholder="New Password"
                  value={this.state.newPassword}
                  onChange={this.handleInputChange}
                  invalidFeedback="Please provide valid new password."
                  required
                />
                <SmartTextBox
                  id="confirmNewPassword"
                  label="Confirm New Password"
                  type="password"
                  placeholder="New Password"
                  value={this.state.confirmNewPassword}
                  onChange={this.handleInputChange}
                  invalidFeedback="Please provide valid new password."
                  required
                />

                {!!this.state.confirmNewPassword && this.state.passwordsDoNotMatch && (
                  <CustomAlert
                    variant="danger"
                    message="Passwords do not match"
                  ></CustomAlert>
                )}
                {this.state.newPassword && this.state.passwordStrength < 4 && (
                  <CustomAlert
                    variant="danger"
                    message="Password not strong enough. Please use uppercase letters, lower case letters, special characters, and numbers"
                  ></CustomAlert>
                )}
                <div className="col-12 text-center float-start">
                  <button
                    disabled={this.state.isLoading || this.state.passwordsDoNotMatch || this.state.passwordStrength < 4}
                    type="submit"
                    className="btn btn-primary btn-block text-uppercase mb-2 shadow-sm btn-sm float-end"
                  >
                    Update
                  </button>
                </div>
              </form>
            </Fade>
          </div>
      </React.Fragment>
    );
  }
}

export default EditUserAndChangePassword;