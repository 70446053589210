import React from "react";
import $ from 'jquery';
import { OverlayTrigger, Popover } from "react-bootstrap";

/*
Use this to enforce viewing of a document before allowing a checkbox value to be selected

props:
    required: 
        label -- checkbox label
        documentUrl
        documentTitle -- title of the document link
        selectedValue -- the default checkbox value (whether reading of the document has been acknowledged)
        onChange -- function to call when the checkbox is checked/unchecked
        userPreferenceKey -- name of the user preference specific to the document being viewed
        invalidFeedback
    optional:
        workTypeShowList
        workType
        multipleShowLists (type String[][])
        multipleDeciders (type String[])
        headerText -- Text that will display over the whole section (similar to the "label" prop from SmartTextBox)

example:
    <SmartDocumentViewer
        id="landscapingRequirementsDocumentViewer"
        label="I have reviewed the landscaping guidelines"
        documentUrl={getLink("landscaping-guidelines")}
        documentTitle="Landscaping Guidelines"
        selectedValue={this.state.landscapingAcknowledgement}
        onChange={(event) => this.handleInputChange(event, {name: "landscapingAcknowledgement", value: !this.state.landscapingAcknowledgement})}
        userPreferenceKey="landscapingGuidelinesViewExpiryDate"
        invalidFeedback="Please review the landscaping guidelines"
        multipleShowLists={[["YES"]]}
        multipleDeciders={[this.state.trenchCustomer]}
        headerText="Please Review the Following Document:"
    />
*/

class SmartDocumentViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.generateId(),
            classTest: 'form-control shadow-sm px-4',
            hasViewedDocument: false,
        }
    }

    generateId() {
        const length = 15;
        const prefix = 'smartDocumentViewer_';
        let uniqueId = prefix + Math.random().toString(36).substring(2, length);
        return uniqueId;
    }

    componentDidMount() {
        $.ajax({
            url: "/api/user/preference/get?key=" + this.props.userPreferenceKey,
            method: "GET",
            success: (userPreference) => {
                let documentAcknowledgement = !!this.props.selectedValue && this.props.selectedValue.toUpperCase() == 'YES';
                let hasViewedDocument = documentAcknowledgement || (!!userPreference && !userPreference.timestampPassed);
                this.setState({
                    hasViewedDocument: hasViewedDocument,
                });
            },
            error: (e) => {
                console.error(e.responseText);
            }
        });
    }

    render() {
        const checkWorkTypeShowList = () => {
            if (!this.props.workType) return false; //list given but no work type
            try {
                return this.props.workTypeShowList.includes(Number(this.props.workType));
            } catch (e) {
                return false;
            }
        }

        const checkMultipleShowLists = () => {
            if (!this.props.multipleDeciders) return false; //list given but no deciders

            let fulfillsAllParameters = true;
            for (let i = 0; i < this.props.multipleShowLists.length; i++) {
                if (!this.props.multipleShowLists[i].includes(this.props.multipleDeciders[i])) {
                    fulfillsAllParameters = false;
                }
            }

            return fulfillsAllParameters;
        }

        const handleOnCheckboxChange = (event) => {
            let checkboxEvent = {
                target: {
                    value: !!this.props.selectedValue && this.props.selectedValue.toUpperCase() == 'YES' ? 'NO' : 'YES'
                }
            }
            this.props.onChange(checkboxEvent)
            checkIsValid(event);
        }

        const handleDocumentView = (event) => {
            this.setState({hasViewedDocument: true});
            
            let date = new Date();
            date.setDate(date.getDate() + 30); // Set the expiry date 30 days out
            let timestamp = date.getTime();
            $.ajax({
                url: "/api/user/preference/set?key=" + this.props.userPreferenceKey + "&timestamp=" + timestamp,
                method: "PUT",
                error: (e) => {
                    console.error(e.responseText);
                }
            });
        }

        const show = () => {
            if (this.props.multipleShowLists && this.props.workTypeShowList) {
                return (checkWorkTypeShowList() && checkMultipleShowLists());
            } else if (this.props.workTypeShowList) {
                return checkWorkTypeShowList();
            } else if (this.props.multipleShowLists) {
                return checkMultipleShowLists();
            }
            return true;
        }

        const defaultClass='form-control shadow-sm px-4';
        const invalidClass='is-invalid';
        const validClass='is-valid';

        const markInvalid = () => {
            this.setState({classTest: `${defaultClass} ${invalidClass}`});
        }

        const markValid = () => {
            this.setState({classTest: `${defaultClass} ${validClass}`});
        }

        const checkIsValid = (e) => {
            if(this.state.isChecked) markValid();
            else markInvalid();
        }

        //separate managed props from other unmanaged props. Unmanaged props will be added directly to the input element.
        var { className, label, id, documentUrl, documentTitle, userPreferenceKey, invalidFeedback,  workType, workTypeShowList, multipleDeciders, multipleShowLists, onChange, headerText, ...unmanagedProps } = this.props;

        if(!id) {
            id = this.state.id;
        }

        if(!headerText) {
            headerText = "Please view the following document:"
        }
        return (
            <React.Fragment>
                {show() && 
                    <div className={"col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3"}>
                        <label
                            className="form-label pb-0"
                        >
                            {headerText} {!!this.props.required && (<span style={{ color: 'red' }}>*</span>)}

                            { 
                                this.props?.tooltip ?
                                    <OverlayTrigger trigger={['hover', 'focus']} placement="auto-end" overlay={<Popover title="Popover right"> 
                                    {this.props?.tooltip}
                                     </Popover>}>
                                        <span className="questionIcon">?</span>
                                    </OverlayTrigger>
                                : ""
                            }
                        </label>

                        <div>
                            <a
                                href={this.props.documentUrl}
                                target="_blank" 
                                rel="noopener noreferrer"
                                onClick={handleDocumentView}
                            >
                                {this.props.documentTitle}
                            </a>
                        </div>
                        <input
                            type="checkbox"
                            style={{display: this.state.hasViewedDocument ? 'inline' : 'none'}}
                            name={id + "Input"}
                            id={id + "Input"}
                            checked={!!this.props.selectedValue && this.props.selectedValue.toUpperCase() == 'YES'}
                            onChange={handleOnCheckboxChange}
                            {...unmanagedProps}                                
                        />
                        <label
                            style={{display: this.state.hasViewedDocument ? 'inline' : 'none'}}
                            htmlFor={id = "Input"}
                            className="p-2"
                        >
                            {this.props.label}{!!this.props.required && (<span style={{ color: 'red' }}>*</span>)}
                        </label>
                        <div className="invalid-feedback">
                            {this.props.invalidFeedback}
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}

export default SmartDocumentViewer;