import React from "react";
import $ from "jquery";
import { translatedURL } from "../../util/functions";

class VerifyCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "verifyCodePage",
      securityCode: "",
    };
  }

  componentDidMount() {
    this.props.setPageHeaders("Password Reset", "Construction Self Service Portal");
  }

  render() {
    const handleCodeChange = (event) => {
      this.setState({ securityCode: event.target.value });
      this.props.clearError();
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      this.props.loading(true);
      //verify code
      $.ajax({
        url: "/api/forgot-password/check-security-code",
        dataType: "html",
        method: "POST",
        data: this.state.securityCode,
        contentType: "text/plain; charset=utf-8",
      }).done( () => { //success
        this.props.forgotPasswordCodeVerified();
      }).fail((result) => { //fail
        this.props.showError(result.responseText);
        this.props.loading(false);
      });
    };

    return (
      <div>
        <p >
          Please enter the 8-digit security code that was emailed to you.
        </p>
        <p>
          <strong>NOTE:</strong> Do not close this window. If you need to use a browser to open your email, open a new window or tab to do so.
        </p>

        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <input
              type="text"
              placeholder="Security Code"
              required
              onChange={handleCodeChange}
              className="form-control  border-0 shadow-sm px-4 text-primary"
            />
          </div>

          <div className="mt-2 clearfix">

            <button
              type="submit"
              className="btn btn-success btn-block text-uppercase mb-2 shadow-sm w-100 float-end"
            >
              Verify Code
            </button>
          </div>

          <div className="mt-2 text-center">
            <a href={translatedURL("/login")}>
              Return to Login
            </a>
          </div>
        </form>
      </div>
    );
  }
}


export default VerifyCode