import React from "react";
import { images } from "../../assets/images/images";
import { Row, Col, Container } from "react-bootstrap";

class SupportBrowser extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    let browsers = [
      { img: images.chrome, text: "Google Chrome" },
      { img: images.edge, text: "Microsoft Edge" },
      { img: images.firefox, text: "Mozilla Firefox" },
      { img: images.safari, text: "Apple Safari" },
    ]
    return (
      <Col xs={12} className='bg-brand text-center'>
        <br />
        <p className="footerTextColor">Supported Browsers</p>

        <div className="footer-icons">
          {
            browsers.map((b, index) => (
              <span key={index} style={{ whiteSpace: 'nowrap' }} className="d-inline-block mx-1 mt-1">
                <img src={b.img} width="30px" alt={b.text} /> {b.text}
              </span>
            ))
          }
        </div>
      </Col>
    )
  }
}

export default SupportBrowser;