import { getHelpLineNumber } from "../../../util/functions";

export const statusTooltip = {
    "Under Review": "This request is under review ",
    "Submitted": "This request has been submitted to our internal system. If the status doesn't switch to \"New\" in the next 1-2 business days, please contact us at " + getHelpLineNumber() + " to make sure the message has not been lost.",
    "Suspended": "This status usually means that this SR was waiting for an update from you for more than 30 days. However some other issue might have occurred. Please contact whoever was working with you on this SR for more information.",
    "In Design": "Your request is being worked on.",
    "New": "This request has been received by our internal system",
    "Customer Input Required": "This request has been returned to you to add additional information. Please check your email to know what additional information is being requested."
};

//used to sort notes columns in dashboard table. React-table doesn't ignore case by default.
export const sortItemsIgnoreCase = (prev, curr, columnId) => {
    let a = prev.values[columnId] || '';
    let b = curr.values[columnId] || '';

    if ((a.toLowerCase() || '') > b.toLowerCase()) {
        return 1;
    } else if (a.toLowerCase() < b.toLowerCase()) {
        return -1;
    } else {
        return 0;
    }
};

export const sortItemsByDate = (prev, curr, columnId) => {
    let aString = prev.values[columnId] || false;
    let bString = curr.values[columnId] || false;

    if(!bString) return 1;
    if(!aString) return -1;

    return new Date(aString) - new Date(bString);
};

export const formatStringAsDate = (ds, includeTime, convertFromUTC) => {
    if (!ds) {
        return '';
    }
    if (convertFromUTC) {
        ds += "Z"
    }
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: (!!includeTime ? 'numeric' : undefined), minute: (!!includeTime ? 'numeric' : undefined) };
    let formattedDate = new Date(ds.toString());
    return formattedDate.toLocaleDateString('en-us', options);
}

export function getBootstrapBreakpoint() {
    /*
    X-Small 	        None 	<576px
    Small 	            sm 	    ≥576px
    Medium  	          md 	    ≥768px
    Large 	            lg 	    ≥992px
    Extra large         xl 	    ≥1200px
    Extra extra large 	xxl 	≥1400px
    */
    var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    if (w >= 1400) return {int: 5, str: 'xxl'};
    if (w >= 1200) return {int: 4, str: 'xl'};
    if (w >= 992) return {int: 3, str: 'lg'};
    if (w >= 768) return {int: 2, str: 'md'};
    if (w >= 576) return {int: 1, str: 'sm'};
    return {int: 0, str: ''};
}

export const wtm = { //WORK TYPE MAP
    GAS_SERVICE_INSTALL: 2, //related to combo 14
    GAS_SERVICE_RELOCATE: 4,
    GAS_MAIN_RELOCATE: 5,
    GAS_INSTALL_NEW_SUBDIVISION: 6, //related to combo 13
    ELECTRIC_SERVICE_INSTALL: 7, //related to combo 14
    ELECTRIC_LIGHT_INSTALL: 8,
    ELECTRIC_SERVICE_RELOCATE: 9,
    ELECTRIC_LIGHT_RETIRE: 10,
    ELECTRIC_PRIMARY_RELOCATE: 11,
    ELECTRIC_DEVELOPER_SUBDIVISION: 12, //related to combo 13
    COMBO_SUBDIVISION: 13,
    COMBO_SERVICE_INSTALL: 14
}