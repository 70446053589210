import {FormCheck} from 'react-bootstrap';
function AccordionTableSwitch(props) {//tabelHiddenLevel, setTableDisplay
   
  let isChecked;
  if(props.tableDisplay == 'auto') {
    isChecked = false;
  }else if (props.tableDisplay == 'table') {
    isChecked = true;
  }else {
    isChecked = false;
  }

  return (
    <div>
      <FormCheck
        checked={isChecked}
        onChange = { (e) => props.setTableDisplay(e) }
        type="switch"
        id="custom-switch"
        label={`Selected View: ${isChecked ? 'Table' : 'Accordion'}`}
      />
    </div>
  );
}

export default AccordionTableSwitch;