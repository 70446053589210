import React from "react";
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table";
import Pagination from 'react-bootstrap/Pagination';
import { SearchBar } from "./SearchBar";
import { Table, Accordion, Row, Container, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'
import SkeletonLoader from "../../SkeletonLoader/SkeletonLoader";

export default function DashboardTable({ columns, data, ...props }) {
  const defaultSort = React.useMemo(
    () => [
      {
        id: "Date Created",
        desc: true
      }
    ],
    []
  );

  const pageSizeOptions = [10, 30, 50];

  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { globalFilter, pageIndex, pageSize},
    
  } = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      autoResetGlobalFilter: false,
      initialState: {
        sortBy: defaultSort,
        pageIndex: 0,
        pageSize: pageSizeOptions[0],
      }
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  return (
    <>
      <Row>
        <Col xs={{span: 12, order: 1}} sm={{span: 6, order: 2}}>
          <Pagination size="sm" className="float-sm-end">
            <Pagination.Item disabled>
              <strong>Items to show</strong>
            </Pagination.Item>
            {pageSizeOptions.map(pageSizeOption => (
              <Pagination.Item
                key={pageSizeOption}
                value={pageSizeOption}
                onClick={e => {setPageSize(pageSizeOption)}}
                active={pageSizeOption == pageSize}
              >
                {pageSizeOption}
              </Pagination.Item>
            ))}
          </Pagination>
        </Col>
        <Col xs={{span: 12, order: 2}} sm={{span: 6, order: 1}}>
          <SearchBar
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </Col>
      </Row>

      {/* larger screens */}
      {(props.mode == 'auto' || props.mode == 'table') && (
        <Table striped bordered hover responsive {...getTableProps()} style={{tableLayout: 'fixed', ...props.style}}
          className={props.mode === 'auto' ? "d-none d-md-table" : props.mode === 'table' ? undefined : 'd-none'}
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, i1) => (
                  <th 
                    {...column.getHeaderProps(column.getSortByToggleProps() )}
                    style={{width: (!!column.colWidth ? column.colWidth : undefined)}}
                    key={i1}
                  >
                    <span className="float-start">
                      {column.render("Header")}
                    </span>
                    {!column.disableSortBy && 
                      <span className="float-end" style={{width: '1em'}}>
                        { 
                          <>
                            {/* comment if you'd like to not show which columns are sortable */}
                            {!column.isSorted && <FontAwesomeIcon icon={faSort} /> }
                            {column.isSorted && column.isSortedDesc && <FontAwesomeIcon icon={faSortDown} /> }
                            {column.isSorted && !column.isSortedDesc && <FontAwesomeIcon icon={faSortUp} /> }
                          </>
                        }
                      </span>
                    }
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {!!props.isLoading && (
              <tr>
                <td colSpan={columns.length} style={{ textAlign: 'center' }} > <SkeletonLoader count={4} height={40} /> </td>
              </tr>
            )}
            {(!page || page.length === 0) && !props.isLoading && (
              <tr>
                <td colSpan={columns.length}>No Results</td>
              </tr>
            )}
            {(page && page.length > 0) && !props.isLoading && (
              <>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} key={i}>
                      {row.cells.map((cell, x) => {
                        return <td {...cell.getCellProps()} className={cell.column.className || undefined} key={x}>{cell.render("Cell")}</td>;
                      })}
                    </tr>
                  );
                })}
              </>
            )}
          </tbody>
        </Table>
      )}
      {/* smaller screens */}
      {(props.mode == 'auto' || props.mode == 'accordion') && (
        <Accordion {...getTableProps()} className={props.mode === 'auto' ? "d-inline d-md-none" : props.mode === 'table' ? 'd-none' : undefined}>
          {(!page || page.length === 0) && (
            <>
              {!!props.isLoading && (
                <SkeletonLoader count={10} />
              )}
              {!props.isLoading && (
                <p>No Results</p>
              )}
            </>
          )}
          {(page && page.length > 0) && page.map((row, i) => {
            prepareRow(row);
            return (
              <Accordion.Item eventKey={i} key={i}>
                <Accordion.Header>
                  <Container>
                    <Row>
                      {row.cells.map((cell, y) => {

                        if(!cell.column.accordionHeader){
                          //React.Fragment is the same as a nothing <></> tag. 
                          //I just need to make sure I had something with a key={key} to keep react happy
                          //if accordionHeader is false or undefined, return the React equivalent of nothing.
                          return (
                            <React.Fragment key={y}></React.Fragment>
                          )
                        }

                        return (
                          <Col xs={6} key={y} className={cell.column.className || undefined}>
                            <span><b>{headerGroups[0].headers[y].render("Header")}:</b> <br /> {cell.render("Cell")}</span>
                          </Col>
                        );
                      })}
                    </Row>
                  </Container>
                </Accordion.Header>
                <Accordion.Body {...row.getRowProps()}>
                  <Container>
                    <Row>
                      {row.cells.map((cell, y) => {
                        if(cell.column.accordionBodyExclude != undefined && cell.column.accordionBodyExclude == true) {
                          return;
                        }
                        let divClass = "mb-1 " + (cell.column.className || '');
                        return (
                          <Col xl={3} lg={4} md={4} sm={4} xs={6} className={divClass} key={y}>
                            <span><b>{headerGroups[0].headers[y].render("Header")}:</b> <br /> {cell.render("Cell")}</span>
                          </Col>
                        );
                      })}
                    </Row>
                  </Container>
                </Accordion.Body>
            </Accordion.Item>
            );
          })}
        </Accordion>
      )}
      <Pagination>
        <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
        <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
        <Pagination.Item disabled>
          Page <strong>{pageIndex + 1} of {pageOptions.length}</strong>
        </Pagination.Item>
        <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
        <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
      </Pagination>
    </>
  );
}