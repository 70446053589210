import React from "react";
import SmartDropDownList from "../lib/SmartDropDownList";
import SmartTextBox from "../lib/SmartTextBox";
import { Popover, OverlayTrigger } from "react-bootstrap";

class ApplianceForm extends React.Component {
    constructor(props) {
        super(props);
        this.refForFocusing = React.createRef();
        this.state = {
            descriptionDropdownUrl: "",
            newButtonJustPressed: false,
        }
    }

    componentDidUpdate() {
        if(this.state.newButtonJustPressed) {
            if(this.refForFocusing.current) this.refForFocusing.current.focus();
            this.setState({
                newButtonJustPressed: false,
            });
        }
    }

    render() {
        
        const checkWorkTypeShowList = () => {
            if (!this.props.workType) return false; //list given but no work type
            try {
                return this.props.workTypeShowList.includes(Number(this.props.workType));
            } catch (e) {
                return false;
            }
        }

        const checkMultipleShowLists = () => {
            if (!this.props.multipleDeciders) return false; //list given but no deciders

            let fulfillsAllParameters = true;
            for (let i = 0; i < this.props.multipleShowLists.length; i++) {
                if (!this.props.multipleShowLists[i].includes(this.props.multipleDeciders[i])) {
                    fulfillsAllParameters = false;
                }
            }

            return fulfillsAllParameters;
        }

        const show = () => {
            syncApplianceEntries(); // Ensure that this is triggered anytime form changes are made

            if (this.props.alwaysShow) {
                return true;
            } else if (this.props.multipleShowLists && this.props.workTypeShowList) {
                return (checkWorkTypeShowList() && checkMultipleShowLists());
            } else if (this.props.workTypeShowList) {
                return checkWorkTypeShowList();
            } else if (this.props.multipleShowLists) {
                return checkMultipleShowLists();
            }

            return true; //if no params are passed in, always show
        }
        var { applianceList, onChange, workType, btuLabel, otherLabel, typeLabel, descriptionLabel, ...unmanagedProps } = this.props;
        
        //prep appliance list
        if(!applianceList) {
            //initialize and add empty row
            applianceList = [];
            applianceList.push({name: '', description: '', BTU: '', other: '', id: ''});
        }else if(applianceList.length == 0) {
            //already initialized. just add empty row
            applianceList.push({name: '', description: '', BTU: '', other: '', id: ''});
        }

        const formatResultForOnChange = (list) =>{
            //to keep things uniform, I need to send onChange function an object with value at event.target.value
            return ( { target: { value: list, } });
        }
        const addRow = () => {
            applianceList.push({name: '', description: '', BTU: '', other: '', id: ''});
            this.props.onChange(formatResultForOnChange(applianceList));
            this.setState({
                newButtonJustPressed: true,
            });
        }
        const deleteRow = (rowNum) =>{
            applianceList.splice(rowNum, 1); //removes list entry. 2nd parameter means to just remove one item
            this.props.onChange(formatResultForOnChange(applianceList));
        }
        const deleteAppliances = (applianceType) => {
            const prunedApplianceList = applianceList.filter(appliance => !appliance.name.includes(applianceType));
            if(applianceList.length === prunedApplianceList.length) return; // No items to delete
            applianceList = prunedApplianceList;
            this.props.onChange(formatResultForOnChange(applianceList));
        }
        const handleChange = (row, field, event) => {            
            //row is row number
            //field is name/description/btu
            applianceList[row][field] = event.target.value;
            if(field==='name') {
                applianceList[row].description = ''; //if first dropdown changes, clear second dropdown
                applianceList[row].other = '';
                applianceList[row].btu = '';
            }
            this.props.onChange(formatResultForOnChange(applianceList));
        }
        
        const getNameURL = (type) => {
            let url = '';
            switch(type.trim()) {
                case "APPLIANCE CODES - DRYER":
                    url = "/api/dropdownlist/maximo/MDUGAPPDRYER";
                    break;
                case "APPLIANCE CODES - AIR CONDITIONING":
                    url = "/api/dropdownlist/maximo/MDUGAPPAC";
                    break;
                case "APPLIANCE CODES - HEAT":
                    url = "/api/dropdownlist/maximo/MDUGAPPHEAT";
                    break;
                case "APPLIANCE CODES - MISCELLANEOUS":
                    url = "/api/dropdownlist/maximo/MDUGAPPMISC";
                    break;
                case "APPLIANCE CODES - POOL/HOT TUB":
                    url = "/api/dropdownlist/maximo/MDUGAPPPOOL";
                    break;
                case "APPLIANCE CODES - WATER HEATER":
                    url = "/api/dropdownlist/maximo/MDUGAPPWHEAT";
                    break;
                case "APPLIANCE CODES - RANGE":
                    url = "/api/dropdownlist/maximo/MDUGAPPRANGE";
                    break;
                case "APPLIANCE CODES - SNOWMELT BOILER":
                    url = "/api/dropdownlist/maximo/MDUGAPPSNOWMELT";
                    break;
                default:
                    url = "";
            } 
            return url;
        }

        const formatDropdownValuesFunction = (value) => {
            //APPLIANCE CODES - DRYER
            //only display what's after the first dash
            if(!value) return '';
            return value.substring(value.indexOf('-') + 1).trim();            
        }

        const newLineOnEnterPressed = (e) => {
            var keyCode = e.code || e.key;
            if (keyCode == 'Enter' || keyCode == 'NumpadEnter') {
                e.preventDefault();
                addRow();
            }
            return false;
        }
        const preventDefaultSubmitOnKeyDown = (e) => {
            var keyCode = e.code || e.key;
            if (keyCode == 'Enter' || keyCode == 'NumpadEnter') {
                e.preventDefault();
                return false;
            }
        }

        const syncApplianceEntries = () => {
            this.props.syncEntries.forEach((syncEntry) => {
                if(syncEntry.condition) {
                    var applianceIndex = applianceList.findIndex(appliance => appliance.name.includes(syncEntry.applianceType));
                    if(applianceIndex == -1) {
                        // Appliance does not exist, add appliance
                        var applianceToAdd = {name: syncEntry.applianceType, description: syncEntry.applianceDescription, BTU: '', other: '', id: ''};
                        // If the appliance list only contains a blank appliance, replace it with this appliance, otherwise add it to the list
                        if(applianceList.length == 1 && applianceList[0].name === '') {
                            applianceList[0] = applianceToAdd;
                        } else {
                            applianceList.push(applianceToAdd);
                        }                        
                    } else {
                        // Appliance exists, set description (this ensures user cannot change the description after the appliance has been added)
                        applianceList[applianceIndex].description = syncEntry.applianceDescription;
                    }
                } else {
                    // Remove all occurrences of this appliance type
                    deleteAppliances(syncEntry.applianceType);
                }
            });
        }

        return (
            <React.Fragment>
                {show() &&
                    <React.Fragment>
                        <h5 className="text-secondary mt-4" >
                            Gas Appliance/Equipment Information
                            <OverlayTrigger trigger={['hover', 'focus']} placement="auto-end" overlay={
                                <Popover title="Popover right"> 
                                    This information helps us design your service properly. Please provide accurate information.
                                    Inaccurate information my require future service line modifications at the customer's expense.
                                </Popover>
                            }>
                            <div className="questionIcon">?</div>
                            </OverlayTrigger>
                        </h5>
                        {applianceList && applianceList.map( (row, rowNum)=> (
                                <React.Fragment key={rowNum}>
                                    <span className="text-secondary">
                                        Appliance {rowNum+1}
                                    </span>
                                    {/* shown when screen is sm */}
                                    <div className="d-grid d-md-none gap-2 justify-content-end">
                                        <button 
                                            type="button" 
                                            disabled={(!applianceList || applianceList.length<=1) ? true : undefined} 
                                            onClick={() => deleteRow(rowNum)} 
                                            className="btn-close" >
                                        </button>
                                    </div>
                                    <SmartDropDownList
                                        innerRef={this.refForFocusing}
                                        className={formatDropdownValuesFunction(row.description) == "OTHER" ? "col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3" : "col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-3"}
                                        label={this.props.typeLabel}
                                        value={row.name || ''}
                                        onChange={(e) => handleChange(rowNum, "name", e)}
                                        invalidFeedback="Please provide a Type of Gas Appliances/Equipment."
                                        fetchURL={"/api/dropdownlist/maximo/MDUGAPPLIANCETYPE"}
                                        defaultText='Select Type'
                                        formatValueFunction = {formatDropdownValuesFunction}
                                        workTypeShowList={this.props.workTypeShowList}
                                        optionFilter={(option) => {
                                            // Check if a syncEntry exists for this dropdown option
                                            const syncEntry = this.props.syncEntries.find((syncEntry) => 
                                                option.value.includes(syncEntry.applianceType)
                                            );
                                            return syncEntry ? syncEntry.showOnDropdown : true;
                                        }}
                                        workType={workType}
                                        {...unmanagedProps}
                                    />
                                    <SmartDropDownList
                                        className={formatDropdownValuesFunction(row.description) == "OTHER" ? "col-xl-5 col-lg-5 col-md-5 col-sm-12 mb-3" : "col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-3"}
                                        label={this.props.descriptionLabel}
                                        value={row.description || ''}
                                        onChange={(e) => handleChange(rowNum, "description", e)}
                                        invalidFeedback="Please select an option for description."
                                        fetchURL={getNameURL(row.name)}
                                        disabled={!row.name}
                                        defaultText='Select Description'
                                        formatValueFunction = {formatDropdownValuesFunction}
                                        workTypeShowList={this.props.workTypeShowList}
                                        workType={workType}
                                        required={!!row.name || undefined}
                                        {...unmanagedProps}
                                    />
                                    <SmartTextBox
                                        label={this.props.otherLabel}
                                        className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3"
                                        maxLength="50"
                                        value={row.other || ''}
                                        onChange={(e) => handleChange(rowNum, "other", e)}
                                        invalidFeedback="Please enter description of other appliance/equipment"
                                        workTypeShowList={this.props.workTypeShowList}
                                        workType={workType}
                                        multipleShowLists={[["OTHER"]]}
                                        multipleDeciders={[formatDropdownValuesFunction(row.description)]}
                                        {...unmanagedProps}
                                        required
                                        onKeyUp={(e) => newLineOnEnterPressed(e)}
                                        onKeyDown={(e) => preventDefaultSubmitOnKeyDown(e)}
                                    />
                                    <SmartTextBox
                                        tooltip={this.props.btuLabelTooltip || undefined}
                                        type="int"
                                        maxLength="12"
                                        label={this.props.btuLabel}
                                        className={formatDropdownValuesFunction(row.description) == "OTHER" ? "col-xl-5 col-lg-5 col-md-5 col-sm-12 mb-3" : "col-xl-3 col-lg-3 col-md-3 col-sm-12 mb-3"}
                                        value={row.btu || ''}
                                        disabled={!row.description}
                                        onChange={(e) => handleChange(rowNum, "btu", e)}
                                        invalidFeedback="Please provide a valid entry (must be a number)"
                                        workTypeShowList={this.props.workTypeShowList}
                                        workType={workType}
                                        {...unmanagedProps}
                                        required
                                        onKeyUp={(e) => newLineOnEnterPressed(e)}
                                        onKeyDown={(e) => preventDefaultSubmitOnKeyDown(e)}
                                    />
                                    {/* shown when screen larger than sm */}
                                    <div className="col-1 mb-3 d-none d-md-block" >
                                        <label className="form-label" style={{height: '24px'}}>&nbsp;</label>
                                        <button type="button" style={{display: 'block'}} disabled={(!applianceList || applianceList.length<=1) ? true : undefined} onClick={() => deleteRow(rowNum)} className="btn-close " ></button>
                                    </div>
                                </React.Fragment>
                        ))}
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end col-sm-12 mb-3 mb-3">
                            <button className="btn btn-success" type="button" onClick={() => addRow()}>Add Appliance</button>
                        </div>
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default ApplianceForm;