import React from "react";
import $ from "jquery";
import { toast } from 'react-toastify';
import { translatedURL } from "../../util/functions";
import { Popover, OverlayTrigger } from "react-bootstrap"; 
/*managed props (all unmanaged props (such as any that are usually used by Input component such as "value", "onChange", "maxLength", etc.) will just be passed through to the input element):
        required: 
            id, 
            label
            invalidFeedback
            fetchURL //must return list of objects in which first is the id and second is the display text
            defaultText
        optional:
            workTypeShowList, 
            workType
            alwaysShow //will default to true if there is no workTypeShowList or workType
            optionFilter // a function that will be used to do data.filter(() => props.optionFilter) (for example, see the work type dropdown in CreateServiceRequest)
            tooltip
        advanced:
            multipleShowLists (type String[][]) //NOTE: make sure types match. The worktype part this parses to numbers
            multipleDeciders (type String[])

            //NOTE: to use this.props.defaultValue, you must define your onChange prop as the following:
            //onChange={(event, runServerSave=true) => this.handleInputChange(event, {name: "nameValue"}, runServerSave)}
            //otherwise, the autosave will wipe data
            defaultValue //dropdown will default to this option

    Example:
        <SmartDropDownList 
            id="requesterPhoneType"  
            label="Requester Phone Type"
            value={this.state.requesterPhoneType}
            onChange={this.handleRequesterPhoneType}
            invalidFeedback="Please provide a valid Requester Phone Type."
            workTypeShowList={[5, 6, 7, 8, 9, 10, 11, 12, 13]}
            workType={selectedConstructWorkType}
            fetchURL="/url/to/get/stuff"
            defaultText='Please select one'
        />

    Example with multiple show lists: (this will show up if this.state.property = Renter or Other, and this.state.YesOrNoSelection = Yes)
        <SmartDropDownList 
            id="ownerName"  
            label="Owner Name"
            value={this.state.ownerName}
            onChange={this.handleOwnerName}
            invalidFeedback="Please provide a valid Owner Name."
            workTypeShowList={[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]}
            workType={selectedConstructWorkType}
            multipleShowLists={[["Renter", "Other"], ["Yes"]]}
            multipleDeciders={[this.state.property, this.state.YesOrNoSelection]}
            fetchURL="/url/to/get/stuff"
            defaultText='Please select one'
        />
*/
class SmartDropDownList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [{ id: '', val: 'loading...' }],
      errorOccurred: false,
      id: this.generateId(),
    }
    
  }

  generateId() {
    const length = 15;
    const prefix = 'smartDropdown_';
    let uniqueId = prefix + Math.random().toString(36).substr(2, length);
    return uniqueId;
  }

  fetchData() {
    const showErrorToast = () =>{
      
      toast.error("Unable To Connect to our Internal Server. Please try again later.");
 
    }

    if(!this.props.fetchURL || this.props.fetchURL == "" || this.props.fetchURL == "/") {
      //invalid url. set data to nothing
      this.setState({
        data: [],
        errorOccurred: false,
      })
    }else{
      fetch(translatedURL(this.props.fetchURL))
        .then(res => {
          if(res.ok) {
            return res.json();
          }
          throw new Error(res.status);
        })
        .then(d => {
          if(this.props.optionFilter){
            d = d.filter(this.props.optionFilter);
          }          
          this.setState({
            data: d,
            errorOccurred: false,
          })
        })
        .catch( (error) => {
          showErrorToast();
          this.setState({
            data: [],
            errorOccurred: true,
          })
        })
      
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.fetchURL != this.props.fetchURL) {
      this.fetchData();
    }
  }

  componentDidMount() {
    this.fetchData();

    //NOTE: to use this.props.defaultValue, you must define your onChange prop as the following:
    //onChange={(event, runServerSave=true) => this.handleInputChange(event, {name: "nameValue"}, runServerSave)}
    //otherwise, the autosave will wipe data
    if(!this.props.value && !!this.props.defaultValue) {
      let event ={
        target: {
            value: this.props.defaultValue,
        }
      }

      const runServerSave = false; //otherwise we'll call the autosave function before other values load
      this.props.onChange(event, runServerSave); 
    }
    
  
  }

  render() {
    const checkWorkTypeShowList = () => {
      if (!this.props.workType) return false; //list given but no work type
      try {
        return this.props.workTypeShowList.includes(Number(this.props.workType));
      } catch (e) {
        return false;
      }
    }

    const checkMultipleShowLists = () => {
      if (!this.props.multipleDeciders) return false; //list given but no deciders

      let fulfillsAllParameters = true;
      for (let i = 0; i < this.props.multipleShowLists.length; i++) {
        if (!this.props.multipleShowLists[i].includes(this.props.multipleDeciders[i])) {
          fulfillsAllParameters = false;
        }
      }

      return fulfillsAllParameters;
    }

    const show = () => {
      if (this.props.alwaysShow) {
        return true;
      } else if (this.props.multipleShowLists && this.props.workTypeShowList) {
        return (checkWorkTypeShowList() && checkMultipleShowLists());
      } else if (this.props.workTypeShowList) {
        return checkWorkTypeShowList();
      } else if (this.props.multipleShowLists) {
        return checkMultipleShowLists();
      }

      return true; //if no params are passed in, always show
    }

    //separate managed props from other unmanaged props. Unmanaged props will be added directly to the input element.
    var {innerRef, formatValueFunction, subLabel, className, value, id, label, invalidFeedback, workTypeShowList, workType, alwaysShow, multipleShowLists, multipleDeciders, fetchURL, defaultText, optionFilter, tooltip, ...unmanagedProps } = this.props;

    if(!id) {
      id = this.state.id;
    }
    return (
      <React.Fragment>
        {show() &&
          <div className={`${className ? className : "col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3"}`}>
            <label
              htmlFor={id}
              className="form-label"
            >
              {this.props.label} {!!this.props.required && (<span style={{ color: 'red' }}>*</span>)}
              {
                this.props?.tooltip ?
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="auto-end"
                    overlay={
                      <Popover title="Popover right">
                        {this.props?.tooltip}
                      </Popover>
                    }
                  >
                    <span className="questionIcon">?</span>
                  </OverlayTrigger>
                  : ""
              }
              {!!this.props.subLabel && <span style={{fontSize: 'smaller'}}><br />{this.props.subLabel}</span>}
            </label>
            <select
              id={id}
              ref={innerRef || undefined}
              className="form-select shadow-sm px-4"
              aria-label={this.props.label}
              value={this.props.value ? this.props.value : this.props.defaultValue || undefined}
              defaultValue={!this.props.value ? this.props.defaultValue || '' : undefined}
              {...unmanagedProps}
            >
              <option value="" disabled={!!this.props.defaultValue ? true: undefined}>
                {this.state.errorOccurred ? "Error Occurred" : this.props.defaultText}
              </option>
              {this.state.data.map((opt, i) => (
                <option key={i} value={opt[Object.keys(opt)[0]]}> {!!this.props.formatValueFunction ? this.props.formatValueFunction(opt[Object.keys(opt)[1]]) : opt[Object.keys(opt)[1]]} </option>
              ))}
            </select>
            <div className="invalid-feedback">
              {this.props.invalidFeedback}
            </div>
          </div>
        }
      </React.Fragment>
    )
  }
}


export default SmartDropDownList;