import React from "react";
import $ from "jquery";
import { getSelectedRequest } from "../../util/functions";
import SmartLabel from "../lib/SmartLabel";
import SmartSection from "../lib/SmartSection";
import { Row } from "react-bootstrap";
import SmartLabelList from "../lib/SmartLabelList";
import { getBrand } from "../../util/functions";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import { wtm } from "../dashboard/TableComponents/SharedConstants";

class Review extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isLoading: true,
      errorOccurred: false,
      submitDisabled: true,
    };

  }

  componentDidMount() {
    $.ajax({
      url: `/api/request/getActiveSR?selected_request_id=${getSelectedRequest()}`,
      dataType: "json",
      method: "GET",
      success: (result) => {
        if(this.props.viewOnly) {
          this.props.setSelectedConstructionWorkType(result.mdugConstrWorkType);
        }
        this.setState({
          data: result,
        });
      },
      error: (err) => {
        console.log(err);
        console.log(err.responseJSON);
        this.setState({ errorOccurred: true });
      },
      complete: (res) => {
        this.setState({
          isLoading: false,
          submitDisabled: false,
        });
      },
    });
  }

  render() {
    let serviceRequest = this.state.data || true;
    let customerRequest = this.state.data.cusInfo || true;
    let projectRequest = this.state.data.proInfo || true;
    let onSiteRequest = this.state.data.onsiteInfo || true;
    let equipmentRequest = this.state.data.equipmentInfo || !["5","6","11"].includes(this.props.selectedWorkType); //don't show equipmentRequest for 5, 6, or 11
    return (
      <div className="bg-light">
        <div className="maincontainer">
          <div className="container-fluid">
            {this.state.isLoading ? <SkeletonLoader count={11} width="100px" /> :
              <Row className="justify-content-center no-gutter overflowFix">
                <div className="col-12">
                  <div className="d-flex align-items-center">
                    <div className="container py-3">
                      <h1 className="text-center text-primary">Review</h1>
                      {!this.props.viewOnly && (
                        <>
                          <p className="text-center">Please review the following information. If any entry is incorrect, please navigate back to the appropriate page and correct the error.</p>
                          <p className="text-center">Once you have finished reviewing your entered information, please click the Submit button.</p>
                        </>
                      )}
                      {serviceRequest && (
                        <Row className="customBorder mt-5">
                          <div className="col-12">
                            <Row>
                              <h3 className="text-center text-primary">Service Request Information</h3>
                              <SmartLabel label="Construction Work Type" value={serviceRequest.workTypeDescription} />
                              <SmartLabel label="Desired Date" value={serviceRequest.desiredDate} />
                              <SmartLabel label="Comments / Notes / Details of Job Requested" value={serviceRequest.description} className="col-12 mb-3" />
                            </Row>
                          </div>
                        </Row>
                      )}
                      {customerRequest && (
                        <>
                          <hr className = "thicker"/>
                          <Row className="customBorder">
                            <div className="col-12">
                              <h3 className="text-center text-primary">
                                Customer Information
                              </h3>
                              <Row className="mt-5">
                                <SmartLabel
                                  label="Requested Type of Service"
                                  value={customerRequest.serviceType}
                                />
                                {/* <SmartLabel
                                  label="Customer Type"
                                  value={customerRequest.customerType}
                                /> */}
                                <SmartSection 
                                  id="developerOrPropertyOwnerSection"
                                  title="Developer/Property Owner"
                                >
                                  <SmartLabel
                                    label="Are you the Developer and/or the Property Owner?"
                                    value={customerRequest.property}
                                  />
                                  <SmartLabel
                                    label="Phone"
                                    value={customerRequest.ownerPrimaryPhone}
                                    workTypeShowList={[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]}
                                    workType={this.props.selectedWorkType}
                                    multipleShowLists={[["NO"]]}
                                    multipleDeciders={[customerRequest.property]}
                                  />
                                  <SmartLabel
                                    label="Name"
                                    value={customerRequest.ownerName}
                                    multipleShowLists={[["NO"]]}
                                    multipleDeciders={[customerRequest.property]}
                                  />
                                  <SmartLabel
                                    label="Email"
                                    value={customerRequest.ownerEmail}
                                    multipleShowLists={[["NO"]]}
                                    multipleDeciders={[customerRequest.property]}
                                  />
                                  <SmartLabel
                                    label="Street"
                                    value={customerRequest.ownerStreet}
                                    multipleShowLists={[["NO"]]}
                                    multipleDeciders={[customerRequest.property]}
                                  />
                                  <SmartLabel
                                    label="City"
                                    value={customerRequest.ownerCity}
                                    multipleShowLists={[["NO"]]}
                                    multipleDeciders={[customerRequest.property]}
                                  />
                                  <SmartLabel
                                    label="State"
                                    value={customerRequest.ownerState}
                                    multipleShowLists={[["NO"]]}
                                    multipleDeciders={[customerRequest.property]}
                                  />
                                  <SmartLabel
                                    label="Zip Code"
                                    value={customerRequest.ownerZipCode}
                                    multipleShowLists={[["NO"]]}
                                    multipleDeciders={[customerRequest.property]}
                                  />
                                </SmartSection>
                                <SmartSection
                                  id="responsiblePartySection"
                                  title="Responsible Party"
                                >
                                  <SmartLabel
                                    label="Is there a separate person responsible for the cost of construction"
                                    value={customerRequest.responsible}
                                  />
                                  <SmartLabel
                                    label="Name"
                                    value={customerRequest.responsibleName}
                                    multipleShowLists={[["YES"]]}
                                    multipleDeciders={[customerRequest.responsible]}
                                  />
                                  <SmartLabel
                                    label="Phone Number (Cell)"
                                    value={customerRequest.responsiblePhoneNumber}
                                    multipleShowLists={[["YES"]]}
                                    multipleDeciders={[customerRequest.responsible]}
                                  />
                                  <SmartLabel
                                    label="Email"
                                    value={customerRequest.responsibleEmail}
                                    multipleShowLists={[["YES"]]}
                                    multipleDeciders={[customerRequest.responsible]}
                                  />
                                  <SmartLabel
                                    label="Company Name"
                                    value={customerRequest.responsibleCompanyName}
                                    multipleShowLists={[["YES"]]}
                                    multipleDeciders={[customerRequest.responsible]}
                                  />
                                  <SmartLabel
                                    label="Street/Billing Address"
                                    value={customerRequest.responsibleStreetAddress}
                                    multipleShowLists={[["YES"]]}
                                    multipleDeciders={[customerRequest.responsible]}
                                  />
                                  <SmartLabel
                                    label="City"
                                    value={customerRequest.responsibleCity}
                                    multipleShowLists={[["YES"]]}
                                    multipleDeciders={[customerRequest.responsible]}
                                  />
                                  <SmartLabel
                                    label="State"
                                    value={customerRequest.responsibleState}
                                    multipleShowLists={[["YES"]]}
                                    multipleDeciders={[customerRequest.responsible]}
                                  />
                                  <SmartLabel
                                    label="Zip Code"
                                    value={customerRequest.responsibleZipCode}
                                    multipleShowLists={[["YES"]]}
                                    multipleDeciders={[customerRequest.responsible]}
                                  />
                                </SmartSection>
                              </Row>
                            </div>
                          </Row>
                        </>
                      )}
                      {projectRequest && (
                        <>
                          <hr className = "thicker"/>
                          <Row className="customBorder">
                            <div className="col-12">
                              <h3 className="text-center text-primary">
                                Project Information
                              </h3>
                              <Row className="mt-5">
                                <SmartLabel
                                  label="Latitude"
                                  value={projectRequest.latitudeY}
                                />
                                <SmartLabel
                                  label="Longitude"
                                  value={projectRequest.longitudeX}
                                />
                                <SmartLabel
                                  label="Service/911 Street Address"
                                  value={projectRequest.tkserviceaddress_streetAddress}
                                />
                                <SmartLabel
                                  label="Service State"
                                  value={projectRequest.tkserviceaddress_stateProvince}
                                />
                                <SmartLabel
                                  label="Service City"
                                  value={projectRequest.tkserviceaddress_city}
                                />
                                <SmartLabel
                                  label="Service Zip Code"
                                  value={projectRequest.tkserviceaddress_postalCode}
                                />
                                <SmartLabel
                                  label="County"
                                  value={projectRequest.tkserviceaddress_county}
                                  workTypeShowList={[1, 2, 3, 4, 14]}
                                  workType={this.props.selectedWorkType}
                                />
                                <SmartLabel
                                  label="Nearest Cross Street"
                                  value={projectRequest.tkserviceaddress_referencePoint}
                                  workTypeShowList={[1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14]}
                                  workType={this.props.selectedWorkType}
                                />
                                <SmartLabel
                                  label="Development/Subdivision Name"
                                  value={projectRequest.developmentName}
                                  workTypeShowList={[
                                    wtm.GAS_SERVICE_INSTALL,
                                    wtm.GAS_INSTALL_NEW_SUBDIVISION,
                                    wtm.ELECTRIC_SERVICE_INSTALL,
                                    wtm.ELECTRIC_DEVELOPER_SUBDIVISION,
                                    wtm.COMBO_SUBDIVISION,
                                    wtm.COMBO_SERVICE_INSTALL,
                                  ]}
                                  workType={this.props.selectedWorkType}
                                  multipleShowLists={[["NEWCOMMERCIAL", "NEWRESIDENTIAL"]]}
                                  multipleDeciders={[customerRequest.serviceType || '']}
                                />
                                <SmartLabel
                                  label="Development/Subdivision Phase"
                                  value={projectRequest.developmentPhase}
                                  workTypeShowList={[
                                    wtm.GAS_INSTALL_NEW_SUBDIVISION,
                                    wtm.ELECTRIC_DEVELOPER_SUBDIVISION,
                                    wtm.COMBO_SUBDIVISION
                                  ]}
                                  workType={this.props.selectedWorkType}
                                  multipleShowLists={[["NEWCOMMERCIAL", "NEWRESIDENTIAL"]]}
                                  multipleDeciders={[customerRequest.serviceType || '']}
                                />
                                <SmartLabel
                                  label="Type of Development"
                                  value={projectRequest.developmentType}
                                  workTypeShowList={[
                                    wtm.GAS_INSTALL_NEW_SUBDIVISION,
                                    wtm.ELECTRIC_SERVICE_INSTALL,
                                    wtm.ELECTRIC_LIGHT_INSTALL,
                                    wtm.ELECTRIC_DEVELOPER_SUBDIVISION,
                                    wtm.COMBO_SUBDIVISION
                                  ]}
                                  workType={this.props.selectedWorkType}
                                  multipleShowLists={[["NEWCOMMERCIAL", "NEWRESIDENTIAL"]]}
                                  multipleDeciders={[customerRequest.serviceType || '']}
                                />
                                <SmartLabel
                                  label="Number of Units"
                                  value={projectRequest.developmentNumUnits}
                                  workTypeShowList={[2, 3, 4, 6, 7, 12, 13, 14]}
                                  workType={this.props.selectedWorkType}
                                  multipleShowLists={[["MIXEDUSE", "MULTIFAMILY"]]}
                                  multipleDeciders={[projectRequest.developmentType]}
                                />
                                <SmartLabel
                                  label="Is service request in a new development/subdivision?"
                                  value={projectRequest.inNewDevelopment}
                                  multipleShowLists={[["NEWCOMMERCIAL", "NEWRESIDENTIAL"]]}
                                  multipleDeciders={[customerRequest.serviceType]}
                                />
                                <SmartLabel
                                  label="Lot"
                                  value={projectRequest.tkserviceaddress_mdugLot}
                                  workTypeShowList={[1, 2, 3, 4, 5, 7, 8, 9, 11, 14]}
                                  workType={this.props.selectedWorkType}
                                  multipleShowLists={[["NEWCOMMERCIAL", "NEWRESIDENTIAL", "INDUSTRIAL"]]}
                                  multipleDeciders={[customerRequest.serviceType]}
                                />
                                <SmartLabel
                                  label="Block"
                                  value={projectRequest.tkserviceaddress_mdugBlock}
                                  workTypeShowList={[1, 2, 3, 4, 5, 7, 8, 9, 11, 14]}
                                  workType={this.props.selectedWorkType}
                                  multipleShowLists={[["NEWCOMMERCIAL", "NEWRESIDENTIAL", "INDUSTRIAL"]]}
                                  multipleDeciders={[customerRequest.serviceType]}
                                />
                                <SmartLabel
                                  label="Inside City Limits"
                                  value={projectRequest.insideCityLimits}
                                />
                                 <SmartLabel
                                  label={"Customer understands the meter location and snow load protection requirements"}
                                  value={projectRequest.meterLocConfirm}
                                  workTypeShowList={[2, 3, 4, 7, 9, 14]}
                                  workType={this.props.selectedWorkType}
                                  multipleShowLists={[["YES"]]} //THIS FIELD IS DISPLAYED DOWN BY THE METER LOCATION FIELDs WHEN proInfo.snowLoad is NO.
                                  multipleDeciders={[projectRequest?.snowLoad]}
                                />
                                <SmartLabel
                                  label="Snow Load Meter Protection"
                                  value={projectRequest.snowLoadMeterProtection}
                                  workTypeShowList={[2, 3, 4, 7, 14]}
                                  workType={this.props.selectedWorkType}
                                  multipleShowLists={[["YES"]]}
                                  multipleDeciders={[projectRequest.snowLoad]}
                                />
                                <SmartLabel
                                  label="Is Temporary/Construction Heat required?"
                                  value={projectRequest.tempServiceRequired}
                                  workTypeShowList={[2, 4, 14]}
                                  workType={this.props.selectedWorkType}
                                  multipleShowLists={[["NEWCOMMERCIAL", "NEWRESIDENTIAL", "INDUSTRIAL"]]}
                                  multipleDeciders={[customerRequest.serviceType || '']}
                                />
                                <SmartLabel
                                  label="Temporary Service BTU"
                                  value={projectRequest.tempServiceBTU}
                                  workTypeShowList={[2, 4, 14]}
                                  workType={this.props.selectedWorkType}
                                  multipleShowLists={[["YES"], ["NEWCOMMERCIAL", "NEWRESIDENTIAL", "INDUSTRIAL"]]}
                                  multipleDeciders={[projectRequest.tempServiceRequired, customerRequest.serviceType || '']}
                                />
                                <SmartLabel
                                  label="Size of Temporary Construction"
                                  value={projectRequest.tempServiceSize}
                                  workTypeShowList={[2, 4, 14]}
                                  workType={this.props.selectedWorkType}
                                  multipleShowLists={[["YES"], ["NEWCOMMERCIAL", "NEWRESIDENTIAL", "INDUSTRIAL"]]}
                                  multipleDeciders={[projectRequest.tempServiceRequired, customerRequest.serviceType || '']}
                                />
                                <SmartLabel
                                  label="Year Home Built"
                                  value={projectRequest.homeYearBuilt}
                                  workTypeShowList={[1, 2, 3, 4, 7, 14]}
                                  workType={this.props.selectedWorkType}
                                  multipleShowLists={[["cng", "gpg"]]}
                                  multipleDeciders={[getBrand()]}
                                />
                                <SmartLabel
                                  label="Home Sq Footage (Heated)"
                                  value={projectRequest.homeSqFt}
                                  workTypeShowList={[1, 2, 3, 4, 7, 14]}
                                  workType={this.props.selectedWorkType}
                                />
                                <SmartLabel
                                  label="Additional Sq Footage"
                                  value={projectRequest.homeSqFtAdditional}
                                  workTypeShowList={[2, 3, 4, 7, 14]}
                                  workType={this.props.selectedWorkType}
                                />
                                <SmartLabel
                                  label="Foundation Type"
                                  value={projectRequest.foundationType}
                                  workTypeShowList={[2, 4, 14]}
                                  workType={this.props.selectedWorkType}
                                  multipleShowLists={[["NEWRESIDENTIAL", "EXISTINGRESIDENTIAL"]]}
                                  multipleDeciders={[customerRequest.serviceType || '']}
                                />
                                <SmartLabelList 
                                  label="Plat Map/Site Drawing Attachments"
                                  value={!!serviceRequest.attachment && !!serviceRequest.attachment.find(a => a.description == "mapAttachment") ? serviceRequest.attachment.filter(a => a.description == "mapAttachment").map(a => a.fileName): null}
                                />
                                <SmartLabel
                                  label="Estimated service line length(s) (in feet)"
                                  value={projectRequest.serviceLength}
                                  workTypeShowList={[1, 2, 3, 4, 6, 7, 9, 12, 13, 14]}
                                  workType={this.props.selectedWorkType}
                                />
                                <SmartLabel
                                  label="Will the trench/conduit be customer provided"
                                  value={projectRequest.customerTrench}
                                  workTypeShowList={[2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14]}
                                  workType={this.props.selectedWorkType}
                                />
                                <SmartLabel
                                  label="Builder understands bedding/backfill requirements"
                                  value={projectRequest.customerTrenchConfirm}
                                  workTypeShowList={[2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14]}
                                  workType={this.props.selectedWorkType}
                                  multipleShowLists={[["YES"]]}
                                  multipleDeciders={[projectRequest.customerTrench]}
                                />
                                <SmartLabel
                                  label="Landscape (access) Considerations – Clear vehicle access available"
                                  value={projectRequest.vehicleAccess}
                                  workTypeShowList={[2, 3, 4, 5, 7, 8, 9, 11, 14]}
                                  workType={this.props.selectedWorkType}
                                />
                                <SmartLabel
                                  label="North Dakota customer application for service has been reviewed"
                                  value={projectRequest.vehicleAccess}
                                  workTypeShowList={[2, 14]}
                                  workType={this.props.selectedWorkType}
                                  multipleShowLists={[["ND"]]}
                                  multipleDeciders={[projectRequest.tkserviceaddress_stateProvince]}
                                />
                                <SmartLabel
                                  label="Desired Meter Location"
                                  value={projectRequest.meterLoc}
                                  workTypeShowList={[2, 3, 4, 7, 9, 14]}
                                  workType={this.props.selectedWorkType}
                                />
                                <SmartLabel
                                  label="Is Desired Meter Location Marked"
                                  value={projectRequest.meterLocMarked}
                                  workTypeShowList={[2, 3, 4, 7, 9, 14]}
                                  workType={this.props.selectedWorkType}
                                />
                                <SmartLabel
                                  label="The customer understands the meter location requirements"
                                  value={projectRequest.meterLocConfirm}
                                  workTypeShowList={[2, 3, 4, 7, 9, 14]}
                                  workType={this.props.selectedWorkType}
                                  multipleShowLists={[["NO"]]} //THIS FIELD IS DISPLAYED UP BY THE SNOW LOAD fields WHEN proInfo.snowLoad is YES.
                                  multipleDeciders={[projectRequest?.snowLoad]}
                                />
                                <SmartLabel
                                  label="Work Location Comments"
                                  value={projectRequest.meterLocComments}
                                  workTypeShowList={[1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14]}
                                  workType={this.props.selectedWorkType}
                                />
                                <SmartSection
                                  id="multiPhaseDevelopmentSection"
                                  title="Multi-Phase Development"
                                  workTypeShowList={[6, 12, 13]}
                                  workType={this.props.selectedWorkType}
                                >
                                  <SmartLabel
                                    label="Is this a multi-phase development"
                                    value={projectRequest.dvlpmtMultiPhase}
                                    workType={this.props.selectedWorkType}
                                  />
                                  <SmartLabel
                                    label="Which phase is this request for?"
                                    value={projectRequest.dvlpmtCurrentPhase}
                                    multipleShowLists={[["YES"]]}
                                    multipleDeciders={[projectRequest.dvlpmtMultiPhase]}
                                  />
                                  <SmartLabel
                                    label="Number of phases in development"
                                    value={projectRequest.dvlpmtNumPhases}
                                    multipleShowLists={[["YES"]]}
                                    multipleDeciders={[projectRequest.dvlpmtMultiPhase]}
                                  />
                                  <SmartLabel
                                    label="Total # of lots in requested phase"
                                    value={projectRequest.dvlpmtNumLots}
                                    multipleShowLists={[["YES"]]}
                                    multipleDeciders={[projectRequest.dvlpmtMultiPhase]}
                                  />
                                </SmartSection>
                                <SmartLabel
                                  label="Stage of Construction"
                                  value={projectRequest.dvlpmtStage}
                                  workTypeShowList={[2, 7, 14]}
                                  workType={this.props.selectedWorkType}
                                  multipleShowLists={[["NEWCOMMERCIAL", "NEWRESIDENTIAL"]]}
                                  multipleDeciders={[customerRequest.serviceType || '']}
                                />
                                <SmartLabel
                                  label="Total number of lots in all phases"
                                  value={projectRequest.numLots}
                                  workTypeShowList={[3, 6, 12, 13]}
                                  workType={this.props.selectedWorkType}
                                />
                                <SmartLabel
                                  label="Plat Status"
                                  value={projectRequest.dvlpmtPlatStatus}
                                  workTypeShowList={[6, 7, 8, 12, 13, 14]}
                                  workType={this.props.selectedWorkType}
                                />
                                <SmartLabel
                                  label="Estimated number of service points/meters (current phase)"
                                  value={projectRequest.dvlpmtNumSp}
                                  workTypeShowList={[6, 12, 13]}
                                  workType={this.props.selectedWorkType}
                                />
                                <SmartLabel
                                  label="The average square footage of buildings in development"
                                  value={projectRequest.dvlpmtAvgSqrFt}
                                  workTypeShowList={[6, 13]}
                                  workType={this.props.selectedWorkType}
                                />
                                <SmartLabel
                                  label="Is a snowmelt system being installed?"
                                  value={projectRequest.isInstallingSnowmeltSystem}
                                  workTypeShowList={[2, 3, 4, 6, 7, 12, 13, 14]}
                                  workType={this.props.selectedWorkType}
                                />
                                <SmartLabel
                                  label="User has read and agrees to the Snow Melt requirements"
                                  value={projectRequest.igcSnowMeltAcknowledgement}
                                  multipleShowLists={[["igc"], ["YES"]]}
                                  multipleDeciders={[getBrand(), projectRequest.isInstallingSnowmeltSystem]}
                                />
                              </Row>
                            </div>
                          </Row>
                        </>
                      )}
                      {serviceRequest && serviceRequest.mdugAppliance && (
                        <>
                          <hr className = "thicker"/>
                          <Row className="customBorder">
                            <div className="col-12">
                              <h3 className="text-center text-primary">
                                Gas Appliances
                              </h3>
                              <Row className="mt-5">
                                {serviceRequest.mdugAppliance.map((app, i) => {
                                  const formatDropdownValuesFunction = (value) => {
                                    //APPLIANCE CODES - DRYER
                                    //only display what's after the first dash
                                    return value.substring(value.indexOf('-') + 1).trim();            
                                  }

                                  return (
                                  <React.Fragment key={i}>
                                    <h5>Appliance {i+1}</h5>
                                    <SmartLabel
                                      className={!app.other ? "col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-3" : undefined}
                                      key={i+'1'}
                                      label={`Type`}
                                      value={app.name}
                                      formatValueFunction = {formatDropdownValuesFunction}
                                    />
                                    <SmartLabel
                                      className={!app.other ? "col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-3" : undefined}
                                      key={i+'2'}
                                      label={`Description`}
                                      value={app.description}
                                      formatValueFunction = {formatDropdownValuesFunction}
                                    />
                                    {!!app.other && 
                                      <SmartLabel
                                        key={i+'4'}
                                        label={`Other`}
                                        value={app.other}
                                      />
                                    }
                                    <SmartLabel
                                      className={!app.other ? "col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-3" : undefined}
                                      key={i+'3'}
                                      label={[6,12,13].includes(Number(this.props.selectedWorkType)) ? "Quantity per Premise" : "BTU"}
                                      value={app.btu}
                                    />
                                    {i != serviceRequest.mdugAppliance.length-1 && <hr />}
                                  </React.Fragment>
                                )})}
                                
                              </Row>
                            </div>
                          </Row>
                        </>
                      )}
                      {onSiteRequest && (
                        <>
                          <hr className = "thicker"/>
                          <Row className="customBorder">
                            <div className="col-12">
                              <h3 className="text-center text-primary">
                                OnSite Information
                              </h3>
                              <Row className="mt-5">
                                <SmartSection
                                  id="onsiteContactSection"
                                  title="Onsite Contact"
                                >
                                  <SmartLabel
                                    label="Is there a separate onsite contact"
                                    value={onSiteRequest.onSiteContact}
                                  />
                                  <SmartLabel
                                    label="Name"
                                    value={onSiteRequest.onSiteName}
                                    multipleShowLists={[["YES"]]}
                                    multipleDeciders={[onSiteRequest.onSiteContact]}
                                  />
                                  <SmartLabel
                                    label="Company"
                                    value={onSiteRequest.onSiteCompany}
                                    multipleShowLists={[["YES"]]}
                                    multipleDeciders={[onSiteRequest.onSiteContact]}
                                  />
                                  <SmartLabel
                                    label="Phone"
                                    value={onSiteRequest.onSitePhone}
                                    multipleShowLists={[["YES"]]}
                                    multipleDeciders={[onSiteRequest.onSiteContact]}
                                  />
                                  <SmartLabel
                                    label="Email"
                                    value={onSiteRequest.onSiteEmail}
                                    multipleShowLists={[["YES"]]}
                                    multipleDeciders={[onSiteRequest.onSiteContact]}
                                  />
                                </SmartSection>
                                <SmartSection 
                                  id="hvacInstallerSection"
                                  title="Gas Equipment Installation Contractor"
                                  workTypeShowList={[1, 2, 3, 4, 14]}
                                  workType={this.props.selectedWorkType}
                                >
                                  <SmartLabel
                                    label="Have you selected an Equipment (HVAC) contractor?"
                                    value={onSiteRequest.hvacDealer}
                                  />
                                  <SmartLabel
                                    label="Name"
                                    value={onSiteRequest.hvacName}
                                    multipleShowLists={[["YES"]]}
                                    multipleDeciders={[onSiteRequest.hvacDealer]}
                                  />
                                  <SmartLabel
                                    label="Phone Number"
                                    value={onSiteRequest.hvacPhone}
                                    multipleShowLists={[["YES"]]}
                                    multipleDeciders={[onSiteRequest.hvacDealer]}
                                  />
                                  <SmartLabel
                                    label="Company"
                                    value={onSiteRequest.hvacCompany}
                                    multipleShowLists={[["YES"]]}
                                    multipleDeciders={[onSiteRequest.hvacDealer]}
                                  />
                                </SmartSection>
                                <SmartSection
                                  id="electricianSection"
                                  title="Electrician"
                                  workTypeShowList={[7, 8, 9, 14]}
                                  workType={this.props.selectedWorkType}
                                >
                                  <SmartLabel
                                    label="Electrician"
                                    value={onSiteRequest.electrician}
                                    workType={this.props.selectedWorkType}
                                  />
                                  <SmartLabel
                                    label="Name"
                                    value={onSiteRequest.electricianName}
                                    multipleShowLists={[["YES"]]}
                                    multipleDeciders={[onSiteRequest.electrician]}
                                  />
                                  <SmartLabel
                                    label="Phone"
                                    value={onSiteRequest.electricianPhone}
                                    multipleShowLists={[["YES"]]}
                                    multipleDeciders={[onSiteRequest.electrician]}
                                  />
                                  <SmartLabel
                                    label="Company"
                                    value={onSiteRequest.electricianCompany}
                                    multipleShowLists={[["YES"]]}
                                    multipleDeciders={[onSiteRequest.electrician]}
                                  />
                                </SmartSection>
                              </Row>
                            </div>
                          </Row>
                        </>
                      )}
                      {equipmentRequest && (
                        <>
                          <hr className = "thicker"/>
                          <Row className="customBorder">
                            <div className="col-12">
                              <h3 className="text-center text-primary">
                                Equipment Information
                              </h3>
                              <Row className="mt-5">
                                <SmartLabel
                                  label="Requested Delivery Pressure"
                                  value={equipmentRequest.meterPressure}
                                  workTypeShowList={[2, 3, 4, 14]}
                                  workType={this.props.selectedWorkType}
                                />
                                <SmartLabel
                                  label="Is Gas the Primary Source of Heat"
                                  value={equipmentRequest.gasOnlySource}
                                  workTypeShowList={[2, 3, 4, 14]}
                                  workType={this.props.selectedWorkType}
                                />
                                <SmartLabel
                                  label="Other Source"
                                  value={equipmentRequest.otherHeatSource}
                                  multipleShowLists={[["NO"]]}
                                  multipleDeciders={[equipmentRequest.gasOnlySource]}
                                />
                                <SmartLabel
                                  label="Converting from fuel type"
                                  value={equipmentRequest.fromFuelType}
                                  workTypeShowList={[2, 14]}
                                  workType={this.props.selectedWorkType}
                                  multipleShowLists={[["EXISTINGCOMMERCIAL", "EXISTINGRESIDENTIAL"]]}
                                  multipleDeciders={[customerRequest.serviceType || '']}
                                />
                                <SmartLabel
                                  label="Other Fuel Type"
                                  value={equipmentRequest.otherFromFuelType}
                                  multipleShowLists={[["OTHER"]]} //other fuel type
                                  multipleDeciders={[equipmentRequest.fromFuelType]}
                                />
                                <SmartLabel
                                  label="Standby/Backup Generation"
                                  value={equipmentRequest.standByGen}
                                  workTypeShowList={[12, 13]}
                                  workType={this.props.selectedWorkType}
                                />
                                <SmartLabel
                                  label="Wiring Certificate associated with request"
                                  value={equipmentRequest.wiringCertId}
                                  workTypeShowList={[7, 9, 11, 14]}
                                  workType={this.props.selectedWorkType}
                                />
                                <SmartLabel
                                  label="Main Electric Rating"
                                  value={equipmentRequest.mainRating}
                                  workTypeShowList={[12, 13]}
                                  workType={this.props.selectedWorkType}
                                />
                                <SmartLabel
                                  label="Voltage/Phases"
                                  value={equipmentRequest.voltagePhase}
                                  workTypeShowList={[12, 13]}
                                  workType={this.props.selectedWorkType}
                                />
                                <SmartLabel
                                  label="Distributed Generation"
                                  value={equipmentRequest.distributedGen}
                                  workTypeShowList={[8, 9, 12, 13]}
                                  workType={this.props.selectedWorkType}
                                />
                                <SmartLabel
                                  label="Street lighting required"
                                  value={equipmentRequest.lightingRequired}
                                  workTypeShowList={[12, 13]}
                                  workType={this.props.selectedWorkType}
                                />
                                <SmartLabel
                                  label="Type of lighting"
                                  value={equipmentRequest.lightingType}
                                  multipleShowLists={[["YES"]]}
                                  multipleDeciders={[equipmentRequest.lightingRequired]}
                                />
                                <SmartLabel
                                  label="Estimated quantity of lights"
                                  value={equipmentRequest.numStreetLights}
                                  multipleShowLists={[["YES"]]}
                                  multipleDeciders={[equipmentRequest.lightingRequired]}
                                />
                                <SmartLabelList 
                                  label="Street Light Plan Attachments"
                                  value={!!serviceRequest.attachment && !!serviceRequest.attachment.find(a => a.description == "Street_Light_Plan") ? serviceRequest.attachment.filter(a => a.description == "Street_Light_Plan").map(a => a.fileName): null}
                                  workTypeShowList={[12, 13]}
                                  workType={this.props.selectedWorkType}
                                  multipleShowLists={[["YES"]]}
                                  multipleDeciders={[equipmentRequest.lightingRequired]}
                                />
                              </Row>
                            </div>
                          </Row>
                        </>
                      )}
                      
                      <div className="col-12">
                        <hr className = "thicker"/>
                        <div className="text-center">
                          {!!this.props.viewOnly && (
                            <button
                              type="button"
                              onClick={() => this.props.closeModal()}
                              className="btn btn-secondary btn-block text-uppercase mb-2 shadow-sm btn-sm me-2"
                            >
                              Close
                            </button>
                          )}
                          {!this.props.viewOnly && (
                            <>
                              <button
                                type="button"
                                onClick={() => this.props.prevPage()}
                                className="btn btn-secondary btn-block text-uppercase mb-2 shadow-sm btn-sm me-2"
                              >
                                Previous
                              </button>
                              <button
                                type="submit"
                                disabled={this.state.submitDisabled}
                                className="btn btn-success btn-block text-uppercase mb-2 shadow-sm btn-sm"
                                onClick={() => {
                                  if(this.state.submitDisabled){
                                    return;
                                  }
                                  this.setState({
                                    submitDisabled: true,
                                  })
                                  this.props.submitToMaximo();
                                }}
                              >
                                Submit
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            }
          </div>
        </div>
      </div>
    );
  }
}


export default Review;