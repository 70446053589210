import { Modal } from "react-bootstrap";
import React from "react";
import EditUserAndChangePassword from "./EditUserAndChangePassword";
const ChangePasswordModal = ({ setDisplayingModal, show }) => {
  return (
    <Modal
      size="lg"
      show={show}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <div>
          <h4>Welcome to the Construction Self Service Portal!</h4>
          <h5>Please update your password:</h5>
        </div>
      </Modal.Header>
      <Modal.Body>
        <EditUserAndChangePassword 
          showModal={() => setDisplayingModal()}
        />
      </Modal.Body>
    </Modal>
  )
}

export default ChangePasswordModal