import React from "react";
import $ from "jquery";
import SmartDropDownList from "../lib/SmartDropDownList";
import SmartTextAreaBox from "../lib/SmartTextAreaBox";
import endpoint from "../../util/endpoint";
import { getSelectedRequest} from "../../util/functions";
import { toast } from "react-toastify";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import { Row, Badge } from "react-bootstrap";
import { isHolidayAndOrWeekend } from "../../util/functions";
import SmartDatePicker from "../lib/SmartDatePicker";
import { wtm } from "../dashboard/TableComponents/SharedConstants";
import _ from 'lodash'

class CreateServiceRequest extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      isLoading: true,
    };
  }

  handleInputChange = (event, options = {name: null, value: null }, runServerSave = true) => {
    let name = options.name == null ? event.target.name : options.name;
    let value = options.value == null ? event.target.value : options.value;

    if(runServerSave){
      this.props.updateServiceRequestObject(name, value);
      this.props.saveSR(); //everything in this component should pass in "runServerSave" as false.
    }else{
      this.props.updateServiceRequestObject(name, value);
    }
  }

  handleConstructionWorkTypeChange = (event) => {
    const workType = event.target.value;
    this.props.setSelectedConstructionWorkType(workType);
    this.props.updateServiceRequestObject('mdugConstrWorkType', workType);
    if (!_.get(this.props.srObj, 'desiredDate') || (_.get(this.props.srObj, 'desiredDate') < this.getMinDate(workType))) {
      this.props.updateServiceRequestObject("desiredDate", this.getMinDate(workType));
    }
    // 'Requested type of service' is hidden for subdivision work types so default the value to NEWRESIDENTIAL
    if(Number(workType) === wtm.GAS_INSTALL_NEW_SUBDIVISION || Number(workType) === wtm.COMBO_SUBDIVISION) {
      this.props.updateServiceRequestObject("cusInfo.serviceType", "NEWRESIDENTIAL");
    }
  };

  getMinWeeksInFuture = (workType) => {
    let weeks = 6;
    if (["6", "12", "13"].includes(workType)) {
      //subdivision types get 10 weeks. others get 6 weeks
      weeks = 10;
    }
    return weeks;
  }

  getMinDate = (workType) => {
    if (!workType) workType = "10000"; //setting to something that just isn't in ["6","12","13"] so defaults to min 6 weeks
    let d;
    if (_.get(this.props.srObj, 'createDate')) { //starting date should be date SR was created in CSSP
      d = new Date(_.get(this.props.srObj, 'createDate'));
    } else {
      d = new Date();
    }
    d.setDate(d.getDate() + this.getMinWeeksInFuture(workType) * 7);
    
    while(this.isHoliday(d)){
      ///if date is a holiday, go forward one day
      d = new Date(d.getTime() + (24*60*60*1000));
    }
    return d;
  }

  componentDidMount() {
    this.setState({ isLoading: true })
    $.ajax({
      url: `/api/request/getActiveSR?selected_request_id=${getSelectedRequest()}`,
      dataType: "json",
      method: "GET",
      success: (result) => {
        this.props.replaceServiceRequestObject(result);

        if (result.proinfo && result.proinfo.latitudeY) {
          this.props.setOldLatitudeAndLongitude(result.proInfo.latitudeY, result.proInfo.longitudeX)
        }

        if (!_.get(result, 'desiredDate')) {
          this.props.updateServiceRequestObject("desiredDate", this.getMinDate(this.props.selectedWorkType));
        }

        this.props.setSelectedConstructionWorkType(result.mdugConstrWorkType);
      },
      error: () => {
        
      }
    }).always = () => {
      this.setState({ isLoading: false })
    };
  }

  isHoliday = (date) => {
    date = new Date(date)
    return isHolidayAndOrWeekend(date);
  }

  render() {
    const nextOptions = (event) => {
      event.preventDefault();

      if (!this.formRef.current.checkValidity()) {
        this.formRef.current.classList.add('was-validated');
        return false
      }

      let desiredDate = new Date(getValue('desiredDate')).toLocaleDateString("en-US"); //creates date string with format MM/DD/YYYY
      this.props.updateServiceRequestObject('desiredDate', desiredDate); 

      let checkDate = new Date(desiredDate + " 00:00:00")
      if (!desiredDate || this.isHoliday(checkDate)) {
        return toast.error("Please select a desired date that is is not a weekend or a holiday.");
      }

      this.props.saveSR_Now(this.props.nextPage);
    };

    const getValue = (path) => {
      return _.get(this.props.srObj, path)
    }

    return (
      <form ref={this.formRef} onSubmit={nextOptions} className="customBorder mt-3 needs-validation" style={{ minHeight: 470 }} noValidate>
        <div className="col-12">
          <h3 className="text-center text-primary mt-3">
            Service Request Information
          </h3>
          {
            this.state.isLoading ? <SkeletonLoader count={11} width='100px' /> :
              <>
                <Row>
                  <div className="col-xl-3 col-lg-3 col-md-3 mb-3"></div>
                  <SmartDropDownList
                    id="constructionWorkTypeDDL"
                    className="col-md-8 offset-md-2 col-sm-12 mb-3"
                    label="Construction Work Type"
                    value={this.props.selectedWorkType}
                    workType={this.props.selectedWorkType}
                    onChange={this.handleConstructionWorkTypeChange}
                    invalidFeedback="Please provide a Construction Work Type."
                    fetchURL={endpoint.workTypes}
                    optionFilter={(opt) => { 
                      //this filter will remove Electric work types when necessary.
                      //this will eventually be removed once it's time to go live with Electric Work Types
                      //electric work type will only show up if it's already selected (usually as the result of a Combo splitting into a field that otherwise can't be chosen)
                      return !opt[Object.keys(opt)[1]].startsWith("Electric ") || opt[Object.keys(opt)[0]] == this.props.selectedWorkType
                    }}
                    defaultText='Select Construction Work Type'
                    required
                  />
                  <div className="col-xl-3 col-lg-3 col-md-3 mb-3"></div>
                </Row>
                {!!this.props.selectedWorkType && (
                  <Row>
                    <SmartDatePicker
                      type="date"
                      id="desiredDate"
                      label={`Desired Date (earliest ${this.getMinWeeksInFuture(this.props.selectedWorkType)} weeks from now)`}
                      // firefox has a bug where datepicker won't default month to contain valid date. So this code will auto set value to min possible date in firefox
                      value={getValue('desiredDate')}
                      className="col-md-8 offset-md-2 col-sm-12 mb-3"
                      onChange={(event, runServerSave=false) => this.handleInputChange(event, {name: "desiredDate"}, runServerSave)}
                      invalidFeedback="Please provide a valid Desired Date."
                      required
                      minDate={this.getMinDate(this.props.selectedWorkType)}
                      maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 50))} //50 years from now
                      filterDate={(date) => !this.isHoliday(date)}
                    />
                    <SmartTextAreaBox
                      id="commentsNotes"
                      label="Comments / Notes / Details of Job Requested"
                      maxLength="32000"
                      className="col-md-8 offset-md-2 col-sm-12 mb-3"
                      value={getValue('description')}
                      onChange={(event, runServerSave=false) => this.handleInputChange(event, {name: "description"}, runServerSave)}
                      placeholder="Leave a comment here"
                      invalidFeedback="Please leave a comment here"
                    />
                    <Row>
                      <div className="col-12">
                        <div className="text-center">
                          <button
                            disabled={this.props.updateInProgress}
                            type="button"
                            onClick={() => this.props.prevPage()}
                            className="btn btn-secondary btn-block text-uppercase mb-2 shadow-sm btn-sm me-2"
                          >
                            Previous
                          </button>
                          <button
                            disabled={this.props.updateInProgress}
                            type="submit"
                            className="btn btn-primary btn-block text-uppercase mb-2 shadow-sm btn-sm"
                          >
                            Next
                          </button>
                        </div>
                        {this.props.updateInProgress && <div className="text-center"><Badge bg="primary" >Saving in progress... </Badge></div>}
                      </div>
                    </Row>
                  </Row>
                )}
              </>
            }
        </div>
      </form>
    );
  }
}

export default CreateServiceRequest;