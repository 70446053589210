import React from "react";
import SmartTextBox from "../lib/SmartTextBox";
import $ from "jquery";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import { translatedURL } from "../../util/functions";

class EditUser extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      type: "editUserPage",
      userData: null,
      redirect: false,
      authError: false,
      isLoading: true,
    };
  }

  componentDidMount() {
    $.ajax({
      url: "/api/user/profileAndUser",
      dataType: "json",
      method: "GET",
      success: (result) => {
        this.setState({
          userData: result.user,
          isLoading: false,
        });
      },
    });
  }

  handleInputChange = (event) => {
    this.setState(prevState => ({
      userData: {
        ...prevState.userData,
        [event.target.name]: event.target.value
      }
    }));
  }
  
  ConditionalWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children;

  render() {
    const handleSubmit = (event) => {
      event.preventDefault();

      if (!this.formRef.current.checkValidity()) {
        this.formRef.current.classList.add('was-validated');
        return false
      }
      
      $.ajax({
        url: `/api/user/user`,
        dataType: "html",
        method: "POST",
        data: JSON.stringify(this.state.userData),
        contentType: "application/json; charset=utf-8",
        success: (result) => {
          if (this.props.displayedInModal) {
            this.props.showModal(false);
          } else {
            window.location.href = translatedURL("/profile");
          }
        },
        error: (error) => {
          this.setState({ authError: true });
        },
      });
    };

    return (
      <React.Fragment>
         <this.ConditionalWrapper
            condition={!this.props.displayedInModal}
            wrapper={children => 
              // add additional formatting when displayed in own page (as opposed to inside a modal)
              <div className="bg-light">
                <div className="maincontainer">
                  <div className="container-fluid">
                    <div className="row justify-content-center no-gutter">
                      <div className="col-12">
                        <div className="d-flex align-items-center">
                          <div className="container pt-4 mb-4">
                            <div className="row customBorder mb-4">
                              {children}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          >
          <div className="col-12">
            <h3 className="text-center text-primary">
              {this.props.displayedInModal ? "" : "Edit User"}
            </h3>
            {
              this.state.isLoading ? <SkeletonLoader count={3} height={30} /> 
              :
            <form ref={this.formRef} onSubmit={handleSubmit} className="row mt-4 needs-validation" noValidate>
              <SmartTextBox
                id="name"
                label="Name"
                placeholder="Name"
                value={this.state.userData.name}
                onChange={this.handleInputChange}
                invalidFeedback="Please provide a valid Name."
              />
              <SmartTextBox
                id="email"
                label="Email"
                maxLength="100"
                type="email"
                placeholder="Email"
                value={this.state.userData.email}
                onChange={this.handleInputChange}
                invalidFeedback="Please provide a valid Email."
                disabled
              />
               <SmartTextBox
                id="phone"
                label="Phone"
                type="phone"
                placeholder="Phone"
                value={this.state.userData.phone}
                onChange={this.handleInputChange}
                invalidFeedback="Please provide a valid Phone."
              />
              <div className="col-12 text-center">
                {!this.props.displayedInModal && <a href={translatedURL("/profile")} className="mx-3 btn btn-primary btn-block text-uppercase mb-2 shadow-sm btn-sm float-start">Cancel</a>}
                <button
                  type="submit"
                  className="btn btn-primary btn-block text-uppercase mb-2 shadow-sm btn-sm float-end"
                >
                  Update
                </button>
              </div>
            </form>
            }
          </div>
        </this.ConditionalWrapper>
      </React.Fragment>
    );
  }
}

export default EditUser;