import React, {useMemo} from "react";
import { logos } from "../../assets/images/images";
import { getBrand, getBrandForHeader, translatedURL } from "../../util/functions";
import { Navbar } from "react-bootstrap";

export default function LoginHeader () {
  const theme = getBrand();
  const brandShortName = getBrand();
  const headerBrandText = getBrandForHeader();
  const logoPath = logos[getBrand()];

  return (
    <div  className={`${brandShortName} ${theme}`}>
      <nav className="navbar navbar-expand-lg navbar-dark bg-brand">
        <Navbar.Brand href={translatedURL("/login")} className="dashboardHeading">
          <span className="dashboardHeadingColor">
            <strong className="uppercase">{headerBrandText}</strong> Construction Self Service
          </span>
        </Navbar.Brand>
      </nav>
      
      <div className="bg-brand-secondary center-block dynamic-vertical-padding ps-2 pe-2">
        <img className="img-logo center-block" src={logoPath} alt="logo" />
      </div>
    </div>
  )
}