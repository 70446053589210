/** 
 
Simple wrapper for SmartLabel that handles lists of values

managed props (all unmanaged props (such as any that are usually used by Input component such as "value", "onChange", "maxLength", etc.) will just be passed through to the input element):
    required: 
        label
        value
    optional:
        workTypeShowList, 
        workType
        alwaysShow //will default to true if there is no workTypeShowList or workType
    advanced: //for when you have multiple parameters for when things are
        multipleShowLists (type String[][]) //NOTE: make sure types match. The worktype part this parses to numbers
        multipleDeciders (type String[])

Example:
    <SmartLabelList 
        label="Requester Phone Type"
        value={some.list.value}
        workTypeShowList={[5, 6, 7, 8, 9, 10, 11, 12, 13]}
        workType={selectedConstructWorkType}
    />

Example with multiple show lists: (this will show up if this.state.property = Renter or Other, and this.state.YesOrNoSelection = Yes)
    <SmartLabelList 
        label="Owner Name"
        value={some.list.value}
        workTypeShowList={[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]}
        workType={selectedConstructWorkType}
        multipleShowLists={[["Renter", "Other"], ["Yes"]]}
        multipleDeciders={[this.state.property, this.state.YesOrNoSelection]}
    />
*/

import SmartLabel from "./SmartLabel"
import SmartSection from "./SmartSection";

export default function SmartLabelList (props) {

    var {label, value, ...unmanagedProps } = props;

    return (
        <>
            {props.value && Array.isArray(props.value) && props.value.length > 0 && (
                <SmartSection title={label}>
                    {props.value.map((v, index) => (
                        <SmartLabel 
                            label={`Item ${index+1}`}
                            value={v}
                            {...unmanagedProps}
                        />
                    ))}
                </SmartSection>
            )}
            {/* If no values, just return a normal SmartLabel */}
            {(!props.value || !Array.isArray(props.value) || !props.value.length > 0) && (
                <SmartLabel 
                    {...props}
                />
            )}
        </>
    )
}