import React from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {BrowserView, MobileView} from 'react-device-detect'

class SmartDatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.generateId(),
      className: "form-control shadow-sm px-4",
      //date as string will stay in this component. It will be used to store mobile site's input box text.
      //there will be a different hidden text box that is either a valid date or empty that we use for actual validation, etc.
      dateAsString: this.props.value && !isNaN(new Date(this.props.value)) ? new Date(this.props.value).toISOString().split("T")[0].replaceAll('+','').toString() : "", //initial value is parsed date or empty string.
      additionalErrorText: '',
    };
    this.checkWorkTypeShowList = this.checkWorkTypeShowList.bind(this);
    this.checkMultipleShowLists = this.checkMultipleShowLists.bind(this);
  }

  generateId() {
    const length = 15;
    const prefix = "smartDatePicker_";
    let uniqueId = prefix + Math.random().toString(36).substr(2, length);
    return uniqueId;
  }

  checkWorkTypeShowList() {
    if (!this.props.workType) return false; //list given but no work type
    try {
      return this.props.workTypeShowList.includes(Number(this.props.workType));
    } catch (e) {
      return false;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.props.multipleDeciders ||
      (Array.isArray(prevProps.multipleDeciders) &&
        prevProps.multipleDeciders.every(
          (el, i) => el == this.props.multipleDeciders[i],
        ))
    ) {
      return;
    }
    let show = true;
    if (this.props.alwaysShow) {
      show = true;
    } else if (this.props.multipleShowLists && this.props.workTypeShowList) {
      show = this.checkWorkTypeShowList() && this.checkMultipleShowLists();
    } else if (this.props.workTypeShowList) {
      show = this.checkWorkTypeShowList();
    } else if (this.props.multipleShowLists) {
      show = this.checkMultipleShowLists();
    }
    if (!show && this.props.onChange) {
      let event = {
        target: {
          name: this.props.id,
          value: "",
        },
      };
      this.props.onChange(event);
    }
  }

  checkMultipleShowLists() {
    if (!this.props.multipleDeciders) return false; //list given but no deciders

    let fulfillsAllParameters = true;
    for (let i = 0; i < this.props.multipleShowLists.length; i++) {
      if (
        !this.props.multipleShowLists[i].includes(
          this.props.multipleDeciders[i],
        )
      ) {
        fulfillsAllParameters = false;
      }
    }

    return fulfillsAllParameters;
  }

  render() {
    //separate managed props from other unmanaged props. Unmanaged props will be added directly to the input element.
    var {
      className,
      value,
      id,
      label,
      invalidFeedback,
      workTypeShowList,
      workType,
      alwaysShow,
      type,
      multipleShowLists,
      multipleDeciders,
      onChange,
      minDate,
      maxDate,
      filterDate = () => true,
      ...unmanagedProps
    } = this.props;

    const show = () => {
      let show = true;
      if (alwaysShow) {
        show = true;
      } else if (multipleShowLists && workTypeShowList) {
        show = this.checkWorkTypeShowList() && this.checkMultipleShowLists();
      } else if (workTypeShowList) {
        show = this.checkWorkTypeShowList();
      } else if (multipleShowLists) {
        show = this.checkMultipleShowLists();
      }

      return show; //if no params are passed in, always show
    };

    const defaultClass = "form-control shadow-sm px-4";
    const invalidClass = "is-invalid";
    const validClass = "is-valid";

    const markInvalid = (reason) => {
      this.setState({ 
        className: `${defaultClass} ${invalidClass}`,
        additionalErrorText: reason || '',
      });
    };

    const markValid = () => {
      this.setState({ 
        className: `${defaultClass} ${validClass}` ,
        additionalErrorText: '',
      });
    };

    const checkIsValid = (val) => {
      this.setState({
        additionalErrorText: '',
      })
      if (!val & !this.props.required) {
        markValid();
      } else if (this.props.required && !val) {
        markInvalid('Field is Required');
      } else if (!!val && isNaN(new Date(val))) {
        //make sure val can parse to date
        markInvalid('Invalid Date');
      } else if (!filterDate(new Date(val))) {
        //date is holiday.
        markInvalid('Selected Date is Holiday or Weekend');
      } else if (
          new Date(val.getFullYear(), val.getMonth(), val.getDate(), 0, 0, 0) > new Date(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate(), 0, 0, 0) ||
          new Date(val.getFullYear(), val.getMonth(), val.getDate(), 0, 0, 0) < new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate(), 0, 0, 0)
       ) {
        //date not between min and max
        markInvalid('Date is not between defined min and max range.');
      } else {
        //valid
        markValid();
      }
    };

    const onChangeForDateObjects = (date) => {
      if (this.props.onChange) {
        let event = {
          target: {
              value: date,
          }
      }
        this.props.onChange(event);
      }

      //check if input is valid
      checkIsValid(date);
    };

    const onChangeForMobileInputBox = event => {
      this.setState({
        dateAsString: event.target.value
      })

      //convert yyyy-mm-dd string to date value and send to parent
      let d = event.target.value.split('-');
      let date = new Date(d[0], d[1] - 1, d[2], 0, 0, 0);
      onChangeForDateObjects(date);
    }

    if (!id) {
      id = this.state.id;
    }

    return (
      <React.Fragment>
        {show() && (
          <div
            className={
              className
                ? className
                : "col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3"
            }
          >
            <label htmlFor={id} className="form-label">
              {this.props.label}{" "}
              {!!this.props.required && <span style={{ color: "red" }}>*</span>}
              {this.props?.tooltip ? (
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement="auto-end"
                  overlay={
                    <Popover title="Popover right">
                      {this.props?.tooltip}
                    </Popover>
                  }
                >
                  <span className="questionIcon">?</span>
                </OverlayTrigger>
              ) : (
                ""
              )}
            </label>
            <div>
              <BrowserView>
                {/* Only displayed for non mobile devices */}
                <DatePicker
                  selected={this.props.value ? new Date(this.props.value) : ""}
                  className={this.state.className}
                  onChange={(date) => onChangeForDateObjects(date)}
                  minDate={minDate ? new Date(minDate) : ""}
                  maxDate={maxDate ? new Date(maxDate) : ""}
                  filterDate={filterDate}
                />
              </BrowserView>
              <MobileView>
                {/* only displayed for mobile devices */}
                <input
                  id={id}
                  onChange={(e) => onChangeForMobileInputBox(e)}
                  className={this.state.className}
                  value={this.state.dateAsString}
                  max={new Date(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate(), 0, 0, 0).toISOString().split('T')[0]}
                  min={new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate(), 0, 0, 0).toISOString().split('T')[0]}
                  type="date"
                />
              </MobileView>

              {/* The Below invisible input is for validation purposes */}
              <input
                  style={{ display: "none" }}
                  id={id}
                  value={
                    this.props.value && !isNaN(new Date(this.props.value))
                      ? new Date(this.props.value).toISOString().split("T")[0].replaceAll('+','').toString()
                      : ""
                  }
                  type="date"
                  max={new Date(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate(), 0, 0, 0).toISOString().split('T')[0]}
                  min={new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate(), 0, 0, 0).toISOString().split('T')[0]}
                  required={this.props.required}
                />
              <div className="invalid-feedback">{this.props.invalidFeedback}{!!this.state.additionalErrorText ? ` ${this.state.additionalErrorText}` : ''}</div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default SmartDatePicker;