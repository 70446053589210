import React from "react";

class LoadingSpinner extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div
        id="data-spinner"
        className={`loadingSpinner ${
          this.props.show ? "showSpinner" : "hideSpinner"
        }`}
      >
        <h3 className="text-success">
          <i className="glyphicon icon-spinner icon-spin"></i>
        </h3>
      </div>
    );
  }
}

export default LoadingSpinner;