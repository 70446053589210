import React from "react";

class InfoPage extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
        };
    }

    render() {
        return (
            <div className="bg-light">
                <div className="maincontainer">
                    <div className="container-fluid">
                        <div className="row justify-content-center no-gutter overflowFix">
                            <div className="col-12">
                                <div className="d-flex align-items-center">
                                    <div className="container mb-4">
                                        <div className="row customBorder mt-3 needs-validation" style={{ minHeight: 470 }} noValidate>
                                            <div className="col-12">
                                                <h3 className="text-center text-primary mt-3">
                                                    Service Request Creation Form
                                                </h3>
                                                <div className="mt-2 mb-4">
                                                    <h5>Some information before you get started:</h5>
                                                    <ul>
                                                        <li>Depending on the work type you select, this form will be 4 or 5 pages of varied lengths. After the last page, you will be shown a final Review page to double check the data you entered.</li>
                                                        <li>All required fields are marked with a red asterisk. If you choose not to fill out an optional field and we determine that we do need that information, you will be contacted for more information.</li>
                                                        <li>After submitting your service request, you will be unable to edit the request unless/until we send it back to you for more information. </li>
                                                        <li>The data you enter <strong> will save every time you go to the next page </strong> and also <strong>every few seconds </strong>as you fill out the form. If you want to take a break, feel free to close this form and come back later. You will find your request in the "Unsubmitted Requests" section of the dashboard. </li>
                                                        <li>After submitting your request, you will be able to see the status of your request in the Submitted Requests section of the dashboard. </li>
                                                    </ul>
                                                </div>
                                                <p>
                                                    Please click the "Next" button below to start filling out the form.
                                                </p>
                                                <div className="col-12 text-center">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary btn-block text-uppercase mb-2 shadow-sm btn-sm"
                                                        onClick={() => this.props.nextPage()}
                                                    >
                                                        Next
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InfoPage;