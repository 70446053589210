import React from "react";
import { Button, Row, Container, Col } from "react-bootstrap";
import CreateRequestParent from "../request/CreateRequestParent";
import $ from "jquery";
import { toast } from 'react-toastify';
import ProfileModal from "../profile/ProfileModal";
import { getProtectionAreaList, getBrand, translatedURL} from "../../util/functions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import ChangePasswordModal from "../profile/ChangePasswordModal";
import DashboardTable from "./TableComponents/DashboardTable";
import UnsubmittedColumns from "./TableComponents/UnsubmittedColumns";
import SubmittedColumns from "./TableComponents/SubmittedColumns";
import AccordionTableSwitch from "./AccordionTableSwitch";
import LoggedInUserCompanyName from "./LoggedInUserCompanyName";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayingPasswordModal: false,
      displayingProfileModal: false,
      dashboardData     : [],
      unsubmittedData   : [], // TEMP DEMO_DATA
      submittedData     : [], // TEMP DEMO2  
      showCreateRequest : false,
      formViewOnly      : false,
      pageNumber        : 0,        // pagination
      objectsPerPage    : 10000,       // pagination related, 
      isLoading         : true,
      fetchingDashboardData: false,
      tableDisplay      : localStorage.getItem('accordionTableSwitchSelection') == null ? 'auto' : localStorage.getItem('accordionTableSwitchSelection'), //auto, table, and accordion. auto (default)=depend on screen size, table = display table, accordion = display accordion
    }
    this.toggleShowCreateRequest = this.toggleShowCreateRequest.bind(this);
    this.setTableDisplay = this.setTableDisplay.bind(this);
    this.setDisplayingPasswordModal = this.setDisplayingPasswordModal.bind(this);
    this.setDisplayingProfileModal = this.setDisplayingProfileModal.bind(this);
    this.editSR = this.editSR.bind(this);
    this.viewSR = this.viewSR.bind(this);
    this.deleteSR = this.deleteSR.bind(this);
    this.duplicateSR = this.duplicateSR.bind(this);
  }

  setDisplayingPasswordModal(value) { this.setState({displayingPasswordModal: value}); }
  setDisplayingProfileModal(value) { this.setState({displayingProfileModal: value}); }
 
  setTableDisplay(e) { 
    localStorage.setItem('accordionTableSwitchSelection', e.target.checked ? 'table' : 'accordion')
    this.setState({tableDisplay: e.target.checked ? 'table' : 'accordion'}); 
  }

  toggleShowCreateRequest = (show, viewOnly) => {
    this.setState({
      showCreateRequest: show,
      formViewOnly: viewOnly,
    })
    this.loadServiceRequests();
  }

  createNewSR() {
    $.ajax({
      url: "/api/request/createservicerequest/",
      method: "POST",
      success: (result) => {
        localStorage.setItem("selected_request_id", result);
        this.toggleShowCreateRequest(true, false);
        this.loadServiceRequests();
      },
      error: (error) => {
        toast.error("An error is occurring that is preventing a new Service Request from being created. Please try again later.")
        console.log(error);
      }
    });
  }

  editSR(srid) {
    localStorage.setItem("selected_request_id", srid);
    this.toggleShowCreateRequest(true, false);
    return;
  }

  viewSR(srid) {
    localStorage.setItem("selected_request_id", srid);
    this.toggleShowCreateRequest(true, true);
    return;
  }

  duplicateSR(requestId) { //duplicate SR
    this.setState({ isLoading: true });

    $.ajax({
      url: "/api/request/duplicate/" + requestId,
      method: "POST",
      success: (result) => {
        toast.success("Service Request Duplicated");
      },
      error: (error) => {
        toast.error("Error Duplicating Service Request");
      },
    }).always = () =>{
      this.loadServiceRequests();
    };
  }

  deleteSR(requestId) { 
    $.ajax({
      url: "/api/request/delete/" + requestId,
      method: "POST",
      success: (result) => {
        toast.success("Successfully Deleted Service Request");
        this.setState({ unsubmittedData: this.state.unsubmittedData.filter(el => el.sruid != requestId), })
      },
      error: (error) => {
        toast.error("Error Deleting Service Request");
      },
    }).always = () =>{
      this.loadServiceRequests();
    };;
  }

  loadServiceRequests() {
    if(!!this.state.fetchingDashboardData) return; //don't fetch unless previous fetch already done (helps with slow connections)
    
    this.setState({
      fetchingDashboardData: true,
    });
    $.ajax({
      url: "/api/request/" + this.state.pageNumber + "/" + this.state.objectsPerPage,
      dataType: "json",
      method: "GET",
      success: (result) => {
        var unsubmittedD = result.filter(d => !d.dateSubmitted);
        var submittedD = result.filter(d => !!d.dateSubmitted);

        this.setState({
          unsubmittedData: unsubmittedD,
          submittedData: submittedD,
        });
      }
    }).always = () => {
      this.setState({
        isLoading: false,
        fetchingDashboardData: false,
      });
    };
  }

  determineModalsToDisplay() {
    // password modal
    fetch(translatedURL("/api/user/show-password-modal"))
    .then(res => {
      if(res.ok) {
        return res.json();
      }
      throw new Error(res.status);
    })
    .then(p => {
      if('show' in p) {
        this.setDisplayingPasswordModal(!!p.show); //'!!' ensures that this value is a boolean
      }else{
        this.setDisplayingPasswordModal(false);
      }
    })
    .catch( (error) => {
      console.log('show password modal fetch error: ', error)
    })

    // profile modal
    fetch(translatedURL("/api/user/show-profile-modal"))
    .then(res => {
      if(res.ok) {
        return res.json();
      }
      throw new Error(res.status);
    })
    .then(p => {
      if('show' in p) {
        this.setDisplayingProfileModal(!!p.show); //'!!' ensures that this value is a boolean
      }else{
        this.setDisplayingProfileModal(false);
      }
    })
    .catch( (error) => {
      console.error('show profile modal fetch error: ', error)
    })
  }

  componentDidMount() {
    this.determineModalsToDisplay();
    this.loadServiceRequests();
    this.interval = setInterval(() => this.loadServiceRequests(), 50000);
    getProtectionAreaList(); // saves in localstorage

    //get holidays list for logged in brand and save to localStorage
    if(!!getBrand()) {
      $('body').addClass(getBrand());
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const {showCreateRequest} = this.state;

    return (
      <Container fluid={true} className="dashboardPosition mt-2">
        {/* Don't display both password modal and profile modal at same time */}
        {this.state.displayingPasswordModal && <ChangePasswordModal setDisplayingModal={this.setDisplayingPasswordModal} show={this.state.displayingPasswordModal} />}
        {this.state.displayingProfileModal && !this.state.displayingPasswordModal && <ProfileModal setDisplayingModal={this.setDisplayingProfileModal} show={this.state.displayingProfileModal} />}

        <Row >
          <Col xs={{span: 12, order: 'last'}} sm={{span: 6, order: 'first'}} className="mb-4" >
            <LoggedInUserCompanyName />
          </Col>
          <Col xs={{span: 12, order: 'first'}} sm={{span: 6, order: 'last'}} className="mb-4">
           
            <div>
              <Button className="btn btn-success newRequestButton" onClick={() => this.createNewSR()}>
                <FontAwesomeIcon icon={faPlus} />
                {'\u00A0'}Create Request
              </Button>
              {this.state.showCreateRequest
                ?
                <div>
                  <CreateRequestParent show={showCreateRequest} fadeModal={this.props.fadeRequestModal} toggleShowCreateRequest={this.toggleShowCreateRequest} viewOnly={this.state.formViewOnly} />
                </div>
                : null
              }
            </div>
          </Col>
        </Row>
        <Row >
          <Col xs={12} className={this.state.tableDisplay == "auto" ? "d-inline d-md-none" : undefined}>
            <AccordionTableSwitch tabelHiddenLevel={1} setTableDisplay={this.setTableDisplay} tableDisplay={this.state.tableDisplay}/>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mb-5">
            <h4>Unsubmitted Requests:</h4>
            <DashboardTable 
              mode={this.state.tableDisplay} 
              columns={UnsubmittedColumns(this.editSR, this.viewSR, this.deleteSR, this.duplicateSR)} 
              data={this.state.unsubmittedData} 
              style={{minWidth: '1000px'}} 
              isLoading={this.state.isLoading}
            />
          </Col>
          <Col xs={12}>
            <h4>Submitted Requests:</h4>
            <DashboardTable 
              mode={this.state.tableDisplay} 
              columns={SubmittedColumns(this.editSR, this.viewSR, this.duplicateSR)}
              data={this.state.submittedData} 
              style={{minWidth: '1200px'}} 
              isLoading={this.state.isLoading}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Dashboard