import { getBrand, getSelectedRequest } from "./functions";
const baseUrl = "";

export default {
    login: baseUrl + '/api/auth/login',
    workTypes: baseUrl + "/api/dropdownlist/workTypes?brand="+ getBrand(),
    getUserProfile: baseUrl + "/api/user/profile",
    // maximoMDUGCITY: baseUrl + "/api/dropdownlist/maximo/MDUGCITY",
    maximoMDUGCITY: baseUrl + "/api/dropdownlist/cities/", // expected /{pathvariable of state}
    getStates: baseUrl + "/api/dropdownlist/states",
    maximoSTATEPROVINCE: baseUrl + "/api/dropdownlist/maximo/STATEPROVINCE",
    maximoMDUGSNOWMTRPROT: baseUrl + "/api/dropdownlist/maximo/MDUGSNOWMTRPROT",
    maximoMDUGFOUNDATIONTYPE: baseUrl + "/api/dropdownlist/maximo/MDUGFOUNDATIONTYPE",
    maximoMDUGDVLPMTSTAGE: baseUrl + "/api/dropdownlist/maximo/MDUGDVLPMTSTAGE",
    maximoMDUGPLATSTATUS: baseUrl + "/api/dropdownlist/maximo/MDUGPLATSTATUS",
    maximoMDUGSPSERVICETYPE: baseUrl + "/api/dropdownlist/maximo/MDUGSPSERVICETYPE",
    maximoMDUGCUSTOMERTYPE: baseUrl + "/api/dropdownlist/maximo/MDUGCUSTOMERTYPE",

    maximoMDUGFUELTYPE: baseUrl + "/api/dropdownlist/maximo/MDUGFUELTYPE",
    maximoMDUGMETERPRESSURE: baseUrl + "/api/dropdownlist/maximo/MDUGMETERPRESSURE",

    get getActiveSR() { return baseUrl +`/api/request/getActiveSR?selected_request_id=${getSelectedRequest()}`},
    get submitToQueue() { return baseUrl +`/api/request/submitToQueue?selected_request_id=${getSelectedRequest()}`},
    get addCustomerInfo() { return baseUrl +`/api/request/addCustomerInfo?selected_request_id=${getSelectedRequest()}`},
    get addServiceRequestInfo() { return baseUrl +`/api/request/addServiceRequestInfo?selected_request_id=${getSelectedRequest()}`},
    get addProjectInfo() { return baseUrl +`/api/request/addProjectInfo?selected_request_id=${getSelectedRequest()}`},
    get attachFile() { return baseUrl +`/api/request/attachFile?selected_request_id=${getSelectedRequest()}`},
    get addEquipmentInfo() { return baseUrl +`/api/request/addEquipmentInfo?selected_request_id=${getSelectedRequest()}`},
    get addOnsiteInfo() { return baseUrl +`/api/request/addOnsiteInfo?selected_request_id=${getSelectedRequest()}`},
} 
