import React from "react";
import Cookies from "js-cookie";
import SupportBrowser from "./SupportBrowser";
import { Row, Col, Container} from "react-bootstrap";
import { getHelpLineNumber, getBrand } from "../../util/functions";

class Footer extends React.Component {
    constructor(props) {
        super(props);
        var now = new Date();
        var year = now.getUTCFullYear()
        this.state = {
            cyear: year,
            brandShortName: getBrand(),
        }
    }

    render() {
        return (
            <footer className={`${this.state.brandShortName} bg-brand`}>
                <SupportBrowser />
                <Col xs={12} className='text-center pt-2' >
                    <Col md={{ span: 4, offset: 4 }}>
                        <p className="smaller footerTextColor">©
                            <span id="copyright-year">{this.state.cyear} </span>
                            <span className="uppercase">{this.state.brandShortName}</span>
                        </p>
                        <p className="smaller footerTextColor">
                            {
                                getHelpLineNumber() && 
                                <span id="copyright-year"> Help line: <a className="footerTextColor" href={"tel:"+getHelpLineNumber()}>{getHelpLineNumber()}</a> </span>
                            }
                        </p>
                    </Col>
                </Col>
            </footer>
        )
    }
}


export default Footer