/**
 * This page, on load, will immediately post to /login and attempt to log in using
 *  1. an email from an "email" url parameter and
 *  2. brand, from a "brand" url parameter.
 * 
 * This page should only be accessible when Sim5000 mode is activated. Check the root Readme.md file to see how to turn on Sim5000 mode.
 *  This will be enforced by the SpringSecurity configuration. 
 *  When running this site locally, we'll be able to access this page directly (if we run frontend and backend separately), 
 *  but that will not be the case when running on the server  (when frontend is ran separately, all requests don't go through spring security. But all requests do on the server. )
 * 
 * This page will appear mostly blank.
 */
import {useEffect, useRef, useState} from 'react'
import { translatedURL } from '../../util/functions'
import FormAutoCSRF from '../lib/FormAutoCSRF';
import Cookies from "js-cookie"

//to test. Go to url: localhost:8080/cssp-sim5000/sim5000-login?email=someemail@email.com&brand=cng
export default function Sim5000_Login() {
    const formRef = useRef(null);
    const [email, setEmail] = useState('');
    const [brand, setBrand] = useState('');
    const [error, setError] = useState('');
    const [ready, setReady] = useState(false);
    const [showManualButton, setShowManualButton] = useState(false);

    const submitForm = () => {
        setError('');
        console.log('email', email, 'brand', brand)
        if(!email || !brand) {
            setError('Email or Brand not set. Not logging in.')
            return;
        }
        formRef.current.submit();
    }

    useEffect(() => {
        setError("")
        const urlSearchString = window.location.search;
        const params = new URLSearchParams(urlSearchString);
        setEmail(params.get('email'));
        setBrand(params.get('brand'));
        setReady(true);
        if(!brand || !email) {
            setError("Username or brand not specified.")
            return;
        }

        //wait a few seconds for all resources, etc to load so we don't end up loosing our session after they return.
        const timeoutId = setTimeout(() => submitForm(), 5000);
        const showManualButtonTimeoutId = setTimeout(() => setShowManualButton(true), 5500);

        return () => {
            clearTimeout(timeoutId);
            clearTimeout(showManualButtonTimeoutId);
        }
    }, [ready, email, brand]);

    if(!ready) return;

    return (
        <>
            <p>Logging in as {email || '<<error>>'} using brand {brand || '<<error>>'}</p>
            <p>You will be redirected shortly</p>

            {!!error && <p style={{color: 'red'}}>The following error occurred: {error}</p>}

            <form ref={formRef} method="POST" action={translatedURL("/login")} className="d-none" noValidate>
                <input type="hidden" name={process.env.REACT_APP_CSRF_TOKEN_NAME} value={Cookies.get(process.env.REACT_APP_CSRF_TOKEN_NAME)} />
                <input name="email" value={email} />
                <input name="brand" value={brand} />
            </form>

            {!!email && !!brand && !!showManualButton &&
                <button onClick={() => submitForm()}>Click here if you were not redirected automatically</button>
            }
        </>
    )
}