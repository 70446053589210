import { Row, Col} from "react-bootstrap";
import React from "react";
import $ from "jquery";
import CustomAlert from "../lib/Alert";
import PasswordStrengthMessage from "./PasswordStrengthMessage";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faXmark } from '@fortawesome/free-solid-svg-icons'
import SmartTextBox from "../lib/SmartTextBox";
import { getPasswordStrength, translatedURL } from "../../util/functions";
import CustomLink from '../lib/Link'

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "registerPage",
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      phone: "",
      redirect: false,
      authError: false,
      passwordStrength: 0,
      phoneError: true,
    };
    this.handlePwdChange = this.handlePwdChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleConfirmPwdChange = this.handleConfirmPwdChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
  }

  handlePwdChange(event) {
    this.setState({
      password: event.target.value,
      passwordStrength: getPasswordStrength(event.target.value),
    });
  };

  handlePhoneChange = (event) => {
    this.setState({ phone: event.target.value });
  };
  handleEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };
  handleConfirmPwdChange = (event) => {
    this.setState({ confirmPassword: event.target.value });
  };
  handleNameChange = (event) => {
    this.setState({ name: event.target.value });
  };

  componentDidMount(){
    this.props.setPageHeaders("", "");
  }

  render() {
    const handleSubmit = (event) => {
      event.preventDefault();
      const email = this.state.email;
      const password = this.state.password;
      const confirmPassword = this.state.confirmPassword;
      const name = this.state.name;
      const phone = this.state.phone;
      if(!phone || !phone.match(/^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/) ) {
        return;
      }
      let bodyFormData = {
        name: name,
        password: password,
        phone: phone,
        email: email,
      };

      if (this.state.passwordStrength >= 4) {
        if (password === confirmPassword) {
          $.ajax({
            url: "/api/auth/signup",
            method: "POST",
            data: JSON.stringify(bodyFormData),
            contentType: "application/json; charset=utf-8",
          }).done(() => {
            window.location.href = translatedURL("/login?success=registered");
          }).fail((result) => {
            if (result.responseText === "Already_Exists") {
              window.location.href = translatedURL("/login?error=already_exists");
              return;
            }
            window.location.href = translatedURL("/login?error=unexpected");
          });
        }
      }
    };

    return (
      <form onSubmit={handleSubmit}>
        <Row>
          <SmartTextBox
            className="col-12 mb-3"
            label="Name"
            maxLength="64"
            value={this.state.name}
            onChange={this.handleNameChange}
            invalidFeedback="Please provide a valid Name."
            placeholder="Your Name"
            required
          />
          <SmartTextBox
            className="col-12 mb-3"
            label="Email Address"
            tooltip="This email will be used as your username whenever you log into the Construction Self Service Portal. Please make sure it is valid and belongs to you."
            type="email"
            maxLength="100"
            value={this.state.email}
            onChange={this.handleEmailChange}
            invalidFeedback="Please provide a valid Email Address."
            placeholder="Email Address"
            required
          />
          <SmartTextBox
            className="col-12"
            label="Phone Number"
            tooltip="Start entering numbers starting with your area code. We will take care of the formatting for you"
            type="phone"
            value={this.state.phone}
            onChange={this.handlePhoneChange}
            invalidFeedback="Please provide a valid Phone Number."
            placeholder="Phone Number"
            required
          />
          <div className="text-muted mt-0 mb-3">
            <span>e.g. (333) 333-4444 </span>
          </div>
          <PasswordStrengthMessage passwordStrength={this.state.passwordStrength} />
          <SmartTextBox
            className="col-12 mb-3"
            label="Password"
            tooltip="Please use uppercase letters, lower case letters, special characters and numbers to create a strong password"
            type="password"
            value={this.state.password}
            onChange={this.handlePwdChange}
            invalidFeedback="Please enter a passphrase you would like to use for this site."
            placeholder="Password"
            required
          />
          <SmartTextBox
            className="col-12 mb-3"
            label="Confirm Password"
            type="password"
            value={this.state.confirmPassword}
            onChange={this.handleConfirmPwdChange}
            invalidFeedback="Please reenter your new passphrase"
            placeholder="Confirm Password"
            required
          />
          <div className="invalid-feedback">
            Password you entered does not match.
          </div>
        {this.state.phone && !this.state.phone.match(/^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/) 
        && (
            <CustomAlert
              variant="danger"
              message="Invalid Phone Number"
            ></CustomAlert>
          )}
        {this.state.confirmPassword &&
          this.state.password !==
          this.state.confirmPassword && (
              <CustomAlert
                variant="danger"
                message="Password you entered does not match"
              ></CustomAlert>
            )}
          {this.state.password && this.state.passwordStrength < 4 && (
            <CustomAlert
              variant="danger"
              message="Password not strong enough. Please use uppercase letters, lower case letters, special characters, and numbers"
            ></CustomAlert>
          )}
        </Row>
        <Row className="mt-2">
          <Col>
            <CustomLink href="/login" className="">
              <button
                type="button"
                className="btn btn-default btn-block shadow-sm float-start w-40"
              >
                <FontAwesomeIcon className='d-none d-sm-inline' icon={faXmark} />
                {'\u00A0'}Cancel
              </button>
            </CustomLink>
            <button
              type="submit"
              className="btn btn-success btn-block text-uppercase mb-2 shadow-sm float-end me-1 w-40"
              disabled={this.state.passwordStrength < 4}
            >
              <FontAwesomeIcon className='d-none d-sm-inline' icon={faDownload} />
              {'\u00A0'}Submit
            </button>
          </Col>
        </Row>
      </form>
    );
  }
}

export default Register