import React from "react";
// import { Col, Row, Button, Container } from "react-bootstrap";
import CustomAlert from "../lib/Alert";
import $ from "jquery";
import LoadingSpinner from "../lib/LoadingSpinner";
import PasswordStrengthMessage from "../register/PasswordStrengthMessage";
import { getPasswordStrength, translatedURL } from "../../util/functions";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "forgetPasswordPage",
      code: "",
      userName: "",
      userId: "",
      password: "",
      confirmPassword: "",
      passwordStrength: 0,
      errorMessage: "",
      successMessage: "",
      isLoading: false,
    };
    this.handlePwdChange = this.handlePwdChange.bind(this);
  }
  componentDidMount() {
    this.props.setPageHeaders("Password Reset", "Construction Self Service Portal");
  }

  handlePwdChange(event) {
    this.setState({
      password: event.target.value,
      passwordStrength: getPasswordStrength(event.target.value),
    });
  };

  render() {
    const handleConfirmPwdChange = (event) => {
      this.setState({ confirmPassword: event.target.value });
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      this.setState({ isLoading: true });

      //update user's password
      $.ajax({
        url: "/api/forgot-password/reset-password",
        method: "POST",
        data: this.state.confirmPassword,
        contentType: "text/plain; charset=utf-8",
      }).done((result) => {
        this.setState({
           isLoading: true,
           errorMessage: "",
           successMessage: (result + " You will be redirected to the login page shortly."),
          });
        setTimeout(() => {
          window.location.replace(translatedURL("/login"));
          return;
        }, "5000")

      }).fail(() => {
        this.setState({
          errorMessage: "Error occurred while resetting password. Please try again later.",
          isLoading: false,
          successMessage: "",
         });
      });

    };

    return (
      <div>
        {!!this.state.errorMessage && (
          <CustomAlert
            variant="danger"
            message={this.state.errorMessage}
          ></CustomAlert>
        )}
        {!!this.state.successMessage && (
          <CustomAlert
            variant="success"
            message={this.state.successMessage}
          />
        )}
        {this.state.isLoading && <LoadingSpinner show={this.state.isLoading} />}
        {!this.state.errorMessage && !this.state.isLoading && (
          <div>
            <p >
              Welcome {this.state.userName ? (this.state.userName + "!") : ""} <br />

              Please enter your new password
            </p>

            <form onSubmit={handleSubmit}>
              <PasswordStrengthMessage passwordStrength={this.state.passwordStrength} />
              <div className="mb-3">
                <input
                  id="inputPassword"
                  type="password"
                  tooltip="Please use uppercase letters, lower case letters, special characters and numbers to create a strong password"
                  placeholder="Password"
                  required
                  onChange={this.handlePwdChange}
                  className="form-control shadow-sm px-4 text-primary"
                />
                <div className="invalid-feedback">
                  Please provide a valid Password.
                </div>
              </div>
              <div className="mb-3">
                <input
                  id="inputConfirmPassword"
                  type="password"
                  placeholder="Confirm Password"
                  required
                  onChange={handleConfirmPwdChange}
                  className="form-control shadow-sm px-4 text-primary"
                />
                <div className="invalid-feedback">
                  Password you entered does not match.
                </div>
              </div>
              {this.state.confirmPassword &&
                this.state.password !==
                this.state.confirmPassword && (
                  <CustomAlert
                    variant="danger"
                    message="Password you entered does not match"
                  ></CustomAlert>
                )}
              {this.state.password && this.state.passwordStrength < 4 && (
                <CustomAlert
                  variant="danger"
                  message="Password not strong enough: please use uppercase letters, lower case letters, special characters and numbers"
                ></CustomAlert>
              )}

              <div className="mt-2 clearfix">

                <button
                  type="submit"
                  className="btn btn-success btn-block text-uppercase mb-2 shadow-sm w-100 float-end"
                  disabled={this.state.passwordStrength < 4 || this.state.password !== this.state.confirmPassword }
                >
                  Confirm Password Change
                </button>
              </div>


            </form>
          </div>
        )}
      </div>
    );
  }
}

export default ResetPassword