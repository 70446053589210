import React from "react";
import SmartTextBox from "../lib/SmartTextBox";
import $ from "jquery";
import { Navigate } from "react-router-dom";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import { getPasswordStrength, translatedURL } from "../../util/functions";
import { toast } from "react-toastify";
import PasswordStrengthMessage from "../register/PasswordStrengthMessage";
import { Col, Fade } from "react-bootstrap";
import CustomAlert from "../lib/Alert";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      isLoading: true,
      passwordStrength: 0,

      showCurrentPasswordInput: true,
      passwordsDoNotMatch: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);

  }

  componentDidMount() {
    //if user was generate by system and hasn't ever set password on their own hide 'current password' textbox
    this.setState({ isLoading: true });

    fetch(translatedURL("/api/user/show-current-password-textbox"))
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(res.status);
      })
      .then(p => {
        if ('show' in p) {
          this.setState({
            showCurrentPasswordInput: !!p.show,
            isLoading: false,
          })
        } else {
          this.setState({
            showCurrentPasswordInput: false,
            isLoading: false,
          })
        }
      })
      .catch((error) => {
        console.log('error determining whether or not to hide password text box. default to show. ', error)
        this.setState({
          showCurrentPasswordInput: false,
          isLoading: false,
        })
      })
  }

  handleInputChange(event) {
    let optional = {};
    if (event.target.name == 'newPassword') {
      optional = {
        passwordStrength: getPasswordStrength(event.target.value),
        passwordsDoNotMatch: (this.state.confirmNewPassword !== event.target.value)
      }
    }
    if (event.target.name == 'confirmNewPassword') {
      optional = { passwordsDoNotMatch: this.state.newPassword !== event.target.value }
    }

    this.setState({ [event.target.name]: event.target.value, ...optional });
  }

  ConditionalWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children;

  render() {
    const handleSubmit = (event) => {
      event.preventDefault();

      if (!this.formRef.current.checkValidity()) {
        this.formRef.current.classList.add('was-validated');
        return false
      }
      if (this.state.newPassword != this.state.confirmNewPassword) {
        return toast.error("Confirm new password does not match with new password")
      }

      if (this.state.passwordStrength < 4) {
        return toast.error("New Password not strong enough")
      }

      this.setState({ isLoading: true });
      let tempData = { ...this.state };
      delete tempData.isLoading;

      let bodyFormData = {
        ...tempData
      };
      $.ajax({
        url: `/api/user/change-password`,
        method: "PUT",
        data: JSON.stringify(bodyFormData),
        contentType: "application/json",
        success: (result) => {
          this.setState({ isLoading: false });
          if (this.props.displayedInModal) {
            toast.success("Password updated successfully")
            this.props.showModal(false);
          } else {
            window.location.href = translatedURL("/profile?passwordChanged=success");
          }
        },
        error: (error) => {
          this.setState({ isLoading: false });
          toast.error(error.responseText)
        },

      });
    };

    return (
      <React.Fragment>
        <this.ConditionalWrapper
          condition={!this.props.displayedInModal}
          wrapper={children =>
            // add additional formatting when displayed in own page (as opposed to inside a modal)
            <div className="bg-light">
              <div className="maincontainer">
                <div className="container-fluid">
                  <div className="row justify-content-center no-gutter">
                    <div className="col-12">
                      <div className="d-flex align-items-center">
                        <div className="container pt-4 mb-4">
                          <div className="row customBorder mb-4">
                            {children}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        >
          {this.state.isLoading && <SkeletonLoader count={3} height={30} />}
          {/* TODO: I can't see fade animations in my vm. But if this ends up looking cool in dev, I should wrap all the form pages in Bootstrap Fade */}
          <Fade in={!this.state.isLoading}>
            <Col xs={12}>
              <h3 className="text-center text-primary">
                Change Password
              </h3>
              <form ref={this.formRef} onSubmit={handleSubmit} className="row mt-4 needs-validation" noValidate>
                <SmartTextBox
                  id="currentPassword"
                  label="Current Password"
                  type="password"
                  placeholder="Current Password"
                  value={this.state.currentPassword}
                  onChange={this.handleInputChange}
                  invalidFeedback="Please provide current password."
                  required
                  multipleShowLists={[[true]]}
                  multipleDeciders={[this.state.showCurrentPasswordInput]}
                />

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3" ></div>

                <PasswordStrengthMessage passwordStrength={this.state.passwordStrength} />
                <SmartTextBox
                  id="newPassword"
                  label="New Password"
                  tooltip="Please use uppercase letters, lower case letters, special characters and numbers to create a strong password"
                  type="password"
                  placeholder="New Password"
                  value={this.state.newPassword}
                  onChange={this.handleInputChange}
                  invalidFeedback="Please provide valid new password."
                  required
                />
                <SmartTextBox
                  id="confirmNewPassword"
                  label="Confirm New Password"
                  type="password"
                  placeholder="New Password"
                  value={this.state.confirmNewPassword}
                  onChange={this.handleInputChange}
                  invalidFeedback="Please provide valid new password."
                  required
                />

                {!!this.state.confirmNewPassword && this.state.passwordsDoNotMatch && (
                  <CustomAlert
                    variant="danger"
                    message="Passwords do not match"
                  ></CustomAlert>
                )}
                {this.state.newPassword && this.state.passwordStrength < 4 && (
                  <CustomAlert
                    variant="danger"
                    message="Password not strong enough. Please use uppercase letters, lower case letters, special characters, and numbers"
                  ></CustomAlert>
                )}

                <div className="col-12 text-center float-start">
                  {!this.props.displayedInModal && <a href={translatedURL("/profile")} className="mx-3 btn btn-primary btn-block text-uppercase mb-2 shadow-sm btn-sm float-start">Cancel</a>}

                  <button
                    disabled={this.state.isLoading || this.state.passwordsDoNotMatch || this.state.passwordStrength < 4}
                    type="submit"
                    className="btn btn-primary btn-block text-uppercase mb-2 shadow-sm btn-sm float-end"
                  >
                    {"Update"}
                  </button>
                </div>
              </form>
            </Col>
          </Fade>
        </this.ConditionalWrapper>
      </React.Fragment>
    );
  }
}


export default ChangePassword;