import React from "react";
import { toast } from 'react-toastify';
import { Popover, OverlayTrigger } from "react-bootstrap"; 
import { translatedURL } from "../../util/functions";

/*
managed props (all unmanaged props (such as any that are usually used by Input component such as "value", "onChange", "maxLength", etc.) will just be passed through to the input element):
            required: 
                label, 
                valueList + valueLabelList OR fetchURL, 
                invalidFeedback
                selectedValue -- the value that should be selected by default
            optional:
                workTypeShowList
                workType
                workTypeOverrideList (list of pages where radio button value will be overridden - NOTE: these pages must also be included in workTypeShowList if that value is used)
                overrideValue (value to override with)
                alwaysShow //will default to true if there is no workTypeShowList or workType
                type
            advanced: //for when you have multiple parameters for when things are
                multipleShowLists (type String[][]) //NOTE: make sure types match. The worktype part this parses to numbers
                multipleDeciders (type String[])


        Example:
            <SmartRadioButtons 
                id="requesterTextNotification"  
                label="Testing Smart Radio Buttons"
                valueList={["YES", "NO"]}
                valueLabelList={["Yes", "No"]}
                selectedValue={this.state.requesterTextNotification}
                onChange={this.handleRequesterTextNotification}
                invalidFeedback="Please provide a valid Requester Text Notification."
                workTypeShowList={[1, 2, 3, 4]}
                workType={selectedConstructWorkType}
            />

            <SmartRadioButtons 
                id="requesterTextNotification"  
                label="Testing Smart Radio Buttons"
                fetchURL="/url/to/get/stuff"
                selectedValue={this.state.requesterTextNotification}
                onChange={this.handleRequesterTextNotification}
                invalidFeedback="Please provide a valid Requester Text Notification."
                workTypeShowList={[1, 2, 3, 4]}
                workType={selectedConstructWorkType}
            />
*/

class SmartRadioButtons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.generateId(),
            data: [{ id: '', val: 'loading...' }],
            errorOccurred: false,
        }
        
    }

    generateId() {
        const length = 15;
        const prefix = 'smartRadio_';
        let uniqueId = prefix + Math.random().toString(36).substr(2, length);
        return uniqueId;
    }

    checkOverrideValue = () => {
        if(!this.props.overrideValue || !this.props.workTypeOverrideList) return false;
        try {
            // Override selection value if work type appears in the work type override list
            return this.props.workTypeOverrideList.includes(Number(this.props.workType));
        } catch (e) {
            return false;
        }
    }

    fetchDataAndSetChecked() {
        const showErrorToast = () =>{  
            toast.error("Unable To Connect to our Internal Server. Please try again later.");
        }

        if(this.checkOverrideValue()) {
            // Update the radio button value to whatever the forced value should be
            let event = {
                target: {
                    value: this.props.overrideValue
                }
            }
            this.props.onChange(event)

            // Value is already set, so no need to fetch list of possible selection values
            return;
        }

        //set up data in state
        if(this.props.fetchURL) {
            fetch(translatedURL(this.props.fetchURL))
            .then(res => {
                if(res.ok) {
                    return res.json();
                }
                throw new Error(res.status);
            })
            .then(d => {
                this.setState({
                    data: d,
                    errorOccurred: false,
                });
            })
            .catch( (error) => {
                showErrorToast();
                this.setState({
                  data: [],
                  errorOccurred: true,
                })
            });
        }else if(this.props.valueList && this.props.valueLabelList && this.props.valueList.length == this.props.valueLabelList.length) {
            let d = [];
            for(let i = 0; i<this.props.valueList.length; i++) {
                d.push({id: this.props.valueList[i], val: this.props.valueLabelList[i]});
            };
            this.setState({
                data: d,
            });
        }else{
            this.setState({
                data: [{ id: '', val: 'error occurred' }],
            });
        }
    }

    componentDidMount() {
        this.fetchDataAndSetChecked();
    }

    render() {
        const checkWorkTypeShowList = () => {
            if (!this.props.workType) return false; //list given but no work type
            try {
                return this.props.workTypeShowList.includes(Number(this.props.workType));
            } catch (e) {
                return false;
            }
        }

        const checkMultipleShowLists = () => {
            if (!this.props.multipleDeciders) return false; //list given but no deciders

            let fulfillsAllParameters = true;
            for (let i = 0; i < this.props.multipleShowLists.length; i++) {
                if (!this.props.multipleShowLists[i].includes(this.props.multipleDeciders[i])) {
                    fulfillsAllParameters = false;
                }
            }

            return fulfillsAllParameters;
        }

        const show = () => {
            if(this.checkOverrideValue()) return false; // Don't render if a specific value is being forced
            if (this.props.alwaysShow) {
                return true;
            } else if (this.props.multipleShowLists && this.props.workTypeShowList) {
                return (checkWorkTypeShowList() && checkMultipleShowLists());
            } else if (this.props.workTypeShowList) {
                return checkWorkTypeShowList();
            } else if (this.props.multipleShowLists) {
                return checkMultipleShowLists();
            }

            return true; //if no params are passed in, always show
        }

        //separate managed props from other unmanaged props. Unmanaged props will be added directly to the input element.
        var { className, label, id, valueList, overrideValue, valueLabelList, invalidFeedback, workType, workTypeShowList, workTypeOverrideList, multipleDeciders, multipleShowLists, alwaysShow, selectedValue, fetchURL, ...unmanagedProps } = this.props;

        if(!id) {
            id = this.state.id;
        }
        return (
            <React.Fragment>
                {show() &&
                    <div className={`${className ? className : "col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3"}`}>
                        <label
                            className="form-label"
                        >
                            {this.props.label} {!!this.props.required && (<span style={{ color: 'red' }}>*</span>)}
                            {
                                this.props?.tooltip ?
                                    <OverlayTrigger
                                        trigger={['hover', 'focus']}
                                        placement="auto-end"
                                        overlay={
                                            <Popover title="Popover right">
                                                {this.props?.tooltip}
                                            </Popover>
                                        }
                                    >
                                        <span className="questionIcon">?</span>
                                    </OverlayTrigger>
                                    : ""
                            }
                        </label>
                        <div>
                            {!this.state.errorOccurred && this.state.data.map((obj, i) => (
                                <div className="form-check form-check-inline" key={i}>
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name={id}
                                        id={id + '_' + i}
                                        value={obj[Object.keys(obj)[0]] || ''}
                                        checked={this.props.selectedValue === obj[Object.keys(obj)[0]]}
                                        {...unmanagedProps}

                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor={id + '_' + i}
                                    >
                                        {obj[Object.keys(obj)[1]]}
                                    </label>
                                </div>
                            ))}
                            {this.state.errorOccurred && (
                                <p>Error Occurred</p>
                            )}
                        </div>
                        <div className="invalid-feedback">
                            {this.props.invalidFeedback}
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}

export default SmartRadioButtons;