import React from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import $ from "jquery";
import { toast } from 'react-toastify';
import InfoPage from "./InfoPage";
import CreateProjectRequest from "./CreateProjectRequest";
import CreateServiceRequest from "./CreateServiceRequest"
import CreateCustomerRequest from "./CreateCustomerRequest"
import CreateOnsiteRequest from "./CreateOnsiteRequest";
import CreateEquipmentRequest from "./CreateEquipmentRequest";
import Review from "./Review";
import { getSelectedRequest, objectsAreEqualOrEmpty } from "../../util/functions";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faX } from '@fortawesome/free-solid-svg-icons'
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons'

import _ from 'lodash'

class CreateRequestParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 0,
            selectedConstructionWorkType: 0,
            serviceTypeOptions: null,
            lastSaveTimeStamp: '',
            isSaving: false,
            saveErrorOccurred: false,
            showSaveIndicator: false,

            oldLatitude: null,
            oldLongitude: null,

            lastSubmittedData: {},
            updateInProgress: false,
            autoSaveTimeout: null,

            serviceRequestObject: {},
        };

        this.saveIndicator = this.saveIndicator.bind(this);
        this.saveSR = _.debounce(this.saveSR.bind(this), 1200);
        this.saveSR_Now = this.saveSR_Now.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.setSelectedConstructionWorkType = this.setSelectedConstructionWorkType.bind(this);
        this.submitToMaximo = this.submitToMaximo.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.setOldLatitudeAndLongitude = this.setOldLatitudeAndLongitude.bind(this);
        this.replaceServiceRequestObject = this.replaceServiceRequestObject.bind(this);
        this.updateServiceRequestObject = this.updateServiceRequestObject.bind(this);
    }

    componentDidMount() {
        if(!!this.state.serviceTypeOptions) {
            return;
        }
        $.ajax({
            url: `/api/dropdownlist/maximo/MDUGSPSERVICETYPE`,
            dataType: "json",
            method: "GET",
            success: (result) => {
                this.setState({
                    serviceTypeOptions: result,
                });
            },
            error: (error) => {
                console.log(error);
            }
        });
    }

    setLastSubmittedData(val) {
        this.setState({lastSubmittedData: _.cloneDeep(val)});
    }

    setUpdateInProgress(val) {
        this.setState({updateInProgress: val});
    }
   
    setOldLatitudeAndLongitude(lat, lng) {
        this.setState({ oldLatitude: lat, oldLongitude: lng  })
    }
    
    hideSaveIndicator() {
        this.setState({
            showSaveIndicator: false,
        }) 
    }
    
    savingStarted() {
        this.setState({
            isSaving: true,
            showSaveIndicator: true,
            saveErrorOccurred: false,
        }) 
    }
    
    savingFail() {
        this.setState({
            isSaving: false,
            showSaveIndicator: true,
            lastSaveTimeStamp: "Error",
            saveErrorOccurred: true,
        })
        // toast.error('Error Autosaving Request');
    }
    
    savingSuccess() {
        this.setState({
            isSaving: false,
            showSaveIndicator: true,
            lastSaveTimeStamp: new Date().toLocaleTimeString(),
            saveErrorOccurred: false,
        })
    }
    
    saveIndicator(status) {
        //this function just cuts down the amount of functions I need to pass to all children.
        //1= starting
        //2 = success
        //3 = fail
        //0 = hide status indicator
        switch(status) {
            case 0: this.hideSaveIndicator(); break;
            case 1: this.savingStarted(); break;
            case 2: this.savingSuccess(); break;
            case 3: this.savingFail(); break;
        }
    }

    replaceServiceRequestObject(sr) {
        this.setState({
            serviceRequestObject: sr
        })
    }

    updateServiceRequestObject(property, value) {
        this.setState({
            serviceRequestObject: _.set(this.state.serviceRequestObject, property, value),
        })
    }

    saveSR(
        additionalSuccessFunction,
        optParams = {} //allows any other random objects to be added if needed later.
    ) {
        this.saveIndicator(1)

        if(this.state.updateInProgress) {
            if(!additionalSuccessFunction) return; //if there was an additional function to be called on success, that means we want to force update even if previous update still in progress.
        }

        if(objectsAreEqualOrEmpty(this.state.lastSubmittedData, this.state.serviceRequestObject)) {
            this.saveIndicator(2)

            if(!!additionalSuccessFunction) additionalSuccessFunction(); //everything already saved so call success function
            return; 
        }
        
        this.saveSR_Now(additionalSuccessFunction, optParams);
    }

    saveSR_Now(
        additionalSuccessFunction,
        optParams = {}
    ) { //allows any other random objects to be added if needed later.
        this.saveIndicator(1)

        if(this.state.updateInProgress) {
            if(!additionalSuccessFunction) return; //if there was an additional function to be called on success, that means we want to force update even if previous update still in progress.
        }
        
        this.setUpdateInProgress(true)
        //this endpoint doesn't need to save attachment data, so lets remove it to stop problems from occurring:
        this.updateServiceRequestObject('attachment', undefined);

        const urlParams = {
            'selected_request_id': !!getSelectedRequest() ? getSelectedRequest() : undefined, //if requestid saved in localstorage, send it
            'newRequest': !!getSelectedRequest() ? undefined : true, //if no request id saved in local storage, send "newRequest=true"
        }

        if(!!optParams && !!optParams.checkProjectRequestAddressForDuplicate) {
            urlParams["checkProjectRequestAddressForDuplicate"] = true;
        }

        $.ajax({
          url: `/api/request/addServiceRequestInfo?${new URLSearchParams(urlParams)}`,
          dataType: "JSON",
          method: "PUT",
          data: JSON.stringify(this.state.serviceRequestObject),
          contentType: "application/json; charset=utf-8",
          success: (result) => {
            this.setLastSubmittedData({...this.state.serviceRequestObject});

            this.setUpdateInProgress(false)
            this.saveIndicator(2)
            
            localStorage.setItem("selected_request_id", result.sruid);

            if(!!additionalSuccessFunction){
                additionalSuccessFunction();
            } 
          },
          error: (data)=>{
            this.setUpdateInProgress(false)
            if (data.status >= 500) {
                this.saveIndicator(3)
            } else if (data.status === 406) {
                toast.info("A Service Request already exists for this address. Please enter a different address.")
                this.saveIndicator(3)
            } else {
                this.saveIndicator(2)
            }
          }
        });
    }

    setSelectedConstructionWorkType(type) {
        this.setState({
            selectedConstructionWorkType: type,
        })
    }
    
    hideModal() {
        this.props.toggleShowCreateRequest(false, false);
    }

    nextPage() {
        this.setState({
            pageNumber: this.state.pageNumber+1,
        })
    }

    prevPage() {
        this.setState({
            pageNumber: this.state.pageNumber-1,
        })
    }
    
    submitToMaximo() {
        $.ajax({
            url: `/api/request/submitToQueue?selected_request_id=${getSelectedRequest()}`,
            method: "POST",
            success: (result) => {
                toast.success("Request Submitted");
            },
            error: (error) => {
                console.log(error);
                toast.error("Error Submitting Service Request");
            },
            complete: (test) => {
                this.hideModal();
            }
        });
        
    }

    /*
    0   no work type selected
    1   ---------inquire only (OBSOLETE)
    2	Gas Service Install
    3   ---------obsolete
    4	Gas Service Relocate
    5	Gas Main Relocate                     //skips EQUIP
    6	Gas Developer Subdivision            //skips EQUIP
    7	Electric Service Install
    8	Electric Light Install
    9	Electric Service Relocate
    10	Electric Light Retire
    11	Electric Primary Relocate            
    12	Electric Developer Subdivision
    13	Gas/Electric Subdivision Combo
    14  Gas/Electric Service Install Combo
    */
    getPageMap = () => {
        const sharedProps = {
            saveIndicator: this.saveIndicator,
            nextPage: this.nextPage,
            prevPage: this.prevPage,
            selectedWorkType: this.state.selectedConstructionWorkType,
            srObj: this.state.serviceRequestObject,
            replaceServiceRequestObject: this.replaceServiceRequestObject,
            updateServiceRequestObject: this.updateServiceRequestObject,
            saveSR: this.saveSR,
            saveSR_Now: this.saveSR_Now,
            updateInProgress: this.state.updateInProgress, //when true, disable previous/next buttons
        }
        
        const INFOPAGE= <InfoPage               nextPage={this.nextPage} />; 
        const SR=       <CreateServiceRequest   {...sharedProps} setOldLatitudeAndLongitude={this.setOldLatitudeAndLongitude} setSelectedConstructionWorkType={this.setSelectedConstructionWorkType} />; 
        const CUST=     <CreateCustomerRequest  {...sharedProps} />; 
        const ONSITE=   <CreateOnsiteRequest    {...sharedProps} />;
        const PROJECT=  <CreateProjectRequest   {...sharedProps} setOldLatitudeAndLongitude={this.setOldLatitudeAndLongitude} oldLongitude={this.state.oldLongitude} oldLatitude={this.state.oldLatitude} />;
        const EQUIP=    <CreateEquipmentRequest {...sharedProps} />;
        const REVIEW=   <Review                 {...sharedProps} submitToMaximo={this.submitToMaximo} viewOnly={this.props.viewOnly}/>;

        const pageMaps = [
            // [PROJECT, EQUIP, REVIEW], // Test
            [INFOPAGE, SR, CUST, ONSITE, PROJECT, EQUIP, REVIEW], // Original
            [INFOPAGE, SR, CUST, ONSITE, PROJECT, REVIEW],
        ]

        if(this.state.selectedConstructionWorkType && ["5","6","10"].includes(this.state.selectedConstructionWorkType)) { //exclude EQUIP page for work types 5,6,10
            return pageMaps[1];
        }else{
            return pageMaps[0];
        }
    }

    getViewOnlyPageMap = () => {
        const REVIEW=   <Review setSelectedConstructionWorkType={this.setSelectedConstructionWorkType} selectedWorkType={this.state.selectedConstructionWorkType} viewOnly={this.props.viewOnly} closeModal={this.hideModal}/>;
        const pageMaps = [
            [REVIEW],
        ]
        return pageMaps[0];
    }

    CreateRequestPageWrapper = ({children}) => (
        <div className="bg-light">
            <div className="container-fluid">
                <Row className="justify-content-center no-gutter overflowFix">
                    <div className="col-12">
                        <div className="d-flex align-items-center">
                            <div className="container mb-4">
                                {children}
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
        </div>
    )

    getServiceTypeDescription = () => {
        var serviceType = this.state.serviceRequestObject?.cusInfo?.serviceType;
        if(serviceType) {
            var option = this.state.serviceTypeOptions.find(option => option.value === serviceType);
            return option ? option.description : null;
        }
        return null;
    }

    render() {
        
        return (
            <React.Fragment>
                <Modal scrollable={true} dialogClassName="create-request-modal-width" show={this.props.show} onHide={() => this.props.toggleShowCreateRequest(false, false)}>
                    <Modal.Header closeButton>
                        <Container>
                            <Row>
                                <Col>
                                    <Modal.Title className="text-primary">Create { this.getServiceTypeDescription() } Request </Modal.Title>
                                </Col>
                            </Row>
                            {
                                this.state.showSaveIndicator && (
                                    <Row>
                                        <Col xs={12} sm={{span: 4, offset: 9}}>
                                            <div className="saveTimeStamp">
                                                {!!this.state.isSaving && (
                                                    <React.Fragment>
                                                        <FontAwesomeIcon icon={faSpinner} className="spin" /> 
                                                        &nbsp;Saving... 
                                                    </React.Fragment>
                                                )}
                                                {!this.state.isSaving && !this.state.saveErrorOccurred && (
                                                    <span>
                                                        <FontAwesomeIcon icon={faCircleCheck} />&nbsp;Saved {this.state.lastSaveTimeStamp}
                                                    </span>
                                                )}
                                                {this.state.saveErrorOccurred && (
                                                    <span style={{"color": 'red'}}>
                                                        <FontAwesomeIcon icon={faX} />&nbsp;Error Saving
                                                    </span>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                )
                            }
                        </Container>
                    </Modal.Header>
                    <Modal.Body className="noPadding">
                        {!!this.props.fadeModal && <div className="fade modal-backdrop show"></div>}
                        {!this.props.viewOnly && (
                            <this.CreateRequestPageWrapper>
                                {this.getPageMap()[this.state.pageNumber]}
                            </this.CreateRequestPageWrapper>
                        )}
                        {!!this.props.viewOnly && (
                            <this.CreateRequestPageWrapper>
                                {this.getViewOnlyPageMap()[0]}
                            </this.CreateRequestPageWrapper>
                        )}
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}

export default CreateRequestParent;