import React from 'react';
import { useAsyncDebounce } from 'react-table'
import SmartTextBox from '../../lib/SmartTextBox';
import { CloseButton } from 'react-bootstrap';
import { InputGroup, Form } from 'react-bootstrap';

function SearchBar({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <>
      <InputGroup className="mb-2" style={{ maxWidth: '325px' }} size="sm" >
        <InputGroup.Text>Search:</InputGroup.Text>
        <Form.Control
          value={value || ""}
          onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          aria-label="Enter search term" 
        />
        <InputGroup.Text>
          <CloseButton
            onClick={() => {
              setValue('');
              onChange('');
            }}
            aria-label="Clear Search"
          />
        </InputGroup.Text>
      </InputGroup>
    </>
  )
}

export { SearchBar };
