import { Button, Dropdown, ButtonGroup} from "react-bootstrap";
import { sortItemsIgnoreCase, formatStringAsDate ,sortItemsByDate } from "./SharedConstants";
const UnsubmittedColumns = (editSR, viewSR, deleteSR, dupSR) =>
  [
    {
      Header: "Work Type",
      className: "ellipsisOverflow",
      accessor: "workTypeDescription",
      accordionHeader: true,
      sortType: (prev, curr, columnId) => { return sortItemsIgnoreCase(prev, curr, columnId); },
    },
    {
      Header: "Date Created",
      className: "ellipsisOverflow",
      accessor: (item) => { return formatStringAsDate(item.createDate, false, false) },
      sortType: (prev, curr, columnId) => { return sortItemsByDate(prev, curr, columnId); },
    },
    {
      Header: "Desired Date",
      className: "ellipsisOverflow",
      accessor: (item) => { return formatStringAsDate(item.desiredDate, false, false) },
      sortType: (prev, curr, columnId) => { return sortItemsByDate(prev, curr, columnId); },
    },
    {
      Header: "Street Address",
      accordionHeader: true,
      className: "ellipsisOverflow",
      accessor: (item) => { return item && item.proInfo && item.proInfo.tkserviceaddress_streetAddress || '' },
      sortType: (prev, curr, columnId) => { return sortItemsIgnoreCase(prev, curr, columnId); },
    },
    {
      Header: "City",
      accordionHeader: true,
      className: "ellipsisOverflow",
      accessor: (item) => { return item && item.proInfo && item.proInfo.tkserviceaddress_city || '' },
      sortType: (prev, curr, columnId) => { return sortItemsIgnoreCase(prev, curr, columnId); },
    },
    {
      Header: "State",
      accordionHeader: true,
      className: "ellipsisOverflow",
      accessor: (item) => { return item && item.proInfo && item.proInfo.tkserviceaddress_stateProvince || '' },
      sortType: (prev, curr, columnId) => { return sortItemsIgnoreCase(prev, curr, columnId); },
    },
    {
      Header: "Notes",
      accessor: "description",
      colWidth: '30%',
      sortType: (prev, curr, columnId) => { return sortItemsIgnoreCase(prev, curr, columnId); },
      Cell: (cell) => (
        <p className="div-overflow-fix">{cell.value}</p>
      ),
    },
    // {
    //   Header: "Date Updated",
    //   className: "ellipsisOverflow",
    //   accordionHeader: true,
    //   accessor: (item) => { return formatStringAsDate(item.updateDate, true, false) },
    // },
    {
      Header: "Actions",
      accessor: (item) => { return item.sruid },
      colWidth: '150px',
      disableSortBy: true,
      Cell: (item) => (
        <Dropdown
          as={ButtonGroup} 
          style={{width: '100%'}}
          drop="down"
        >
          <ButtonGroup style={{width: '100%'}}>
            <Button variant="outline-primary" size="sm" onClick={() => viewSR(item.value)} >
              View
            </Button>
            <Button variant="outline-primary" size="sm" onClick={() => editSR(item.value)}>
              Edit
            </Button>
          </ButtonGroup>
          <Dropdown.Toggle split variant="outline-primary" size="sm"/>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => dupSR(item.value)}> 
              <Button variant="outline-primary" size="sm" style={{width: '100%'}}>
                Copy
              </Button>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => deleteSR(item.value)} > 
              <Button variant="danger" size="sm" style={{width: '100%'}}>
                Delete
              </Button>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )
    },
  ];

export default UnsubmittedColumns;