import { useEffect, useState } from "react"
import $ from "jquery";

export default function LoggedInUserName(props) {
    const [userName, setUserName] = useState('');

    useEffect(() => {
        if(!userName) {
            $.ajax({
                url: "/api/user/user",
                dataType: "json",
                method: "GET",
                success: (result) => {
                    if(!!result && !!result.name) {
                        setUserName(result.name);
                    } 
                },
            });
        }
    }, [])

    if (!!userName) return (
        <span className="dashboard-header-name">Welcome <strong>{userName}</strong></span>
    )
    
    return <></>
}