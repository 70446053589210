import React from "react";
// import { Container, Row, Col, Button } from "react-bootstrap";

class PasswordStrengthMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classText: '',
      message: '',
      passwordStrength: this.props.passwordStrength,
    };
    // this.componentDidMount();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.passwordStrength !== this.props.passwordStrength) {
      this.setState({passwordStrength: this.props.passwordStrength});
      this.init();
    }
  }

  init() {
    const classDanger = 'text-danger pull-right';
    const classWarning = 'text-warning pull-right';
    const classSuccess = 'text-success pull-right';

    switch (this.props.passwordStrength) {
      case false:
        this.setState({
          message: "",
          class: classDanger,
        });
        return;
      case 0:
        this.setState({
          message: "Very Weak",
          class: classDanger,
        });
        break;
      case 1:
        this.setState({
          message: "Weak",
          class: classDanger,
        });
        break;
      case 2:
        this.setState({
          message: "Medium-Weak",
          class: classWarning,
        });
        break;
      case 3:
        this.setState({
          message: "Medium",
          class: classWarning,
        });
        break;
      case 4:
        this.setState({
          message: "Strong",
          class: classSuccess,
        });
        break;
      default:
        this.setState({
          message: "Very Strong",
          class: classSuccess,
        });
        break;
    }
  }
  render() {
    // this.init();

    return (
      <span key={this.props.passwordStrength} className={this.state.class}>
        {this.state.message}
      </span>
    );
  }
}


export default PasswordStrengthMessage