import React from "react";
import SmartTextBox from "../lib/SmartTextBox";
import { getLink, translatedURL } from "../../util/functions";
import CustomLink from '../lib/Link'
import FormAutoCSRF from "../lib/FormAutoCSRF";
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "loginPage",
      email: "",
      password: "",
      redirect: false,
      location: {},
      visibility: false,
      isLoading: false,
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePwdChange = this.handlePwdChange.bind(this);
  }

  handleEmailChange = (event) => {
    localStorage.setItem("userEmail", event.target.value);//for use by mfa page
    this.setState({ email: event.target.value })
    this.props.clearError();
  };

  handlePwdChange = (event) => {
    this.setState({ password: event.target.value })
    this.props.clearError();
  };

  componentDidMount() {
    this.props.setPageHeaders("", "");
    localStorage.removeItem("userEmail")
  }

  componentWillUnmount() {
    this.props.clearError();
    this.props.clearSuccess();
  }
  render() {
    return (
      <>
        <FormAutoCSRF method="POST" action={translatedURL("/login")} className="row mt-4 needs-validation" noValidate>
          <SmartTextBox
            id="email"
            className="mb-3"
            label="Email"
            name="email"
            placeholder="Email"
            type="email"
            value={this.state.email}
            onChange={this.handleEmailChange}
            invalidFeedback="Please provide a valid Email."
            required
            autoFocus
          />
          <SmartTextBox
            id="password"
            className="mb-3"
            label="Password"
            name="password"
            type="password"
            placeholder="Password"
            value={this.state.password}
            onChange={this.handlePwdChange}
            invalidFeedback="Please provide a valid Password."
            required
          />
          <div className="mt-2 clearfix text-end">
            <button
              type="submit"
              disabled={this.state.isLoading}
              className="btn btn-warning btn-block text-uppercase shadow-sm"
            >
              Sign in
            </button>
            <CustomLink href="/register" className="float-start">
              <button type="button" className="btn btn-primary btn-block text-uppercase shadow-sm">
                Register
              </button>
            </CustomLink>
          </div>
        </FormAutoCSRF>
        <div className="mt-2 text-center">
          <a href={translatedURL("/forgot-password")}>
            Forgot my Password
          </a>
        </div>
        <div className="mt-2 row">
          {getLink("privacy-policy") &&
            <div className="col-md-6 text-center">
              <a href={getLink("privacy-policy")} target="_blank">Privacy Policy</a>
            </div>
          }
          <div className={getLink("privacy-policy") ? "col-md-6 text-center" : "col-md-12 text-center"}>
            <a href={getLink("legal-statement")} target="_blank">Legal Statements</a>
          </div>
        </div>
      </>
    );
  }
}

export default Login;