import React from "react";
import { Row } from "react-bootstrap";

/*

Use this component to group up other components in form. Each SmartSection will wrap child components
in a box containing a title of a given value. This component can be hidden or shown based on a
given workTypeShowList, which will show/hide every every child component inside the SmartSection.

    required:
        title
    optional:
        workTypeShowList
        workType (required if workTypeShowList is used)

Example usage:

    <SmartSection
        id="customerInformationSection"
        title="Customer Information"
    >
        <SomeOtherComponent />
        <SomeOtherComponent />
        <SomeOtherComponent />
        <SomeOtherComponent />
    </SmartSection>

Example usage using workTypeShowList:

    <SmartSection
        id="customerInformationSection"
        title="Customer Information"
        workTypeShowList={[4, 6, 12, 13, 14]}
        workType={this.props.selectedWorkType}
    >
        <SomeOtherComponent />
        <SomeOtherComponent />
        <SomeOtherComponent />
        <SomeOtherComponent />
    </SmartSection>

*/

class SmartSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.generateId(),
        }
    }

    generateId() {
        const length = 15;
        const prefix = 'smartSection_';
        let uniqueId = prefix + Math.random().toString(36).substr(2, length);
        return uniqueId;
    }

    checkWorkTypeShowList = () => {
        if (!this.props.workType) return false; //list given but no work type
        try {
            return this.props.workTypeShowList.includes(Number(this.props.workType));
        } catch (e) {
            return false;
        }
    }

    checkMultipleShowLists = () => {
        if (!this.props.multipleDeciders) return false; //list given but no deciders

        let fulfillsAllParameters = true;
        for (let i = 0; i < this.props.multipleShowLists.length; i++) {
            if (!this.props.multipleShowLists[i].includes(this.props.multipleDeciders[i])) {
                fulfillsAllParameters = false;
            }
        }

        return fulfillsAllParameters;
    }

    render() {

        const show = () => {
            let show = true;
            if (this.props.alwaysShow) {
                show = true;
            } else if (this.props.multipleShowLists && this.props.workTypeShowList) {
                show = (this.checkWorkTypeShowList() && this.checkMultipleShowLists());
            } else if (this.props.workTypeShowList) {
                show = this.checkWorkTypeShowList();
            } else if (this.props.multipleShowLists) {
                show = this.checkMultipleShowLists();
            }
            
            return show; //if no params are passed in, always show
        }

        return (
            <>
                { show() &&
                    <fieldset className ="border p-2 mb-3">
                        <legend className="float-none w-auto">{this.props.title}</legend>
                        <Row>
                            {this.props.children}
                        </Row>
                    </fieldset>
                }
            </>
        )
    }
}

export default SmartSection