/**
 * Create <a href /> links.
 * Utilizes the bootstrap "nav.link" component
 * The main purpose of this is to translate links based on the process.env.PUBLIC_URL. This makes it so that the CSR/Sim5000 version of CSSP can be based at /cssp-sim5000 or something instead of just "/"
 */
import { translatedURL } from "../../util/functions";

import {Nav} from "react-bootstrap";
const Link = Nav.Link;


export default function CustomLink({href, children, ...props}) {
    return (
        <Link href={translatedURL(href)} {...props}>
            {children}
        </Link>
    )
}