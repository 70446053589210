import { useEffect, useState } from "react"
import $ from "jquery";

export default function LoggedInUserCompanyName(props) {
    const [companyName, setCompanyName] = useState('');

    useEffect(() => {
        if(!companyName) {
            $.ajax({
                url: "/api/user/profile",
                dataType: "json",
                method: "GET",
                success: (result) => {
                    if(!!result && !!result.companyName) {
                        setCompanyName(result.companyName);
                    } 
                },
            });
        }
        
    }, [])

    if (!!companyName) return (
        <span>Logged in as a member of: <br /> <strong>{companyName}</strong></span>
    )
    
    return <></>
}