import { Modal } from "react-bootstrap";
import EditProfile from "./EditProfile";

const ProfileModal = ({ setDisplayingModal, show }) => {
  return (
    <Modal
      size="lg"
      show={show}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <div>
          <h4>Welcome to the Construction Self Service Portal!</h4>
          <h5>Please enter the following information:</h5>
        </div>
      </Modal.Header>
      <Modal.Body>
        <EditProfile
          showModal={() => setDisplayingModal()}
          displayedInModal={true}
        />
      </Modal.Body>
    </Modal>
  )
}

export default ProfileModal