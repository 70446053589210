import React from "react";
import { Alert } from "react-bootstrap";
function CustomAlert(props) {
  return (
    <Alert key={props.variant} variant={props.variant}>
        {props.message}
    </Alert>
  );
}

export default CustomAlert;