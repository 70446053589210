import React from "react";
import SmartTextBox from "../lib/SmartTextBox";
import SmartDropDownList from "../lib/SmartDropDownList";
import SmartRadioButtons from "../lib/SmartRadioButtons";
import SmartFile from "../lib/SmartFile";
import $ from "jquery";
import { getSelectedRequest } from "../../util/functions";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import { Row, Badge } from "react-bootstrap";
import endpoint from "../../util/endpoint";
import _ from 'lodash'

class CreateEquipmentRequest extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      isLoading: false,
    };
  }

  handleInputChange = (event, options = {name: null, value: null }, runServerSave = true) => {
    let name = options.name == null ? event.target.name : options.name;
    let value = options.value == null ? event.target.value : options.value;

    if(runServerSave){
      this.props.updateServiceRequestObject(name, value);
      this.props.saveSR();
    }else{
      this.props.updateServiceRequestObject(name, value);
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true })
    $.ajax({
      url: `/api/request/getActiveSR?selected_request_id=${getSelectedRequest()}`,
      dataType: "json",
      method: "GET",
      success: (result) => {
        this.props.replaceServiceRequestObject(result);
        this.setState({
          isLoading: false,
        });
      },
      error: () => {
        this.setState({ isLoading: false })
      }
    });
  }

  render() {
    const nextOptions = (event) => {
      event.preventDefault();

      if (!this.formRef.current.checkValidity()) {
        this.formRef.current.classList.add('was-validated');
        return false
      }
      
      this.props.saveSR_Now(this.props.nextPage);
    };

    const getValue = (path) => {
      return _.get(this.props.srObj, path)
    }
    
    return (
      <form ref={this.formRef} onSubmit={nextOptions} className="needs-validation" noValidate>
        <div className="col-12">
          <h3 className="text-center text-primary mt-3">
            Equipment Request Information
          </h3>
          {this.state.isLoading ? <SkeletonLoader count={11} width="100px" /> :
            <Row>
              <SmartDropDownList
                label="Requested Delivery Pressure"
                value={getValue("equipmentInfo.meterPressure")}
                onChange={(event, runServerSave=true) => this.handleInputChange(event, {name: "equipmentInfo.meterPressure"}, runServerSave)}
                invalidFeedback="Please provide a Requested Delivery Pressure."
                fetchURL={endpoint.maximoMDUGMETERPRESSURE}
                defaultText='Select Requested Delivery Pressure'
                workTypeShowList={[2, 3, 4, 14]}
                workType={this.props.selectedWorkType}
                defaultValue="STANDARD"
                required
              />
              <SmartRadioButtons
                label="Is Gas the Primary Source of Heat?"
                valueList={["YES", "NO"]}
                valueLabelList={["Yes", "No"]}
                selectedValue={getValue("equipmentInfo.gasOnlySource")}
                onChange={(event) => this.handleInputChange(event, {name: "equipmentInfo.gasOnlySource"})}
                invalidFeedback="Please provide a valid Is Gas the Primary Source of Heat."
                workTypeShowList={[2, 3, 4, 14]}
                workType={this.props.selectedWorkType}
              />
              <SmartTextBox
                label="Other Source"
                maxLength="255"
                value={getValue("equipmentInfo.otherHeatSource")}
                onChange={(event) => this.handleInputChange(event, {name: "equipmentInfo.otherHeatSource"})}
                invalidFeedback="Please provide a valid Other Source Text."
                multipleShowLists={[["NO"]]}
                multipleDeciders={[getValue("equipmentInfo.gasOnlySource")]}
              />
              <SmartDropDownList
                label="Converting from fuel type"
                value={getValue("equipmentInfo.fromFuelType")}
                onChange={(event) => this.handleInputChange(event, {name: "equipmentInfo.fromFuelType"})}
                invalidFeedback="Please provide a Converting from fuel type."
                fetchURL={endpoint.maximoMDUGFUELTYPE}
                defaultText='Select Converting from fuel type'
                workTypeShowList={[2, 14]}
                workType={this.props.selectedWorkType}
                multipleShowLists={[["EXISTINGCOMMERCIAL", "EXISTINGRESIDENTIAL"]]}
                multipleDeciders={[getValue('cusInfo.serviceType')]}
                required
              />
              <SmartTextBox
                label="Other Fuel Type"
                maxLength="255"
                value={getValue("equipmentInfo.otherFromFuelType")}
                onChange={(event) => this.handleInputChange(event, {name: "equipmentInfo.otherFromFuelType"})}
                invalidFeedback="Please provide a valid other fuel type."
                multipleShowLists={[["OTHER"]]} //other fuel type
                multipleDeciders={[getValue("equipmentInfo.fromFuelType")]}
                required
              />
              <SmartRadioButtons
                label="Standby/Backup Generation"
                valueList={["YES", "NO"]}
                valueLabelList={["Yes", "No"]}
                selectedValue={getValue("equipmentInfo.standByGen")}
                onChange={(event) => this.handleInputChange(event, {name: "equipmentInfo.standByGen"})}
                invalidFeedback="Please provide a valid Standby/Backup Generation."
                workTypeShowList={[12, 13]}
                workType={this.props.selectedWorkType}
              />
              <SmartTextBox
                label="Wiring Certificate associated with request"
                maxLength="255"
                value={getValue("equipmentInfo.wiringCertId")}
                onChange={(event) => this.handleInputChange(event, {name: "equipmentInfo.wiringCertId"})}
                invalidFeedback="Please provide a valid Wiring Certificate associated with request."
                workTypeShowList={[7, 9, 11, 14]}
                workType={this.props.selectedWorkType}
              />
              <SmartTextBox
                label="Main Electric Rating"
                maxLength="255"
                value={getValue("equipmentInfo.mainRating")}
                onChange={(event) => this.handleInputChange(event, {name: "equipmentInfo.mainRating"})}
                invalidFeedback="Please provide a valid Main Electric Rating."
                workTypeShowList={[12, 13]}
                workType={this.props.selectedWorkType}
                required={this.props.selectedWorkType == "12" ? undefined : true}
              />
              <SmartTextBox
                label="Voltage/Phases"
                maxLength="255"
                value={getValue("equipmentInfo.voltagePhase")}
                onChange={(event) => this.handleInputChange(event, {name: "equipmentInfo.voltagePhase"})}
                invalidFeedback="Please provide valid Voltage/Phases value."
                workTypeShowList={[12, 13]}
                workType={this.props.selectedWorkType}
                required={this.props.selectedWorkType == "12" ? undefined : true}
              />
              <SmartRadioButtons
                label="Distributed Generation"
                valueList={["YES", "NO"]}
                valueLabelList={["Yes", "No"]}
                selectedValue={getValue("equipmentInfo.distributedGen")}
                onChange={(event) => this.handleInputChange(event, {name: "equipmentInfo.distributedGen"})}
                invalidFeedback="Please provide a valid Distributed Generation."
                workTypeShowList={[8, 9, 12, 13]}
                workType={this.props.selectedWorkType}
                required
              />
              <SmartRadioButtons
                label="Street lighting required?"
                valueList={["YES", "NO"]}
                valueLabelList={["Yes", "No"]}
                selectedValue={getValue("equipmentInfo.lightingRequired")}
                onChange={(event) => this.handleInputChange(event, {name: "equipmentInfo.lightingRequired"})}
                invalidFeedback="Please provide a valid Street lighting required? (provide plan)."
                workTypeShowList={[12, 13]}
                workType={this.props.selectedWorkType}
              />
              <SmartDropDownList
                label="Type of lighting"
                value={getValue("equipmentInfo.lightingType")}
                onChange={(event) => this.handleInputChange(event, {name: "equipmentInfo.lightingType"})}
                invalidFeedback="Please provide a Type of lighting."
                fetchURL={"/api/dropdownlist/maximo/MDUGLIGHTINGTYPE"}
                defaultText='Select Type of lighting'
                required
                multipleShowLists={[["YES"]]}
                multipleDeciders={[getValue("equipmentInfo.lightingRequired")]}
              />
              <SmartTextBox
                label="Estimated quantity of lights"
                type="int"
                maxLength="12"
                value={getValue("equipmentInfo.numStreetLights")}
                onChange={(event) => this.handleInputChange(event, {name: "equipmentInfo.numStreetLights"})}
                invalidFeedback="Please provide a valid Estimated quantity of lights."
                required
                multipleShowLists={[["YES"]]}
                multipleDeciders={[getValue("equipmentInfo.lightingRequired")]}
              />
              <SmartFile
                label="Street Light Plan"
                id="streetLightPlan"
                invalidFeedback="Please provide a valid Lot/Site Drawing (map) attachment."
                attachmentList={getValue("attachment")}
                maximoFileType="Street_Light_Plan"
                workTypeShowList={[12, 13]}
                workType={this.props.selectedWorkType}
                multipleShowLists={[["YES"]]}
                multipleDeciders={[getValue("equipmentInfo.lightingRequired")]}
              />

              <Row>
                <div className="col-12">
                  <div className="text-center">
                    <button
                      disabled={this.props.updateInProgress}
                      type="button"
                      onClick={() => this.props.prevPage()}
                      className="btn btn-secondary btn-block text-uppercase mb-2 shadow-sm btn-sm me-2"
                    >
                      Previous
                    </button>
                    <button
                      disabled={this.props.updateInProgress}
                      type="submit"
                      className="btn btn-primary btn-block text-uppercase mb-2 shadow-sm btn-sm"
                    >
                      Next
                    </button>
                  </div>
                  {this.props.updateInProgress && <div className="text-center"><Badge bg="primary" >Saving in progress... </Badge></div>}
                </div>
              </Row>
            </Row>
          }
        </div>
      </form>
    );
  }
}

export default CreateEquipmentRequest;