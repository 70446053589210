import Cookies from "js-cookie"
/**
 * CSRF is set up to protect us from XSS attacks.
 * This will automatically add a hidden csrf token to Forms.
 * Used by Login and MFA pages. 
 */
export default function FormAutoCSRF({children, ...props}) {
    return (
        <form {...props}>
            <input type="hidden" name={process.env.REACT_APP_CSRF_TOKEN_NAME} value={Cookies.get(process.env.REACT_APP_CSRF_TOKEN_NAME)} />
            {children}
        </form>
    )
} 