import React from "react";
import { Popover, OverlayTrigger } from "react-bootstrap"; 
/** 
managed props (all unmanaged props (such as any that are usually used by Input component such as "value", "onChange", "maxLength", etc.) will just be passed through to the input element):
    required: 
        label
        value
    optional:
        workTypeShowList, 
        workType
        alwaysShow //will default to true if there is no workTypeShowList or workType
    advanced: //for when you have multiple parameters for when things are
        multipleShowLists (type String[][]) //NOTE: make sure types match. The worktype part this parses to numbers
        multipleDeciders (type String[])

Example:
    <SmartLabel 
        label="Requester Phone Type"
        value={this.state.requesterPhoneType}
        workTypeShowList={[5, 6, 7, 8, 9, 10, 11, 12, 13]}
        workType={selectedConstructWorkType}
    />

Example with multiple show lists: (this will show up if this.state.property = Renter or Other, and this.state.YesOrNoSelection = Yes)
    <SmartLabel 
        label="Owner Name"
        value={this.state.ownerName}
        workTypeShowList={[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]}
        workType={selectedConstructWorkType}
        multipleShowLists={[["Renter", "Other"], ["Yes"]]}
        multipleDeciders={[this.state.property, this.state.YesOrNoSelection]}
    />
*/

class SmartLabel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.generateId(),
        }
    }

    generateId() {
        const length = 15;
        const prefix = 'smartLabel_';
        let uniqueId = prefix + Math.random().toString(36).substr(2, length);
        return uniqueId;
    }

    render() {
        const checkWorkTypeShowList = () => {
            if (!this.props.workType) return false; //list given but no work type
            try {
                return this.props.workTypeShowList.includes(Number(this.props.workType));
            } catch (e) {
                return false;
            }
        }

        const checkMultipleShowLists = () => {
            if (!this.props.multipleDeciders) return false; //list given but no deciders

            let fulfillsAllParameters = true;
            for (let i = 0; i < this.props.multipleShowLists.length; i++) {
                if (!this.props.multipleShowLists[i].includes(this.props.multipleDeciders[i])) {
                    fulfillsAllParameters = false;
                }
            }

            return fulfillsAllParameters;
        }

        const show = () => {
            if (this.props.alwaysShow) {
                return true;
            } else if (this.props.multipleShowLists && this.props.workTypeShowList) {
                return (checkWorkTypeShowList() && checkMultipleShowLists());
            } else if (this.props.workTypeShowList) {
                return checkWorkTypeShowList();
            } else if (this.props.multipleShowLists) {
                return checkMultipleShowLists();
            }

            return true; //if no params are passed in, always show
        }

        let cName = this.props.className || "col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3"
       
        var {formatValueFunction, className, value, id, label, workTypeShowList, workType, alwaysShow, multipleShowLists, multipleDeciders, ...unmanagedProps } = this.props;

       
        return (
            <React.Fragment>
                {show() &&
                    <div className={cName} {...unmanagedProps}>
                        <label
                            className="form-label"
                            style={{maxWidth: '100%'}}
                        >
                            {this.props.label}: <br /> <span className="label-overflow-fix"><b>{!!this.props.formatValueFunction ? this.props.formatValueFunction(this.props.value) : this.props.value}</b></span>
                        </label>
                    </div>
                }
            </React.Fragment>
        )
    }
}

export default SmartLabel