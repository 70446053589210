import React from "react";
import { Button, Col, Row, Card, Container } from "react-bootstrap";
import $ from "jquery";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import {UserIcon, CompanyProfileIcon, HomeownerProfileIcon} from './ProfileIcons';

import { toast } from "react-toastify";
import SubuserManagement from "./SubuserManagement";
import CustomLink from '../lib/Link'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "profilePage",
      profileData: {},
      userData: {},
      deleteId: "",
      redirect: false,
      authError: false,
      isLoading: true,
      location: {},
    };
  }

  componentDidMount() {
    $.ajax({
      url: "/api/user/profileAndUser",
      dataType: "json",
      method: "GET",
      success: (result) => {
        this.setState({
          profileData: result.profile,
          userData: result.user,
          isLoading: false,
        });
      },
    });

    const urlParams = new URLSearchParams(window.location.search);
    const passwordChanged = urlParams.get('passwordChanged');
    if (passwordChanged == "success") {
      toast.dismiss()
      toast.success("Password updated successfully");
      window.history.replaceState({}, "", "/profile")
    }
  }

  render() {
    return (
      <div>
        <div className="maincontainer">
          <div className="container-fluid">
            <div className="row justify-content-center no-gutter">
              <div className="col-12  mb-4">
                <div className="d-flex align-items-center">
                  <Container className="py-3">
                    <Row>
                      <Col xxl={12}>
                        <CustomLink className="profileDashboardButton" href={`/dashboard`}>
                          <Button variant="primary" size="sm">
                          <FontAwesomeIcon icon={faLeftLong} /> Return To Dashboard
                          </Button>
                        </CustomLink>
                      </Col>
                    </Row>
                    <Row className="g-4">
                      <Col lg={12} xl={6}>
                        <Card >
                          <Card.Header className="bg-primary border-success text-light">
                            User Details
                          </Card.Header>
                          <Card.Body>
                            {this.state.isLoading ? <SkeletonLoader count={11} width='100px' /> :
                              <Row>
                                <Col xs="4">
                                  <UserIcon height={80} />
                                </Col>
                                <Col xs="8">
                                  <p>
                                    Name: <b>{this.state.userData.name}</b>
                                  </p>
                                  <p>
                                    Email: <b>{this.state.userData.email}</b>
                                  </p>
                                  <p>
                                    Phone: <b>{this.state.userData.phone}</b>
                                  </p>
                                </Col>
                              </Row>
                            }
                          </Card.Body>
                          <Card.Footer className="bg-gray border-success">
                            <CustomLink className="profileButton" href={`/editUser`}>
                              <Button variant="primary" size="sm">
                                Edit
                              </Button>
                            </CustomLink>
                            <CustomLink className="profileButton" href={`/changePassword`}>
                              <Button variant="primary" size="sm">
                                Change Password
                              </Button>
                            </CustomLink>
                          </Card.Footer>
                        </Card>
                      </Col>
                      <Col lg={12} xl={6}>
                        <Card>
                          <Card.Header className="bg-primary border-success text-light">
                            Profile Details
                          </Card.Header>
                          <Card.Body>
                            {this.state.isLoading ? <SkeletonLoader count={11} width='100px' /> :
                              <Row>
                                <Col xs="4">
                                  {!!this.state.profileData.builder && <CompanyProfileIcon height={80}/>}
                                  {!this.state.profileData.builder && <HomeownerProfileIcon height={80}/>}
                                </Col>
                                <Col xs="8">

                                  {this.state.profileData.builder &&
                                    <>
                                      <p>
                                        Company Name: <b>{this.state.profileData.companyName}</b>
                                      </p>
                                      <p>
                                        Company Phone: <b>{this.state.profileData.companyPhone}</b>
                                      </p>
                                      <p>
                                        Company Email: <b>{this.state.profileData.companyEmail}</b>
                                      </p>
                                    </>
                                  }

                                  <h6 className="pt-2 border-bottom">Mailing Address</h6>
                                  <p>
                                    Street Address: <b>{this.state.profileData.streetAddress}</b>
                                  </p>
                                  <p>
                                    City: <b>{this.state.profileData.city}</b>
                                  </p>
                                  <p>
                                    State: <b>{this.state.profileData.state}</b>
                                  </p>
                                  <p>
                                    Zip Code: <b>{this.state.profileData.zipCode}</b>
                                  </p>
                                  <h6 className="pt-2 border-bottom">Billing Address</h6>
                                  <p>
                                    Street Address: <b>{this.state.profileData.billingStreetAddress}</b>
                                  </p>
                                  <p>
                                    City: <b>{this.state.profileData.billingCity}</b>
                                  </p>
                                  <p>
                                    State: <b>{this.state.profileData.billingState}</b>
                                  </p>
                                  <p>
                                    Zip Code: <b>{this.state.profileData.billingZipCode}</b>
                                  </p>
                                </Col>
                              </Row>
                            }
                          </Card.Body>
                          {
                            this.state.userData.isPrimaryUser &&
                            <Card.Footer className="bg-gray border-success">
                              <CustomLink className="profileButton" href={`/editProfile`}>
                                <Button variant="primary" size="sm">
                                  Edit
                                </Button>
                              </CustomLink>
                            </Card.Footer>
                          }
                        </Card>
                      </ Col>
                      {this.state.userData && this.state.userData.isPrimaryUser
                        && this.state.profileData && this.state.profileData.builder && this.state.profileData.builder.builderId &&
                        (
                          <Col xxl={12}>
                            <Card >
                              <Card.Header className="bg-primary border-success text-light">
                                User Management
                              </Card.Header>
                              <Card.Body>
                                <SubuserManagement builderData={this.state.profileData.builder} />
                              </Card.Body>
                            </Card>
                          </Col>
                        )
                      }
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default Profile;