import { Placeholder } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function SkeletonLoader(props) {
    
    const { count } = props

    return(
        <>
            <Skeleton count={count}  />
        </>
    )
}