import React from "react";
import $ from "jquery";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { getBrand, translatedURL } from "../../util/functions";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "forgotPasswordPage",
      email: "",
      emailSent: false,
    };
  }

  componentDidMount() {
    this.props.setPageHeaders("Password Reset", "Construction Self Service Portal");
  }

  render() {
    const handleEmailChange = (event) => {
      this.setState({ email: event.target.value });
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      this.props.loading(true);
      //send email with link to user.
      $.ajax({
        url: "/api/forgot-password/send-email",
        dataType: "html",
        method: "POST",
        data: this.state.email,
        contentType: "text/plain; charset=utf-8",
      }).always((result) => {
        this.props.forgotPasswordEmailSent();
      });
    };

    return (
      <div>
        <p >
          Please enter the email associated with your account.
        </p>

        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <input
              type="email"
              placeholder="Email"
              required
              onChange={handleEmailChange}
              className="form-control shadow-sm px-4 text-primary"
            />
            <div className="invalid-feedback">
              Please provide a valid Email.
            </div>
          </div>

          <div className="mt-2 clearfix">

            <button
              type="submit"
              className="btn btn-success btn-block text-uppercase mb-2 shadow-sm w-100 float-end"
            >
              Send Email{'\u00A0'}
              <FontAwesomeIcon icon={faPaperPlane} />
            </button>
          </div>

          <div className="mt-2 text-center">
            <a href={translatedURL("/login")}>
              Return to Login
            </a>
          </div>
        </form>
      </div>
    );
  }
}

export default ForgotPassword;