import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import LoginHeader from "./LoginHeader";
import Footer from "../footer/Footer";
import { getBrand } from "../../util/functions";

import CustomAlert from "../lib/Alert";
import LoadingSpinner from "../lib/LoadingSpinner";



export default function LoginParent({Child, ...props}) {
  const [theme, setTheme] = useState(getBrand());
  const [successMessage, showSuccess] = useState("");
  const [errorMessage, showError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [header, setHeader] = useState("");
  const [subHeader, setSubHeader] = useState("");

  const setPageHeaders = (pHeaderText, pSubHeaderText) => {
    setHeader(pHeaderText);
    setSubHeader(pSubHeaderText);
  }
  
  const clearError = () => { 
    showError(""); 
  }

  const clearSuccess = () => { 
    showSuccess(""); 
  }

  useEffect(() => {
    //error handling from url error params:
    const errorCodeToMessageMapping = {
      "InvalidLogin": "Username or Password is Invalid.",
      "inactive": "You have been logged out due to inactivity.",
      "mfa-1": "Code Submission Failed. Please try again later.",
      "mfa-2": "Entered code has expired. Please request a new code.",
      "mfa-3": "No security code exists for user. Please request a code before trying to verify.",
      "mfa1": "Code Incorrect. Please try again",
      "mfa2": "Code Incorrect. Please try again",
      "mfa3": "Too many failed attempts. Please request a new MFA code",
      "already_exists": "There is already an account that exists for your email. Please log in or select \"Forgot my Password\" if you have forgotten you password.",
      "unexpected": "An unexpected error has occurred. Please try again later.",
    }

    const successCodeToMessageMapping = {
      "registered": "Account created successfully! Please log in with your email and password.",
      "logout": "You have been successfully logged out.",
    }
    
    const errorCode = new URLSearchParams(window.location.search).get("error");
    const eMessage = !!errorCodeToMessageMapping[errorCode] ? errorCodeToMessageMapping[errorCode] : "";
    showError(eMessage);

    const successCode = new URLSearchParams(window.location.search).get("success");
    const sMessage = !!successCodeToMessageMapping[successCode] ? successCodeToMessageMapping[successCode] : "";
    showSuccess(sMessage);
  }, [])
 
  const propsToPassToChildren = {
    showError: showError,
    showSuccess: showSuccess,
    clearError: clearError,
    clearSuccess: clearSuccess,
    setPageHeaders: setPageHeaders,
    isLoading: isLoading,
  }

  return (
    <div className={theme}>
      <LoginHeader />
      <div className="maincontainer">
        <Container className="p-2">
          <Row>
            <Col 
              xl={{ span: 4, offset: 4 }} 
              md={{ span: 6, offset: 3 }} 
              sm={{ span: 10, offset: 1 }} 
              xs={12}
              className="well well-form p-4"
            >
              {!!errorMessage && (
                <CustomAlert
                  variant="danger"
                  message={errorMessage}
                />
              )}
              {!!successMessage && (
                <CustomAlert
                  variant="success"
                  message={successMessage}
                />
              )}
              {isLoading && <LoadingSpinner show={isLoading} />}
              {!isLoading &&
                <>
                  <div>
                    <h4>{header}</h4>
                    <p className="mb-1">
                      {subHeader}
                    </p>
                  </div>
                  <Child
                    {...propsToPassToChildren}
                  />
                </>
              }
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
}