import React from "react";

import { useTable, useSortBy, useGlobalFilter } from 'react-table'
import { Table, Row, Col, Accordion, Container } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'

import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import { SearchBar } from "../dashboard/TableComponents/SearchBar";

export default function UserTables({ columns, data, isLoading }) {
  const tableStyle = {};

  const defaultSort = React.useMemo(
    () => [
      {
        id: "Primary User",
        desc: true
      },
      {
        id: "Email",
        desc: false
      }
    ],
    []
  );

  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { globalFilter },

  } = useTable(
    {
      columns: columns,
      data: data,
      autoResetSortBy: false,
      autoResetGlobalFilter: false,
      initialState: {
        sortBy: defaultSort,
      }
    },
    useGlobalFilter,
    useSortBy,
  );

  return (
    <>
      <Col xs={{ span: 12 }} sm={{ span: 9 }} lg={10} xxl={11}>
        <SearchBar
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </Col>
      <Col xs={12}>
        <Table
          {...getTableProps()}
          striped
          bordered
          hover
          responsive
          style={{ tableLayout: 'fixed', ...tableStyle }}
          className="d-none d-md-table"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, i1) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={i1}
                  >
                    <span className="float-start">
                      {column.render("Header")}
                    </span>
                    {!column.disableSortBy &&
                      <span className="float-end" style={{ width: '1em' }}>
                        {
                          <>
                            {/* comment if you'd like to not show which columns are sortable */}
                            {!column.isSorted && <FontAwesomeIcon icon={faSort} />}
                            {column.isSorted && column.isSortedDesc && <FontAwesomeIcon icon={faSortDown} />}
                            {column.isSorted && !column.isSortedDesc && <FontAwesomeIcon icon={faSortUp} />}
                          </>
                        }
                      </span>
                    }
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {!!isLoading && (
              <tr>
                <td colSpan={columns.length} style={{ textAlign: 'center' }} > <SkeletonLoader count={4} height={40} /> </td>
              </tr>
            )}
            {!isLoading && (
              <>
                {(!rows || rows.length === 0) && (
                  <tr>
                    <td colSpan={columns.length}>No Results</td>
                  </tr>
                )}
                {(rows && rows.length > 0) && rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} key={i}>
                      {row.cells.map((cell, x) => {
                        return <td {...cell.getCellProps()} className={cell.column.className || undefined} key={x}>{cell.render("Cell")}</td>;
                      })}
                    </tr>
                  );
                })}
              </>
            )}
          </tbody>
        </Table>
        <Accordion {...getTableProps()} className="d-inline d-md-none">
          {(!rows || rows.length === 0) && (
            <p>No Results</p>
          )}
          {(rows && rows.length > 0) && rows.map((row, i) => {
            prepareRow(row);
            return (
              <Accordion.Item eventKey={i} key={i}>
                <Accordion.Header>
                  {/* <Container> */}
                  <Row className="w-100">
                    {row.cells.map((cell, y) => {
                      return (
                        <>
                          {!!cell.column.accordionHeader && (
                            <Col className={cell.column.accordionHeaderColClass || "col-sm-6"} key={y}>
                              {!cell.column.accordionHeaderHideLabel && (
                                <span><b>{headerGroups[0].headers[y].render("Header")}:</b> <br /> {cell.render("Cell")}</span>
                              )}
                              {cell.column.accordionHeaderHideLabel && (
                                <span>{cell.render("Cell")}</span>
                              )}
                            </Col>
                          )}
                        </>
                      );
                    })}
                  </Row>
                  {/* </Container> */}
                </Accordion.Header>
                <Accordion.Body {...row.getRowProps()}>
                  <Container>
                    <Row>
                      {row.cells.map((cell, y) => {
                        if (cell.column.accordionBodyExclude != undefined && cell.column.accordionBodyExclude == true) {
                          return;
                        }
                        return (
                          <>
                            <Col xl={12} lg={4} md={4} sm={4} xs={6} className="mb-1" key={y}>
                              <span><b>{headerGroups[0].headers[y].render("Header")}:</b> <br /> {cell.render("Cell")}</span>
                            </Col>
                          </>
                        );
                      })}
                    </Row>
                  </Container>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </Col>
    </>

  )
}