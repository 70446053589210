import React from "react";
/*
 managed props (all unmanaged props (such as any that are usually used by Input component such as "value", "onChange", "maxLength", etc.) will just be passed through to the input element):
            required: 
                id, 
                label
                invalidFeedback
            optional:
                workTypeShowList, 
                workType
                alwaysShow //will default to true if there is no workTypeShowList or workType
                type
            advanced: //for when you have multiple parameters for when things are
                multipleShowLists (type String[][]) //NOTE: make sure types match. The worktype part this parses to numbers
                multipleDeciders (type String[])

        Example:
            <SmartTextAreaBox 
                id="requesterPhoneType"  
                label="Requester Phone Type"
                value={this.state.requesterPhoneType}
                onChange={this.handleRequesterPhoneType}
                invalidFeedback="Please provide a valid Requester Phone Type."
                workTypeShowList={[5, 6, 7, 8, 9, 10, 11, 12, 13]}
                workType={selectedConstructWorkType}
            />

        Example with multiple show lists: (this will show up if this.state.property = Renter or Other, and this.state.YesOrNoSelection = Yes)
            <SmartTextAreaBox 
                id="ownerName"  
                label="Owner Name"
                value={this.state.ownerName}
                onChange={this.handleOwnerName}
                invalidFeedback="Please provide a valid Owner Name."
                workTypeShowList={[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]}
                workType={selectedConstructWorkType}
                multipleShowLists={[["Renter", "Other"], ["Yes"]]}
                multipleDeciders={[this.state.property, this.state.YesOrNoSelection]}
            />
        */
class SmartTextAreaBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.generateId(),
        }
       
    }

    generateId() {
        const length = 15;
        const prefix = 'smartTextboxArea_';
        let uniqueId = prefix + Math.random().toString(36).substr(2, length);
        return uniqueId;
    }

    render() {
        const checkWorkTypeShowList = () => {
            if (!this.props.workType) return false; //list given but no work type
            try {
                return this.props.workTypeShowList.includes(Number(this.props.workType));
            } catch (e) {
                return false;
            }
        }

        const checkMultipleShowLists = () => {
            if (!this.props.multipleDeciders) return false; //list given but no deciders

            let fulfillsAllParameters = true;
            for (let i = 0; i < this.props.multipleShowLists.length; i++) {
                if (!this.props.multipleShowLists[i].includes(this.props.multipleDeciders[i])) {
                    fulfillsAllParameters = false;
                }
            }

            return fulfillsAllParameters;
        }

        const show = () => {
            if (this.props.alwaysShow) {
                return true;
            } else if (this.props.multipleShowLists && this.props.workTypeShowList) {
                return (checkWorkTypeShowList() && checkMultipleShowLists());
            } else if (this.props.workTypeShowList) {
                return checkWorkTypeShowList();
            } else if (this.props.multipleShowLists) {
                return checkMultipleShowLists();
            }

            return true; //if no params are passed in, always show
        }

        //separate managed props from other unmanaged props. Unmanaged props will be added directly to the input element.
        var { className, rows, value, id, label, invalidFeedback, workTypeShowList, workType, alwaysShow, type, multipleShowLists, multipleDeciders, ...unmanagedProps } = this.props;

        if(!id) {
            id = this.state.id;
        }

        return (
            <React.Fragment>
                {show() &&
                    <div className={`${className ? className : "col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3"}`}>
                        <label
                            htmlFor={id}
                            className="form-label"
                        >
                            {this.props.label} {!!this.props.required && (<span style={{ color: 'red' }}>*</span>)}
                        </label>
                        <textarea
                            id={id}
                            style={{resize: 'vertical'}}
                            className="form-control shadow-sm px-4"
                            placeholder="Leave a comment here"
                            rows={this.props.rows || "3"}
                            value={this.props.value || ""}
                            {...unmanagedProps}
                        ></textarea>
                        <div className="invalid-feedback">
                            {this.props.invalidFeedback}
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}

export default SmartTextAreaBox;