import React from "react";
import { Popover, OverlayTrigger } from "react-bootstrap"; 
/** 
managed props (all unmanaged props (such as any that are usually used by Input component such as "value", "onChange", "maxLength", etc.) will just be passed through to the input element):
    required: 
        label
        invalidFeedback
    optional:
        workTypeShowList, 
        workType
        alwaysShow //will default to true if there is no workTypeShowList or workType
        type
        className
        tooltip
    advanced: //for when you have multiple parameters for when things are
        multipleShowLists (type String[][]) //NOTE: make sure types match. The worktype part this parses to numbers
        multipleDeciders (type String[])

Example:
    <SmartTextBox 
        id="requesterPhoneType"  
        label="Requester Phone Type"
        value={this.state.requesterPhoneType}
        onChange={this.handleRequesterPhoneType}
        invalidFeedback="Please provide a valid Requester Phone Type."
        workTypeShowList={[5, 6, 7, 8, 9, 10, 11, 12, 13]}
        tooltip="This will show up if the user hovers over a question mark by the label"
        workType={selectedConstructWorkType}
    />

Example with multiple show lists: (this will show up if this.state.property = Renter or Other, and this.state.YesOrNoSelection = Yes)
    <SmartTextBox 
        id="ownerName"  
        label="Owner Name"
        className="MyCustomClass"
        value={this.state.ownerName}
        onChange={this.handleOwnerName}
        invalidFeedback="Please provide a valid Owner Name."
        workTypeShowList={[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]}
        workType={selectedConstructWorkType}
        multipleShowLists={[["Renter", "Other"], ["Yes"]]}
        multipleDeciders={[this.state.property, this.state.YesOrNoSelection]}
    />
*/

class SmartTextBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.generateId(),
            classTest: 'form-control shadow-sm px-4',
        }
        this.checkWorkTypeShowList = this.checkWorkTypeShowList.bind(this);
        this.checkMultipleShowLists = this.checkMultipleShowLists.bind(this);
    }

    generateId() {
        const length = 15;
        const prefix = 'smartTextBox_';
        let uniqueId = prefix + Math.random().toString(36).substr(2, length);
        return uniqueId;
    }

    checkWorkTypeShowList() {
        if (!this.props.workType) return false; //list given but no work type
        try {
            return this.props.workTypeShowList.includes(Number(this.props.workType));
        } catch (e) {
            return false;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(
           !this.props.multipleDeciders 
           || (Array.isArray(prevProps.multipleDeciders) && prevProps.multipleDeciders.every((el, i)=>el == this.props.multipleDeciders[i] ))
        ) {
            return;
        }
        let show = true;
        if (this.props.alwaysShow) {
            show = true;
        } else if (this.props.multipleShowLists && this.props.workTypeShowList) {
            show = (this.checkWorkTypeShowList() && this.checkMultipleShowLists());
        } else if (this.props.workTypeShowList) {
            show = this.checkWorkTypeShowList();
        } else if (this.props.multipleShowLists) {
            show = this.checkMultipleShowLists();
        }
        if(!show && this.props.onChange) {
            let event = {
                target: {
                    name: this.props.id,
                    value: "",
                }
            }
            this.props.onChange(event)
        }
    }

    checkMultipleShowLists() {
        if (!this.props.multipleDeciders) return false; //list given but no deciders

        let fulfillsAllParameters = true;
        for (let i = 0; i < this.props.multipleShowLists.length; i++) {
            if (!this.props.multipleShowLists[i].includes(this.props.multipleDeciders[i])) {
                fulfillsAllParameters = false;
            }
        }

        return fulfillsAllParameters;
    }

    render() {

        const show = () => {
            let show = true;
            if (this.props.alwaysShow) {
                show = true;
            } else if (this.props.multipleShowLists && this.props.workTypeShowList) {
                show = (this.checkWorkTypeShowList() && this.checkMultipleShowLists());
            } else if (this.props.workTypeShowList) {
                show = this.checkWorkTypeShowList();
            } else if (this.props.multipleShowLists) {
                show = this.checkMultipleShowLists();
            }
            
            return show; //if no params are passed in, always show
        }

        const formatPhoneNumber = (value) => {
            if (!value) return value;
            const phoneNumber = value.replace(/[^\d]/g, '');
            const phoneNumberLength = phoneNumber.length;
            if (phoneNumberLength < 4) return `(${phoneNumber}`;
            if (phoneNumberLength < 7) {
                return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
            }
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
        }

        const forceInteger = (value) => {
            //only positive integers.
            return value.replace(/[^\d]/g, '');
        }
        const forceAlphabets = (value) => {
            //only positive integers.
            return value.replace(/,[0-9]+/g, '');
        }

        const defaultClass='form-control shadow-sm px-4';
        const invalidClass='is-invalid';
        const validClass='is-valid';

        const validRegexStrings = {
            textOnly: "^[A-Za-z ]+$",
            phone: "^\\(\\d{3}\\)\\s\\d{3}-\\d{4}$",
            zip: "^\\d{5}(-\\d{4})?$",
            int: "^\\d+$",
            tel:   "^\\(\\d{3}\\)\\s\\d{3}-\\d{4}$", //'tel' is the html input type for telephone number
            email: "^[\\w]+([-.!$&*=^_`|~#%'+\\/?{}][\\w]+)*@[a-zA-Z0-9]+([.-][a-zA-Z0-9]+)*([.][a-zA-Z]{2,})$",
            latlong: "^-?\\d+(\\.\\d+)?$",
            num: "^-?\\d+(\\.\\d+)?$",
            allowAll: ".*",
        }

        const validRegexObjects =  {
            city:  new RegExp(validRegexStrings.textOnly),
            phone: new RegExp(validRegexStrings.phone),
            zip: new RegExp(validRegexStrings.zip),
            int: new RegExp(validRegexStrings.int),
            tel:   new RegExp(validRegexStrings.tel), //'tel' is the html input type for telephone number
            email: new RegExp(validRegexStrings.email, 'iu'),//the 'i' is for 'case insensitive'
            latlong: new RegExp(validRegexStrings.latlong),
            num: new RegExp(validRegexStrings.num),
            password: new RegExp(validRegexStrings.allowAll),
        }

        const formatFunctions = {
            phone: formatPhoneNumber,
            tel: formatPhoneNumber, //'tel' is the html input type for telephone number
            int: forceInteger, 
            city: forceAlphabets,
        }

        const markInvalid = () => {
            this.setState({classTest: `${defaultClass} ${invalidClass}`});
        }

        const markValid = () => {
            this.setState({classTest: `${defaultClass} ${validClass}`});
        }

        const checkIsValid = (e) => {
            let val = e.target.value;
            try{
                if(!val && !this.props.required) {
                    markValid();
                }else if(this.props.required && !val) {
                    markInvalid();
                }else if(this.props.type && !validRegexObjects[this.props?.type?.toLowerCase()].test(val)) {
                    markInvalid();
                }else{//valid
                    markValid();
                };
            }catch(e) {
              console.log('TODO: implement type regex in SmartTextBox for type: ', this.props.type);
            }
        }

        const formatForcer = (e) => {
            if(this.props.onChange && this.props.type && formatFunctions.hasOwnProperty(this.props?.type?.toLowerCase())) {
                e.target.value = formatFunctions[this.props?.type?.toLowerCase()](e.target.value);
                this.props.onChange(e);
            }else if(this.props.onChange) {
                //no format forcer implemented for this type.
                this.props.onChange(e);
            }
        }

        const customOnChangeFunction = (e) => {
            //force format if necessary
            formatForcer(e);

            //check if input is valid
            checkIsValid(e);
        }

        //separate managed props from other unmanaged props. Unmanaged props will be added directly to the input element.
        var { className, innerRef, value, id, label, invalidFeedback, workTypeShowList, workType, alwaysShow, type, multipleShowLists, multipleDeciders, onChange, tooltip, ...unmanagedProps } = this.props;

        if(!id) {
            id = this.state.id;
        }

        return (
            <React.Fragment>
                {show() &&
                    <div className={className ? className : "col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3"}>
                        <label
                            htmlFor={id}
                            className="form-label"
                        >
                            {this.props.label} {!!this.props.required && (<span style={{ color: 'red' }}>*</span>)}

                            { 
                                this.props?.tooltip ?
                                    <OverlayTrigger trigger={['hover', 'focus']} placement="auto-end" overlay={<Popover title="Popover right"> 
                                    {this.props?.tooltip}
                                     </Popover>}>
                                        <span className="questionIcon">?</span>
                                    </OverlayTrigger>
                                : ""
                            }
                        
                        </label>

                        <input
                            id={id}
                            ref={innerRef || undefined}
                            name={this.props.name || id || ''}
                            pattern={(this.props.type && validRegexStrings.hasOwnProperty(this.props?.type?.toLowerCase()) ? validRegexStrings[this.props?.type?.toLowerCase()]: undefined)}
                            type={this.props.type ? this.props.type : "text"}
                            className={this.state.classTest}
                            onChange={(e) => customOnChangeFunction(e)}
                            value = {this.props.value ? this.props.value : this.props.type == 'file' ? undefined : ''}
                            {...unmanagedProps}
                        />     
                        
                        <div className="invalid-feedback">
                            {this.props.invalidFeedback}
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}

export default SmartTextBox