import React from "react";
import { CloseButton, Badge, ProgressBar, Popover } from "react-bootstrap";
import { getSelectedRequest, translatedURL } from "../../util/functions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import $ from "jquery";
import { toast } from "react-toastify";
import SmartTextBox from "./SmartTextBox";

class SmartFile extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      id: this.generateId(),
      classTest: 'form-control shadow-sm px-4',
      //this file object will represent the file metadata from the server
      fileList: this.props.attachmentList ? this.props.attachmentList.filter(a => a.description == this.props.maximoFileType) : null,
      attachmentHasBeenModified: false,
      uploadFailed: false,
      isSaving: false,
      percentMaxFiles: 0,
      percentFileSpace: 0,
      minPercentToShowProgressBar: 50,
      maxTotalFilesMB: 20,
      maxSingleFileMB: 10,
      maxFilesUploaded: 10,
      
    }
    this.checkWorkTypeShowList = this.checkWorkTypeShowList.bind(this);
    this.checkMultipleShowLists = this.checkMultipleShowLists.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
  }

  updateProgressBars = (optFileList) => {
    //optFileList is either attachments from current component, or nothing
    if(!optFileList){
      optFileList = [];
      if(this.props.attachmentList && this.props.maximoFileType){
        optFileList.push.apply(optFileList, this.props.attachmentList.filter(a => a.description != this.props.maximoFileType) || []);
      }
      if(this.state.fileList || (this.props.attachmentList && this.props.maximoFileType)){
        optFileList.push.apply(optFileList, this.state.fileList || this.props.attachmentList.filter(a => a.description == this.props.maximoFileType) || []);
      }
    }else{
      //current component's files passed in. Still add other component's files
      if(this.props.attachmentList && this.props.maximoFileType){
        optFileList.push.apply(optFileList, this.props.attachmentList.filter(a => a.description != this.props.maximoFileType) || []);
      }
    }

    //figure out percentages for max requirements
    const maxTotalFilesMB = this.state.maxTotalFilesMB;//20mb total max
    const maxFilesUploaded = this.state.maxFilesUploaded; //10 files max

    let totalFilesSpace = 0;
    let totalNumFiles = 0;

    //count space/etc from this form:
    for(let i = 0; i<optFileList.length; i++) {
      totalNumFiles++;
      totalFilesSpace += optFileList[i].size
    }

    //percentage of max file amount and max combined file size will show if nearing threshold
    this.setState({
      percentMaxFiles: (totalNumFiles / maxFilesUploaded) * 100,
      percentFileSpace: ( totalFilesSpace / (maxTotalFilesMB * 1024 * 1024)) * 100,
    });
  }

  componentDidMount() {
    this.updateProgressBars();
  }

  generateId() {
    const length = 15;
    const prefix = 'smartFile_';
    let uniqueId = prefix + Math.random().toString(36).substr(2, length);
    return uniqueId;
  }

  checkWorkTypeShowList() {
    if (!this.props.workType) return false; //list given but no work type
    try {
      return this.props.workTypeShowList.includes(Number(this.props.workType));
    } catch (e) {
      return false;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    //remove data on file change
    if (
      !this.props.multipleDeciders
      || (Array.isArray(prevProps.multipleDeciders) && prevProps.multipleDeciders.every((el, i) => el == this.props.multipleDeciders[i]))
    ) {
      return;
    }
    let show = true;
    if (this.props.alwaysShow) {
      show = true;
    } else if (this.props.multipleShowLists && this.props.workTypeShowList) {
      show = (this.checkWorkTypeShowList() && this.checkMultipleShowLists());
    } else if (this.props.workTypeShowList) {
      show = this.checkWorkTypeShowList();
    } else if (this.props.multipleShowLists) {
      show = this.checkMultipleShowLists();
    }
    if (!show && this.props.onChange) {
      this.deleteUploadedFile()
    }
  }

  checkMultipleShowLists() {
    if (!this.props.multipleDeciders) return false; //list given but no deciders

    let fulfillsAllParameters = true;
    for (let i = 0; i < this.props.multipleShowLists.length; i++) {
      if (!this.props.multipleShowLists[i].includes(this.props.multipleDeciders[i])) {
        fulfillsAllParameters = false;
      }
    }

    return fulfillsAllParameters;
  }

  handleFileUpload = (event) => {
    let newFiles = event.target.files || null;
    this.setState({
      uploadFailed: false,
      isSaving: true,
    })

    //validation:
    const maxTotalFilesMB = this.state.maxTotalFilesMB;//20mb total max
    const maxFilesUploaded = this.state.maxFilesUploaded; //10 files max
    const maxSingleFileMB = this.state.maxSingleFileMB; //10mb single file size
    //get total space by all current files (on all file forms, not just this one)
    let totalFilesSpace = 0;
    let totalNumFiles = 0;

    //count space/etc from other forms:
    let filesFromOtherForms = this.props.attachmentList ? this.props.attachmentList.filter(a => a.description != this.props.maximoFileType) : null;
    if (filesFromOtherForms && filesFromOtherForms.length > 0) {
      for(let i = 0; i<filesFromOtherForms.length; i++) {
        totalNumFiles++;
        totalFilesSpace += filesFromOtherForms[i].size
      }
    }

    //count space/etc from this form:
    let filesFromThisForm = this.state.fileList;
    if (filesFromThisForm && filesFromThisForm.length > 0) {
      for(let i = 0; i<filesFromThisForm.length; i++) {
        totalNumFiles++;
        totalFilesSpace += filesFromThisForm[i].size
      }
    }

    //make sure total size including new files doesn't exceed max (20mb)
    for(let i = 0; i< newFiles.length; i++){
      totalNumFiles ++;
      totalFilesSpace += newFiles[i].size;

      let tfsMB = (totalFilesSpace / 1024) / 1024;
      //ensure combined mb between all files < max
      if(tfsMB > maxTotalFilesMB){
        toast.error(
          `You have exceeded the max of ${maxTotalFilesMB}mb combined file storage. Please upload less files or decrease the size of your files`,
          {autoClose: 8000,}
        );
        event.target.value = null; //clear selection
        this.setState({
          uploadFailed: true,
          isSaving: false,
        })
        return;
      }
      
      //ensure no more files added than max
      if(totalNumFiles > maxFilesUploaded){
        toast.error(
          `Exceeded max of ${maxFilesUploaded} total uploaded files on each Service Request`,
          {autoClose: 8000,}
        );
        event.target.value = null; //clear selection
        this.setState({
          uploadFailed: true,
          isSaving: false,
        })
        return;
      }
    }

    for(let i = 0; i< newFiles.length; i++){
      var filesize = ((newFiles[i].size / 1024) / 1024).toFixed(4);
      if (filesize > maxSingleFileMB) {
        //file too large
        toast.error(`File too Large. Max size is ${maxSingleFileMB}mb`);
        event.target.value = null; //clear selection
        this.setState({
          uploadFailed: true,
          isSaving: false,
        })
        return;
      }
    }
    //end validation

    //uploading file
    let formData = new FormData();
    for(let i = 0; i< newFiles.length; i++){
      formData.append('files', newFiles[i]);
    }
    
    formData.append('maximoType', this.props.maximoFileType || 'typeNotGiven');

    $.ajax({
      url: `/api/attachment/add?selected_request_id=${getSelectedRequest()}`,
      processData: false,
      contentType: false,
      method: "POST",
      data: formData,
      success: (res) => {
        let tempFileList = this.state.fileList || [];
        tempFileList.push(...res);

        this.setState({
          uploadFailed: false,
          fileList: tempFileList,
        })
      },
      error: (err) => {
        console.log("File upload failed. ")
        toast.error("Error occurred while uploading file: " + err.responseText);
        this.setState({
          uploadFailed: true,
        })
        this.inputRef.current.value=null; //clear input box inner text that displays file name
      },
    }).always(res => {
      this.setState({
        isSaving: false,
      })
    });
    //uploading file end

    //percentage of max file amount and max combined file size will show if nearing threshold
    this.setState({
      percentMaxFiles: (totalNumFiles / maxFilesUploaded) * 100,
      percentFileSpace: ( totalFilesSpace / (maxTotalFilesMB * 1024 * 1024)) * 100,
    })

    event.target.value = null; //clear selection
  };

  deleteUploadedFile = (attachmentId) => {
    $.ajax({
      url: `/api/attachment/deleteFileFromSR/${attachmentId}?selected_request_id=${getSelectedRequest()}`,
      processData: false,
      contentType: false,
      method: "DELETE",
      fail: (res) => {
        toast.error("Unexpected Error Occurred");
      },
      success: (res) => {
        toast.success("File removed");
      }
    }).always((res)=>{
      this.setState({
        fileList: this.state.fileList.filter(a => a.attachmentId !== attachmentId),
        attachmentHasBeenModified: true,
      });
      this.inputRef.current.value=null; //clear input box inner text that displays file name
      this.updateProgressBars(this.state.fileList.filter(a => a.attachmentId !== attachmentId));
    });
  }

  downloadFile = (pFile) => {
    fetch( translatedURL(`/api/attachment/downloadFileFromSR/${pFile.attachmentId}?selected_request_id=${getSelectedRequest()}`), 
      {
        method: "GET",
      })
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the fileName you want
        a.download = pFile.fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => {
        toast.error("Failed to download file. Please try again later.")
      });
      
  }

  render() {
    const show = () => {
      let show = true;
      if (this.props.alwaysShow) {
        show = true;
      } else if (this.props.multipleShowLists && this.props.workTypeShowList) {
        show = (this.checkWorkTypeShowList() && this.checkMultipleShowLists());
      } else if (this.props.workTypeShowList) {
        show = this.checkWorkTypeShowList();
      } else if (this.props.multipleShowLists) {
        show = this.checkMultipleShowLists();
      }

      return show; //if no params are passed in, always show
    }

    //separate managed props from other unmanaged props. Unmanaged props will be added directly to the input element.
    var {accept, maximoFileType, attachmentList, className, id, type, onChange, multipleShowLists, workTypeShowList, alwaysShow, workType, multipleDeciders, ...unmanagedProps } = this.props;

    if (!id) {
      id = this.state.id;
    }

    const AcceptedFilesToolTip = 
    <>
      <Popover.Header as="h3"><strong>File Attachment Requirements:</strong></Popover.Header>
      <Popover.Body>
        <p>{`All files must have a size less than ${this.state.maxSingleFileMB}mb individually, and the combined size of all files in a Service Request must total less than ${this.state.maxTotalFilesMB}mb. You can upload a max of ${this.state.maxFilesUploaded} files total to a Service Request.`}</p>
        <p>Below are the accepted file types:</p>
        <ul>
          <li><strong>Images:</strong> jpg, png, psd</li>
          <li><strong>CAD:</strong> dwg</li>
          <li><strong>Documents:</strong> pdf, doc, docx</li>
          <li><strong>Spreadsheets:</strong> xls, xlsx</li>
        </ul>
      </Popover.Body>
      <p></p>
    </>

    return (
      <>
        {show() && (
          <div className={this.props.className || "col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3"}>
            <SmartTextBox
              id={id || undefined}
              className="undefined_css_class"
              type="file"
              innerRef={this.inputRef}
              onChange={(e) => this.handleFileUpload(e)}
              multiple
              accept=".png, .jpg, .kml, .psd, .dwg, .pdf, .doc, .docx, .xls, .xlsx"
              tooltip={AcceptedFilesToolTip}
              {...unmanagedProps}
            />
            {this.state.percentFileSpace > this.state.minPercentToShowProgressBar && (
              <ProgressBar striped animated variant="primary" now={this.state.percentFileSpace} label={`Used File Space: ${Math.round(this.state.percentFileSpace)}%`} />
            )}
            {this.state.percentMaxFiles > this.state.minPercentToShowProgressBar && (
              <ProgressBar striped animated variant="warning" now={this.state.percentMaxFiles} label={`Max 10 files ${Math.round(this.state.percentMaxFiles)}%`} />
            )}
            {!!this.state.isSaving && (
              <React.Fragment>
                <FontAwesomeIcon icon={faSpinner} className="spin" />
                &nbsp;Uploading...
              </React.Fragment>
            )}

            {this.state.uploadFailed && 
              <Badge bg="danger" className="w-100 mt-2">
                Failed to upload file(s). Please try again
              </Badge>
            }

            {this.state.fileList && this.state.fileList.length>0 &&
              this.state.fileList.map( (pfile, i) => (
                <Badge bg="success" className={i===0 ? "div-overflow-fix w-100 mt-2" : "div-overflow-fix w-100"} style={{whiteSpace: 'initial'}} key={i}> 
                  <CloseButton 
                    className="float-end" 
                    variant="white" 
                    style={{width: '5%'}}
                    onClick={() => {this.deleteUploadedFile(pfile.attachmentId)}} 
                  />
                  <span 
                    className="float-start " 
                    style={{width: '90%'}}
                  >
                    Attached File:&nbsp;
                    <span
                      className="make-me-look-like-a-link" 
                      onClick={() => {this.downloadFile(pfile)}}
                    >
                      <strong>{pfile.fileName || pfile.name}</strong>
                    </span>
                  </span>
                </Badge>
              ))
              
            }
          </div>
        )}
      </>


    )
  }
}

export default SmartFile