import chrome from "./icons/chrome_icon.png";
import firefox from "./icons/firefox_icon.png";
import edge from "./icons/edge_icon.png";
import safari from "./icons/safari_icon.png";

import mduLogo from "./mdu/logo-color.svg";
import igcLogo from "./igc/logo-color.svg";
import cngLogo from "./cng/logo-color.svg";
import gpgLogo from "./gpg/logo-color.svg";


export const logos = {
    mdu: mduLogo,
    igc: igcLogo,
    cng: cngLogo,
    gpg: gpgLogo
}

export const images = {
    chrome: chrome,
    firefox: firefox,
    edge: edge,
    safari: safari,
}

export const favicons = (brand) => {
    const fileMap = {
        mdu: require.context('./mdu/favicons'),
        cng: require.context('./cng/favicons'),
        gpg: require.context('./gpg/favicons'),
        igc: require.context('./igc/favicons')
    }

    return fileMap[brand];
}