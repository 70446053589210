import React from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { translatedURL } from "../../util/functions";
import FormAutoCSRF from "../lib/FormAutoCSRF";
class MFA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "mfaPage",
      redirect: false,
      trust: "off",
      location: {},
      email: "",
      mfaRequestSent: false,
      mfaCode: ""
    };

    this.handleCodeChange = this.handleCodeChange.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("userEmail") === null) {
      window.location.replace("/login");
      return;
    } else {
      this.setState({
        email: localStorage.getItem("userEmail")
      })
    }
  }

  handleCodeChange = (event) => {
    this.setState({ mfaCode: event.target.value })
  }

  componentWillMount() {
    if (localStorage.getItem("userEmail") === null) {
      window.location.replace("/login");
      return;
    }
    this.props.setPageHeaders("MFA", "Please check your registered email for a Security Code and then enter it below.");
  }

  render() {
    return (
      <FormAutoCSRF
        method="post" action={translatedURL("/login")}
      >
        <input type="hidden" name="email" value={this.state.email} />
        <div className="mb-3">
          Code:
          <input
            id="mfaCode"
            type="text"
            placeholder="Code"
            name="code"
            required
            autoFocus=""
            value={this.state.mfaCode}
            onChange={this.handleCodeChange}
            className="form-control shadow-sm px-4"
          />
          <div className="invalid-feedback">
            Invalid mfa code
          </div>
        </div>
        <div className="form-check">
          <label
            className="form-check-label"
            htmlFor="chkTrust"
          >
            Remember this device for 90 days
            <OverlayTrigger trigger={['hover', 'focus']} overlay={
              <Popover title="Popover right">
                Do not select this option if this is a shared device.
              </Popover>
            }>
              <span className="questionIcon">?</span>
            </OverlayTrigger>
          </label>
          <input
            name="trust"
            className="form-check-input"
            type="checkbox"
            id="chkTrust"
            onChange={({ target }) => this.setState({ trust: target.value })}
          />
        </div>
        <div className="mt-2">
          <button
            type="submit"
            className="btn btn-success btn-block text-uppercase mb-2 shadow-sm w-100"
          >
            Submit
          </button>
        </div>
      </FormAutoCSRF>
    );
  }
}

export default MFA