import React from "react";
import $ from "jquery";
import SmartRadioButtons from "../lib/SmartRadioButtons";
import SmartTextBox from "../lib/SmartTextBox";
import SmartDropDownList from "../lib/SmartDropDownList";
import SmartSection from "../lib/SmartSection";
import endpoint from "../../util/endpoint";
import { getBrand, getSelectedRequest } from "../../util/functions";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import { Row, Badge } from "react-bootstrap";
import { wtm } from "../dashboard/TableComponents/SharedConstants";
import _ from 'lodash'

class CreateCustomerRequest extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      isLoading: false,
    };
  }

  handleInputChange = (event, options = {name: null, value: null }, runServerSave = true) => {
    let name = options.name == null ? event.target.name : options.name;
    let value = options.value == null ? event.target.value : options.value;

    if(runServerSave){
      this.props.updateServiceRequestObject(name, value);
      this.props.saveSR();
    }else{
      this.props.updateServiceRequestObject(name, value);
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true })
    $.ajax({
      url: `/api/request/getActiveSR?selected_request_id=${getSelectedRequest()}`,
      dataType: "json",
      method: "GET",
      success: (result) => {
        this.props.replaceServiceRequestObject(result);
        this.setState({
          isLoading: false,
        });
      },
      error: () => {
        this.setState({ isLoading: false })
      }
    });
  }

  render() {
    const nextOptions = (event) => {
      event.preventDefault();

      if (!this.formRef.current.checkValidity()) {
        this.formRef.current.classList.add('was-validated');
        return false
      }
      
      this.props.saveSR_Now(this.props.nextPage);
    };

    const getValue = (path) => {
      return _.get(this.props.srObj, path)
    }

    return (
      <form ref={this.formRef} onSubmit={nextOptions} className="customBorder mt-3 needs-validation" noValidate>
        <div className="col-12">
          <h3 className="text-center text-primary mt-3">
            Customer Information
          </h3>
          {this.state.isLoading ? <SkeletonLoader count={11} width="100px" /> :
            <Row>
              <SmartDropDownList
                id="requestedTypeOfService"
                label="Requested Type of Service"
                value={getValue('cusInfo.serviceType')}
                onChange={(event) => this.handleInputChange(event, {name: "cusInfo.serviceType"})}
                invalidFeedback="Please provide a Requested Type of Service."
                fetchURL={endpoint.maximoMDUGSPSERVICETYPE}
                defaultText='Select Requested Type of Service'
                required
                optionFilter={(option) => { 
                  //this filter will remove "Existing Commercial" and "Existing Residential" when work type is a New Subdivision (either electric OR combo).
                  if([wtm.ELECTRIC_DEVELOPER_SUBDIVISION, wtm.COMBO_SUBDIVISION].includes(Number(this.props.selectedWorkType))) {
                    return !option.value.startsWith("EXISTING");
                  }
                  return true;
                }}
                // Hide this input field on subdivision work types
                // The type of service is defaulted to NEWRESIDENTIAL in CreateServiceRequest
                workTypeShowList={Object.values(wtm).filter((workType) => workType !== wtm.GAS_INSTALL_NEW_SUBDIVISION && workType !== wtm.COMBO_SUBDIVISION)}
                workType={this.props.selectedWorkType}
              />
              {/* <SmartDropDownList
                id="customerType"
                label="Customer Type"
                value={[getValue('cusInfo.customerType')]}
                onChange={(event) => this.handleInputChange(event, {name: "cusInfo.customerType"})}
                invalidFeedback="Please provide a Customer Type."
                fetchURL={endpoint.maximoMDUGCUSTOMERTYPE}
                defaultText='Select Customer Type'
                required
              /> */}
              <SmartSection
                id="developerOrPropertyOwnerSection"
                title="Developer/Property Owner"
              >
                <SmartRadioButtons
                  id="renterOwnerInquire"
                  label="Are you the Developer and/or the Property Owner?"
                  tooltip="If you are either the developer or the property owner select 'Yes'. If not select 'No'"
                  valueList={["YES", "NO"]}
                  valueLabelList={["Yes", "No"]}
                  selectedValue={getValue('cusInfo.property')}
                  onChange={(event) => this.handleInputChange(event, {name: "cusInfo.property"})}
                  invalidFeedback="Please provide an valid response"
                  required
                />
                <SmartTextBox
                  id="ownerPhone"
                  type="phone"
                  label="Phone"
                  value={getValue('cusInfo.ownerPrimaryPhone')}
                  onChange={(event) => this.handleInputChange(event, {name: "cusInfo.ownerPrimaryPhone"})}
                  invalidFeedback="Please provide a valid Phone."
                  multipleShowLists={[["NO"]]}
                  multipleDeciders={[getValue('cusInfo.property')]}
                  required
                />
                <SmartTextBox
                  id="ownerName"
                  label="Owner Name"
                  maxLength="62"
                  value={getValue('cusInfo.ownerName')}
                  onChange={(event) => this.handleInputChange(event, {name: "cusInfo.ownerName"})}
                  invalidFeedback="Please provide a valid Owner Name."
                  multipleShowLists={[["NO"]]}
                  multipleDeciders={[getValue('cusInfo.property')]}
                  required
                />
                <SmartTextBox
                  id="ownerEmail"
                  label="Email"
                  maxLength="100"
                  value={getValue('cusInfo.ownerEmail')}
                  onChange={(event) => this.handleInputChange(event, {name: "cusInfo.ownerEmail"})}
                  invalidFeedback="Please provide a valid Owner Email."
                  type="email"
                  multipleShowLists={[["NO"]]}
                  multipleDeciders={[getValue('cusInfo.property')]}
                  required
                />
                <SmartTextBox
                  id="ownerStreet"
                  label="Street"
                  maxLength="55"
                  value={getValue('cusInfo.ownerStreet')}
                  onChange={(event) => this.handleInputChange(event, {name: "cusInfo.ownerStreet"})}
                  workType={this.props.selectedWorkType}
                  multipleShowLists={[["NO"]]}
                  multipleDeciders={[getValue('cusInfo.property')]}
                  required
                />
                <SmartTextBox
                  label="City"
                  maxLength="36"
                  value={getValue('cusInfo.ownerCity')}
                  onChange={(event) => this.handleInputChange(event, {name: "cusInfo.ownerCity"})}
                  workType={this.props.selectedWorkType}
                  multipleShowLists={[["NO"]]}
                  multipleDeciders={[getValue('cusInfo.property')]}
                  required
                />
                <SmartTextBox
                  label="State"
                  maxLength="36"
                  value={getValue('cusInfo.ownerState')}
                  onChange={(event) => this.handleInputChange(event, {name: "cusInfo.ownerState"})}
                  invalidFeedback="Please provide a valid State."
                  multipleShowLists={[["NO"]]}
                  multipleDeciders={[getValue('cusInfo.property')]}
                  required
                />
                <SmartTextBox
                  id="ownerZipCode"
                  label="Zip Code"
                  type="zip"
                  maxLength="10"
                  value={getValue('cusInfo.ownerZipCode')}
                  onChange={(event) => this.handleInputChange(event, {name: "cusInfo.ownerZipCode"})}
                  invalidFeedback="Please provide a valid Zip Code. Valid formats: 12345 or 12345-1234"
                  multipleShowLists={[["NO"]]}
                  multipleDeciders={[getValue('cusInfo.property')]}
                  required
                />
              </SmartSection>
              <SmartSection
                id="responsiblePartySection"
                title="Responsible Party"
              >
                <SmartRadioButtons
                  id="responsibleParty"
                  label="Is there a separate person responsible for the cost of construction?"
                  valueList={["YES", "NO"]}
                  valueLabelList={["Yes", "No"]}
                  selectedValue={getValue('cusInfo.responsible')}
                  onChange={(event) => this.handleInputChange(event, {name: "cusInfo.responsible"})}
                  invalidFeedback="Please provide whether or not there is a separate financially responsible party"
                  required
                />
                <SmartTextBox
                  id="responsibleName"
                  label="Responsible Name"
                  maxLength="62"
                  value={getValue('cusInfo.responsibleName')}
                  onChange={(event) => this.handleInputChange(event, {name: "cusInfo.responsibleName"})}
                  invalidFeedback="Please provide a valid responsible name."
                  multipleShowLists={[["YES"]]}
                  multipleDeciders={[getValue('cusInfo.responsible')]}
                  required
                />
                <SmartTextBox
                  id="responsiblePhoneNumber"
                  type="phone"
                  label="Phone Number (Cell)"
                  value={getValue('cusInfo.responsiblePhoneNumber')}
                  onChange={(event) => this.handleInputChange(event, {name: "cusInfo.responsiblePhoneNumber"})}
                  invalidFeedback="Please provide a valid Phone Number (Cell)."
                  multipleShowLists={[["YES"]]}
                  multipleDeciders={[getValue('cusInfo.responsible')]}
                  required
                />
                <SmartTextBox
                  id="responsibleEmail"
                  type="email"
                  maxLength="100"
                  label="Email"
                  value={getValue('cusInfo.responsibleEmail')}
                  onChange={(event) => this.handleInputChange(event, {name: "cusInfo.responsibleEmail"})}
                  invalidFeedback="Please provide a valid Email."
                  multipleShowLists={[["YES"]]}
                  multipleDeciders={[getValue('cusInfo.responsible')]}
                  required
                />
                <SmartTextBox
                  id="responsibleCompanyName"
                  maxLength="50"
                  label="Company Name"
                  value={getValue('cusInfo.responsibleCompanyName')}
                  onChange={(event) => this.handleInputChange(event, {name: "cusInfo.responsibleCompanyName"})}
                  invalidFeedback="Please provide a valid Company Name."
                  multipleShowLists={[["YES"]]}
                  multipleDeciders={[getValue('cusInfo.responsible')]}
                  required
                />
                <SmartTextBox
                  id="responsibleStreetAddress"
                  label="Street/Billing Address"
                  maxLength="55"
                  value={getValue('cusInfo.responsibleStreetAddress')}
                  onChange={(event) => this.handleInputChange(event, {name: "cusInfo.responsibleStreetAddress"})}
                  invalidFeedback="Please provide a valid Street Address."
                  multipleShowLists={[["YES"]]}
                  multipleDeciders={[getValue('cusInfo.responsible')]}
                  required
                />
                <SmartTextBox
                  label="City"
                  maxLength="36"
                  value={getValue('cusInfo.responsibleCity')}
                  onChange={(event) => this.handleInputChange(event, {name: "cusInfo.responsibleCity"})}
                  invalidFeedback="Please provide a valid City."
                  multipleShowLists={[["YES"]]}
                  multipleDeciders={[getValue('cusInfo.responsible')]}
                  required
                />
                <SmartTextBox
                  label="State"
                  maxLength="36"
                  value={getValue('cusInfo.responsibleState')}
                  onChange={(event) => this.handleInputChange(event, {name: "cusInfo.responsibleState"})}
                  invalidFeedback="Please provide a valid State."
                  multipleShowLists={[["YES"]]}
                  multipleDeciders={[getValue('cusInfo.responsible')]}
                  required
                />
                <SmartTextBox
                  id="responsibleZipCode"
                  label="Zip Code"
                  type="zip"
                  maxLength="10"
                  value={getValue('cusInfo.responsibleZipCode')}
                  onChange={(event) => this.handleInputChange(event, {name: "cusInfo.responsibleZipCode"})}
                  invalidFeedback="Please provide a valid Zip Code. Valid formats: 12345 or 12345-1234"
                  multipleShowLists={[["YES"]]}
                  multipleDeciders={[getValue('cusInfo.responsible')]}
                  required
                />
              </SmartSection>
              <Row>
                <div className="col-12">
                  <div className="text-center">
                    <button
                      disabled={this.props.updateInProgress}
                      type="button"
                      onClick={() => this.props.prevPage()}
                      className="btn btn-secondary btn-block text-uppercase mb-2 shadow-sm btn-sm me-2"
                    >
                      Previous
                    </button>
                    <button
                      disabled={this.props.updateInProgress}
                      type="submit"
                      className="btn btn-primary btn-block text-uppercase mb-2 shadow-sm btn-sm"
                    >
                      Next
                    </button>
                  </div>
                  {this.props.updateInProgress && <div className="text-center"><Badge bg="primary" >Saving in progress... </Badge></div>}
                </div>
              </Row>
            </Row>
          }
        </div>
      </form>
    );
  }
}

export default CreateCustomerRequest;