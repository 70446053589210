import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import LoginHeader from "../login/LoginHeader";
import Footer from "../footer/Footer";
import VerifyCode from "./VerifyCode";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import CustomAlert from "../lib/Alert";
import LoadingSpinner from "../lib/LoadingSpinner";
import { getBrand } from "../../util/functions";

class ForgotPasswordParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: getBrand(),
      successMessage: "",
      isLoading: false,
      header: "",
      subHeader: "",
      forgotPasswordStepNumber: 1, //1= email entry (default), 2= verify code, 3= reset password page
    };
    this.showError = this.showError.bind(this);
    this.showSuccess = this.showSuccess.bind(this);
    this.clearSuccess = this.clearSuccess.bind(this);
    this.clearError = this.clearError.bind(this);
    this.loading = this.loading.bind(this);
    this.setPageHeaders = this.setPageHeaders.bind(this);
    this.forgotPasswordEmailSent = this.forgotPasswordEmailSent.bind(this);
    this.forgotPasswordCodeVerified = this.forgotPasswordCodeVerified.bind(this);
    this.forgotPassword_RestartProcess = this.forgotPassword_RestartProcess.bind(this);
  }

  loading(p) { this.setState({ isLoading: p }) }

  showError(e) { this.setState({ errorMessage: e }) }

  showSuccess(e) { this.setState({ successMessage: e }) }

  clearError() { this.showError(""); }

  clearSuccess() { this.showSuccess(""); }

  forgotPasswordEmailSent() {
    this.setState({
      isLoading: false,
      forgotPasswordStepNumber: 2,
    });
  }

  forgotPasswordCodeVerified() {
    this.setState({
      isLoading: false,
      forgotPasswordStepNumber: 3,
    })
  }

  forgotPassword_RestartProcess() {
    this.setState({
      forgotPasswordStepNumber: 1,
    })
  }

  setPageHeaders(pHeaderText, pSubHeaderText) {
    this.setState({
      header: pHeaderText,
      subHeader: pSubHeaderText,
    })
  }

  componentDidMount() {
    //reset forgot password process if user navigates directly to page
    this.forgotPassword_RestartProcess();
  }

  render() {
    return (
      <div className={this.state.theme}>
        <LoginHeader />
        <div className="maincontainer">
          <Container fluid={true}>
            <Row>
              <div className="h-100">
                <div className="login d-flex align-items-center">
                  <Container>
                    <Row>
                      <div>
                        <Col xl={{ span: 4, offset: 4 }} md={{ span: 6, offset: 3 }} sm={{ span: 10, offset: 1 }} xs={12}>
                          <div className="well well-form">
                            {!!this.state.errorMessage && (
                              <CustomAlert
                                variant="danger"
                                message={this.state.errorMessage}
                              ></CustomAlert>
                            )}
                            {!!this.state.successMessage && (
                              <CustomAlert
                                variant="success"
                                message={this.state.successMessage}
                              />
                            )}
                            {this.state.isLoading && <LoadingSpinner show={this.state.isLoading} />}
                            {!this.state.isLoading &&
                              <>
                                {this.state.forgotPasswordStepNumber === 1 && (
                                  <ForgotPassword
                                    loading={this.loading}
                                    setPageHeaders={this.setPageHeaders}
                                    forgotPasswordEmailSent={this.forgotPasswordEmailSent}
                                  />
                                )}
                                {this.state.forgotPasswordStepNumber === 2 && (
                                  <VerifyCode
                                    loading={this.loading}
                                    setPageHeaders={this.setPageHeaders}
                                    forgotPasswordCodeVerified={this.forgotPasswordCodeVerified}
                                    showError={this.showError}
                                    clearError={this.clearError}
                                  />
                                )}
                                {this.state.forgotPasswordStepNumber === 3 && (
                                  <ResetPassword
                                    setPageHeaders={this.setPageHeaders}
                                  />
                                )}
                              </>
                            }
                          </div>
                        </Col>
                      </div>
                    </Row>
                  </Container>
                </div>
              </div>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    );
  }
}

export default ForgotPasswordParent;