import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Cookies from 'js-cookie';

import 'bootstrap/dist/css/bootstrap.min.css';
import $ from "jquery"

//in sim5000, base url changes. This will automatically add the base url when necessary.
import { translatedURL } from './util/functions';

$.ajaxSetup({
  beforeSend: function (xhr, options) {
    //fix urls if our context.root is different (could be either / or /sim5000-cssp/)
    options.url = translatedURL(options.url);

    //if POST/PUT/DELETE/PATCH add CSRF header
    if (['POST', 'PUT', 'DELETE', 'PATCH'].includes(options.type)) {
      xhr.setRequestHeader(process.env.REACT_APP_CSRF_TOKEN_NAME, Cookies.get(process.env.REACT_APP_CSRF_TOKEN_NAME));
    }
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  /** NOTE: 
   *  React.StrictMode will sometimes seems as though it is causing problems. But that is intentional
      When running our site in dev mode (locally with npm start), StrictMode will: 
        1. mount and unmount components twice every time they are loaded.
        2. fire all eventListener functions twice
        3. other annoying things (feel free to add more items here as you keep coming back to look at this angrily)
      The point is to have our site coded more resiliently. If unloading and reloading or calling a function twice quickly can break stuff, 
      then our site isn't coded well and could easily have dumb weird things happen in prod.
      
      So, long story short, if you're here because stackoverflow says deleting React.StrictMode will solve your problems:
      **************DON'T GIVE INTO TEMPTATION ************** fix the code instead **************
      (This message is brought to future me by my past self. Thank you for coming to my Ted Talk :) )
  **/
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
