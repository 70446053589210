import React from "react";
import $ from "jquery";
import { Modal, Button} from "react-bootstrap";
import { logout } from "../../util/functions";

import CustomLink from '../lib/Link'

class TimeoutHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    }

    this.logouttime = React.createRef({});

    this.idleTime = 0;
    this.idleInterval = [];
    this.TIME_BEFORE_MODAL_SHOW = 15; //15 minutes before modal pops up
    this.interval = [];
    this.timerStarted = false;

    this.backendPingLimiter = true; //set to false for 10 seconds once pingBackend is called. This will decrease the calls to the resetTimeoutCounter function
  }

  pingBackend() {
    if(this.backendPingLimiter) {
      this.backendPingLimiter=false;

      $.ajax({
        url: "/api/auth/resetSessionTimeoutCounterAndCheckIfLoggedIn", //if logged in, spring will postpone it's auto logout stuff for a bit. If request returns "not authorized" status, redirect user to login page.
        method: "GET",
        contentType: "text/plain",
      }).done((res) => {
        //success. backend timeout restarted. do nothing here
      }).fail((res) => {
        if(res.status === 403) { //user not logged in. Call logout function.
          logout({error: 'inactive'});
        }
      })

      setTimeout(()=>{
        this.backendPingLimiter=true;
      }, 10000)
    }
  }

  showModal() {
    this.props.setShown(true);
    var fiveMinutes = 60 * 5;
    this.startTimer(fiveMinutes);
  }

  hideModal() {
    this.stopTimer();
    this.props.setShown(false);
  }

  timerIncrement() {
    this.idleTime = this.idleTime + 1;
    if (this.idleTime === this.TIME_BEFORE_MODAL_SHOW) {
      //open timeout modal
      this.showModal();
    }
  }

  stopTimer() {
    this.interval.forEach((i) => {
      clearInterval(i);
    });
    this.interval = [];
    if(this.logouttime.current) {
      this.logouttime.current.innerText = "05:00";
    }
    this.timerStarted=false;
  }


  startTimer(duration) {
    let timer = duration;
    let minutes, seconds;
    if(!this.timerStarted) {
      this.timerStarted=true;
      this.interval.push(setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);
  
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
  
        if(this.logouttime.current) {
          this.logouttime.current.innerText = (minutes + ":" + seconds);
        }
  
        if (--timer < 0) {
          logout({error: 'inactive'});
        }
      }, 1000));
    }
    
  }

  pingHandler = () => {
    this.idleTime = 0;
    this.pingBackend();
  }

  componentDidMount() {
      // Increment the idle time counter every minute.
      this.idleInterval.push(setInterval(()=>this.timerIncrement(), 60000)); // 1 minute

      // Zero the idle timer on mouse movement or keypress.
      $(document).on("mousemove", this.pingHandler);
      $(document).on("keypress", this.pingHandler);
  }

  componentWillUnmount() {
    $(document).off("mousemove", this.pingHandler);
    $(document).off("keypress", this.pingHandler);  
  }
  
  render() {
    return (
      <Modal 
        show={this.props.shown}
        onHide={()=>this.hideModal()}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>Are you still there?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Due to inactivity you will be logged out in <span ref={this.logouttime} id="logouttime">05:00</span></h5>
        </Modal.Body>
        <Modal.Footer>
          <CustomLink href="#" className="dashboardHeading">
            <Button onClick={logout} variant="warning">Log Out</Button>
          </CustomLink>
          <Button variant="primary" onClick={()=>{this.pingBackend(); this.hideModal()}}>I'm Still Here!</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default TimeoutHandler;